import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { GoPerson } from "react-icons/go";
import { LuUploadCloud } from "react-icons/lu";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import CustomDialog from "../layout/Modal";
import TextInput from "../input-fields/TextInput";
import DropdownSelect from "../input-fields/SelectDropdown";
import { validationSchema } from "../../../Agent/validation/userRegistration";
import formatBytes from "../../AgentsReuseables/functions/formatBytes";
import AdminAuth from "../../../context/AdminContext/adminAuth";
import LoadingSpinner from "../../spinner";

export default function AssociationDetails({ closeModal }) {
  const genderArray = [
    { value: "", label: "Select" },
    { value: "Mr", label: "Mr" },
    { value: "Ms", label: "Ms" },
    { value: "Mrs", label: "Mrs" },
    { value: "Other", label: "Other" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { createAgent, toastMessage } = AdminAuth();
  const queryClient = useQueryClient();

  const [selectedFile, setSelectedFile] = useState(null);
  const [formValues, setFormValues] = useState({
    title: "",
    passport: "",
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(null);
  const MAX_FILE_SIZE = 2 * 1024 * 1024;

  const handleDropdownChange = (selectedValue) => {
    setFormValues((prevData) => ({
      ...prevData,
      title: selectedValue,
    }));
    setValue("title", selectedValue);
  };

  const handleFileChange = async (event) => {
    setError(null);
    const file = event.target.files[0];
    if (file && file.size <= MAX_FILE_SIZE) {
      try {
        const base64String = await convertFileToBase64(file);
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          passport: base64String,
        }));
        setSelectedFile(file);
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    } else {
      setSelectedFile(null);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        passport: "",
      }));
      setError(
        `File size exceeds the maximum allowed (${formatBytes(MAX_FILE_SIZE)}).`
      );
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const { mutateAsync: createAgentEntries, isPending } = useMutation({
    mutationFn: async (formData) => {
      try {
        await validationSchema.validate(formData, { abortEarly: false });
        const response = await createAgent({ ...formData });

        if (!(response?.Message === "Successful")) {
          setShowSpinner(false);
          toastMessage("error", response.Message);
          return;
        }
        closeModal();
      } catch (error) {
        // console.log(error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["agents"] });
    },
  });

  const onSubmit = async (data) => {
    try {
      await createAgentEntries({ ...data, passport: formValues.passport });
    } catch (validationErrors) {
      console.log("Validation errors:", validationErrors);
    }
  };

  const hasError =
    Object.keys(errors).length > 0 || !formValues.title || !formValues.passport;

  return (
    <>
      <CustomDialog
        isOpen={true}
        handleClose={closeModal}
        title="Create Agents"
        maxWidth="sm"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow mt-4 py-5 md:py-6 mt-3">
            <div className="flex flex-wrap items-start justify-between mx-2">
              <div className="w-10/12">
                <div className="mb-8 ml-2 px-5 w-full md:w-10/12">
                  <span
                    htmlFor="passport"
                    className="block text-xs font-medium text-gray-700 mb-2"
                  >
                    Upload Passport |{" "}
                    <i className="text-xs text-red-400">
                      image should not be more than 2MB
                    </i>
                  </span>
                  <label
                    className="flex flex-col items-center cursor-pointer appearance-none rounded-md border border-gray-300 bg-white p-4 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                    tabIndex="0"
                  >
                    <div className="rounded-full bg-gray-100 p-2 mb-2">
                      <LuUploadCloud className="text-2xl text-gray-600" />
                    </div>

                    <span className="text-xs font-bold text-gray-600">
                      Click to upload{" "}
                      <span className="text-gray-400 font-medium">
                        or drag and drop
                      </span>
                    </span>

                    <span className="text-xs text-gray-600">
                      SVG, PNG, JPG, or GIF (max. 800x400px)
                    </span>

                    <input
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                    {error && <p className="text-xs text-red-500">{error}</p>}
                  </label>
                </div>
              </div>
              <div className="flex items-start w-full md:w-2/12 justify-center">
                {!selectedFile && (
                  <div className="ml-4 mt-6 rounded-full bg-gray-100 p-2">
                    <GoPerson className="text-7xl text-slate-600" />
                  </div>
                )}
                {selectedFile && (
                  <div className="ml-4 mt-6">
                    <div className="rounded-full overflow-hidden bg-gray-300">
                      <img
                        src={"data:image/jpg;base64," + formValues.passport}
                        alt="Selected File"
                        style={{
                          width: "80px",
                          height: "80px",
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="block md:flex mt-4 md:mt-5">
              <div className="flex flex-col md:px-6 mx-2 md:mx-0 w-full md:w-1/2">
                <TextInput
                  label="First Name"
                  name="firstName"
                  fieldName={register("firstName")}
                  placeholder="Enter First Name"
                  required // Example of required field
                  errorMessage={errors.firstName?.message}
                />
              </div>
              <div className="flex flex-col mt-4 md:mt-0 mx-2 md:mx-0 w-full md:w-1/2">
                <TextInput
                  label="Last Name"
                  name="lastName"
                  fieldName={register("lastName")}
                  placeholder="Enter Last Name"
                  required // Example of required field
                  errorMessage={errors.lastName?.message}
                />
              </div>
            </div>

            <div className="block md:flex mt-4 md:mt-5">
              <div className="flex flex-col md:px-6 mx-2 md:mx-0 w-full md:w-1/2 min-h-[40px] ">
                {" "}
                <TextInput
                  label="Middle Name"
                  name="middleName"
                  fieldName={register("middleName")}
                  placeholder="Enter Middle Name"
                  errorMessage={errors.middleName?.message}
                />
              </div>
              <div className=" flex flex-col mt-4 md:mt-0 mx-2 md:mx-0 w-full md:w-1/2 min-h-[40px]">
                {" "}
                <div className="mt-4">
                  <DropdownSelect
                    label="Title"
                    options={genderArray}
                    value={formValues.title}
                    onChange={handleDropdownChange}
                    id="gender-select"
                  />
                </div>
                {errors.title && (
                  <span className="text-red-500">{errors.title.message}</span>
                )}
              </div>
            </div>
            <div className="block md:flex mt-4 md:mt-5">
              <div className="flex flex-col md:px-6 mx-2 md:mx-0 w-full md:w-1/2">
                <TextInput
                  label="Phone Number"
                  name="phoneNumber"
                  fieldName={register("phoneNumber")}
                  placeholder="Enter Phone Number"
                  type="number"
                  errorMessage={errors.phoneNumber?.message}
                />
              </div>
              <div className="flex flex-col mt-4 md:mt-0 mx-2 md:mx-0 w-full md:w-1/2">
                <TextInput
                  label="Email"
                  name="email"
                  fieldName={register("email")}
                  placeholder="Enter Email"
                  errorMessage={errors.email?.message}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-end items-center mt-10">
            <button
              className="btn red-outline font-medium w-full md:w-[150px]"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className={`border text-white text-xs md:text-sm bg-gray-100 bg-green-600 rounded py-1 px-2 ${
                !isValid || hasError
                  ? "opacity-50 cursor-not-allowed bg-slate-300"
                  : ""
              }`}
              type="submit"
              disabled={!isValid || hasError}
            >
              {!isPending ? "Submit" : "Loading...."}
            </button>
          </div>
        </form>
      </CustomDialog>
    </>
  );
}
