import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";

const DropdownSelect = ({ label, options, value, onChange, id }) => {
  return (
    <Box sx={{ minWidth: "100%" }}>
      <InputLabel id={`${id}-label`}>{label}</InputLabel>
      <FormControl fullWidth>
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value}
          label={label}
          onChange={(event) => onChange(event.target.value)}
          sx={{ height: 40 }}
        >
          {options &&
            options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

DropdownSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default DropdownSelect;
