import React, { useState } from "react";
import EnhancedTable from "../../reusables/AgentsReuseables/associationCompleteTable";
import AssociationDetails from "../../reusables/shared/modal/AssociationModal";
import PageLayout from "../../reusables/shared/layout/PageLayout";

function Associations() {
  const [totalAssociations, setTotalAssociations] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  const closeModal = () => {
    setShowDetails(false);
  };

  const handleTableValue = (data) => {
    setTotalAssociations(data);
  };

  return (
    <PageLayout
      title="Association"
      subTitle="View/Create Associations."
      totalValue={totalAssociations}
      showDetails={showDetails}
      setShowDetails={setShowDetails}
      buttonShown="create"
      noButton={false}
    >
      <section
        className="pl-3 pt-1 overflow-x-hidden md:mt-2"
        style={{
          width: "100%",
        }}
      >
        <EnhancedTable
          handleTableValue={handleTableValue}
          totalValue={totalAssociations}
        />
      </section>
      {showDetails && (
        <AssociationDetails onClose={closeModal} open={showDetails} />
      )}
    </PageLayout>
  );
}

export default Associations;
