import React, { useState } from "react";
import { LuUploadCloud } from "react-icons/lu";
import { GoPerson } from "react-icons/go";
import Select from "../../reusables/AgentsReuseables/SelectDropDown";
import SideNav from "../../reusables/AgentsReuseables/sideNav";

function Settins() {
  const initialFormData = { lastName: "", firstName: "", email: "", role: "" };
  const [formData, setFormData] = useState(initialFormData);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const handleRoleChange = (event) => {
    const value = event.target.value;
    setFormData({ ...formData, role: value });
  };
  const handleClick = (e) => {
    e.preventDefault();

    // setState((p) => ({ ...p, showOptions: !state.showOptions }));
  };
  return (
    <div className="flex flex-row w-full mt-2 pr-6 " style={{ height: "96vh" }}>
      <div className="flex flex-col w-1/6 ml-6  mt-2 br-4">
        <SideNav currentLink="settingAgent" />
      </div>
      <div
        className="mt-4  items-center justify-between"
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            marginLeft: "12%",
          }}
        >
          <h1 className="text-gray-700 text-4xl mb-6">Settings</h1>
        </div>
        <div
          className="justify-center items-center  flex flex-col"
          style={{
            width: "90%",
          }}
        >
          <div
            className="grid grid-cols-1 md:grid-cols-2 gap-4"
            style={{
              width: "80%",
            }}
          >
            <div>
              <p>Personal info</p>
              <p className="text-xs">
                Update your photo and personal details here.
              </p>
            </div>
            <div className="flex items-end justify-end p-4">
              <button className="border rounded-lg p-1 m-1 w-20 text-sm">
                Cancel
              </button>
              <button className="border rounded-lg p-1 m-1 bg-slate-400 text-white w-20 text-sm">
                Save
              </button>
            </div>
          </div>

          <form
            style={{
              width: "80%",
            }}
          >
            <div className="w-5/6">
              <div className="flex mt-4 text-sm">
                <label htmlFor="firstName">Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  placeholder="Enter Name"
                  onChange={handleChange}
                  autoComplete="firstName"
                  className="mt-1 p-2 w-full border rounded-md focus:outline-none ml-12 focus:border-blue-500"
                  required
                />
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  placeholder="Enter Last Name"
                  onChange={handleChange}
                  autoComplete="lastName"
                  className="mt-1 p-2 w-full border rounded-md focus:outline-none ml-12 focus:border-blue-500"
                  required
                />
              </div>

              <div className="flex mt-4 text-sm">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  placeholder="Enter Email Address"
                  onChange={handleChange}
                  autoComplete="email"
                  className="mt-1 p-2 w-full border rounded-md focus:outline-none ml-12 focus:border-blue-500"
                  required
                />{" "}
              </div>

              <div className="flex mt-4 text-sm">
                <label className="text-[9px] md:text-sm mr-14">Role</label>
                <div className="w-full h-11">
                  <select
                    className="border-2 md:py-2 md:px-2  rounded md:w-full focus:outline-none focus:border-blue-500"
                    defaultValue="Select"
                    value={formData.role}
                    onChange={handleRoleChange}
                  >
                    {["Admin", "Agent"].map((item, index) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8 text-xs">
                <div className="flex items-start justify-start flex flex-row  justify">
                  <div>
                    {" "}
                    <p className="font-semibold">Your photo</p>
                    <p>This will be displayed on your profile.</p>
                  </div>
                  <div>
                    {" "}
                    {!selectedFile && (
                      <div className="ml-4  rounded-full bg-gray-100 p-2">
                        <GoPerson className="text-8xl text-slate-600" />
                      </div>
                    )}
                    {selectedFile && (
                      <div className="ml-4">
                        <div className="rounded-full overflow-hidden bg-gray-300">
                          <img
                            src={URL.createObjectURL(selectedFile)}
                            alt="Selected File"
                            style={{
                              width: "110px",
                              height: "110px",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="mb-8 text-xs">
                  <label
                    className="flex flex-col items-center cursor-pointer appearance-none rounded-md border border-gray-300 bg-white p-4 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                    tabIndex="0"
                  >
                    <div className="rounded-full bg-gray-100 p-2 mb-2">
                      <LuUploadCloud className="text-2xl text-gray-600" />
                    </div>

                    <span className="text-xs font-bold text-gray-600">
                      Click to upload{" "}
                      <span className="text-gray-400 font-medium text-xs">
                        or drag and drop
                      </span>
                    </span>

                    <span className="text-xs text-gray-600">
                      SVG, PNG, JPG, or GIF (max. 800x400px)
                    </span>

                    <input
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Settins;
