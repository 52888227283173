import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import AgentAuth from "../../context/AgentContext/agentAuth";
import AuthContext from "../../context/AuthContext";
import schemas from "../Validation";

const FormComponent = ({ email }) => {
  const { changeDefaultPasswordSchema } = schemas;

  const queryClient = useQueryClient();
  const { toastMessage } = AgentAuth();
  const navigate = useNavigate();
  const { changePassword } = useContext(AuthContext);

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(changeDefaultPasswordSchema),
  });

  const changePasswordMutation = useMutation({
    mutationFn: async (data) => {
      const payload = {
        EmailAddress: email,
        OldPassword: data.password,
        NewPassword: data.newPassword,
        ConfirmPassword: data.newPassword,
      };

      const response = await changePassword(payload);
      if (response && !response.Success) {
        toastMessage("error", response?.Message);
        return;
      }
      navigate("/signin");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["agents"] });
    },
    onError: () => {
      toastMessage("error", "An error occurred, please try again");
    },
  });

  const onSubmit = async (data) => {
    await changePasswordMutation.mutateAsync(data);
  };

  return (
    <form
      className="flex flex-wrap w-full grid-cols-2 gap-4 mt-20 px-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1 className="text-3xl font-bold w-full text-center mb-6">
        Change Password
      </h1>

      <p className="text-gray-700 w-full text-center mb-6">
        Please create your new password in order to log in to the platform
      </p>

      <section className="pl-3 pr-6 w-full">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="password"
        >
          Old Password
        </label>
        <div className="relative">
          <input
            id="password"
            className={`border mb-2 py-2 px-3 rounded text-gray-700 w-full ${
              errors.password ? "border-red-500" : ""
            }`}
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="* * * * * * * *"
            {...register("password")}
          />
          <span
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        </div>
        {errors.password && (
          <p className="text-red-500 text-xs italic">
            {errors.password.message}
          </p>
        )}
      </section>

      <section className="pl-3 pr-6 w-full">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="newPassword"
        >
          New Password
        </label>
        <div className="relative">
          <input
            id="newPassword"
            className={`border mb-2 py-2 px-3 rounded text-gray-700 w-full ${
              errors.newPassword ? "border-red-500" : ""
            }`}
            name="newPassword"
            type={showNewPassword ? "text" : "password"}
            placeholder="* * * * * * * *"
            {...register("newPassword")}
          />
          <span
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={() => setShowNewPassword(!showNewPassword)}
          >
            {showNewPassword ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        </div>
        {errors.newPassword && (
          <p className="text-red-500 text-xs italic">
            {errors.newPassword.message}
          </p>
        )}
      </section>

      <section className="pl-3 pr-6 w-full">
        {isSubmitting || changePasswordMutation.isLoading ? (
          <div className="flex justify-center items-center w-full h-14">
            <div className="animate-spin rounded-full h-11 w-11 border-t-2 border-b-4 border-gray-900"></div>
          </div>
        ) : (
          <button
            className="bg-green-700 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:border-none w-full"
            disabled={isSubmitting}
          >
            Proceed
          </button>
        )}
      </section>

      <section className="flex flex-row mt-8 px-14 w-full justify-around">
        <p className="text-black underline">Terms of use</p>
        <p className="text-black ml-8 underline">FAQ</p>
      </section>
    </form>
  );
};

export default FormComponent;
