import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import * as Yup from "yup";
import { FaRegEye } from "react-icons/fa6";
import { FaRegEyeSlash } from "react-icons/fa6";

import { LuUploadCloud } from "react-icons/lu";
import { GoPerson } from "react-icons/go";
import { CiFileOn } from "react-icons/ci";
import { GiCheckMark } from "react-icons/gi";
import OTPInput from "../Auth/OTP";
import AgentAuth from "../context/AgentContext/agentAuth";
import adminAuth from "../context/AdminContext/adminAuth";

import FormStepper from "./stepper";
import Select from "../reusables/AgentsReuseables/SelectDropDown";
import NigerianState from "../context/data/NigerianState";
import {
  userSchema,
  initialBusinessDetails,
  initialNextOfKinDetails,
  personalDetails,
  isFormNotEmpty,
  initialState,
  businesSubCategory,
  businessCategory,
} from "../Agent/validation/userRegistration";
import { convertKeysToPascalCase } from "../reusables/AgentsReuseables/functions/convertCamelCase";
import formatBytes from "../reusables/AgentsReuseables/functions/formatBytes";
import TermsModal from "../reusables/TermsCondition";

const regexMATANIDPattern = /MATAN\/[^/]+\/\d+/;

export default function RegistrationForm() {
  const navigate = useNavigate();
  const { listAssociations } = adminAuth();
  const {
    agentState,
    modalOpen,
    setModalOpen,
    toastMessage,
    validateBVN,
    createMember,
    resendUserOtp,
    verifyUserOtp,
    getAllBranches,
  } = AgentAuth();
  const modalRef = useRef();
  const queryClient = useQueryClient();

  const [errors, setErrors] = useState({});
  const [signature, setSignature] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [LGA, setLGA] = useState([]);
  // const [allBranches, setAllBranches] = useState([]);
  const [allBranches, setAllBranches] = useState(() => {
    try {
      const savedBranches = sessionStorage.getItem("allBranches");
      return savedBranches ? JSON.parse(savedBranches) : [];
    } catch (error) {
      console.error("Error parsing allBranches from localStorage", error);
      return [];
    }
  });

  const [businessAssociations, setBusinessAssociations] = useState(() => {
    try {
      const savedAssociations = sessionStorage.getItem("businessAssociations");
      return savedAssociations ? JSON.parse(savedAssociations) : [];
    } catch (error) {
      console.error(
        "Error parsing businessAssociations from localStorage",
        error
      );
      return [];
    }
  });

  // const [businessAssociations, setBusinessAssociations] = useState([]);
  const [error, setError] = useState(null);
  const [passportError, setPassportError] = useState(null);
  const [formData, setFormData] = useState(initialState);
  const [openTab, setOpenTab] = useState(0);
  const [validatedBVN, setValidatedBVN] = useState(false);
  const [validatedOTP, setValidatedOTP] = useState(false);
  const [successResponse, setSuccessResponse] = useState("");
  const [showBVN, setShowBVN] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(true);

  const allStates = Object.keys(NigerianState);
  const MAX_FILE_SIZE = 2 * 1024 * 1024;

  const toggleBVNVisibility = () => {
    setShowBVN(!showBVN);
  };

  const handleFileSignatureChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
    ];

    if (selectedFile) {
      if (selectedFile.size > MAX_FILE_SIZE) {
        setError(
          `File size exceeds the maximum allowed (${formatBytes(
            MAX_FILE_SIZE
          )}).`
        );
        setSignature(null);
      } else if (!allowedTypes.includes(selectedFile.type)) {
        setError("Please upload a valid image file (SVG, PNG, JPG, or GIF)");
        setSignature(null);
      } else {
        setError(null);
        setSignature(selectedFile);
      }
    }
  };

  useEffect(() => {
    const handleFileToBase64 = (file, key, setFormDataCallback) => {
      if (file && file.size <= MAX_FILE_SIZE) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setFormDataCallback((prevFormData) => ({
            ...prevFormData,
            [key]: reader.result.split(",")[1],
          }));
        };
      }
    };

    handleFileToBase64(signature, "signature", setFormData);
    handleFileToBase64(selectedFile, "passport", setFormData);
  }, [signature, selectedFile]);

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalOpen]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = [
      "image/svg+xml",
      "image/png",
      "image/jpeg",
      "image/gif",
    ];

    if (file) {
      if (!allowedTypes.includes(file.type)) {
        setPassportError(
          "Please upload a valid image file (SVG, PNG, JPG, or GIF)"
        );
        setSelectedFile(null);
      } else {
        setPassportError(null);
        setSelectedFile(file);
      }
    }
  };

  const handleBlur = async (e) => {
    const { name, value } = e.target;
    try {
      await userSchema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: undefined,
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleBusinessState = (value) => {
    setLGA(NigerianState[value]);
    setFormData((prevData) => ({
      ...prevData,
      businessState: value,
    }));
  };
  const handleBusinessLGA = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      businessLocalGovernment: value,
    }));
  };
  const handleBusinessAssociationChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      businessAssociationName: value,
    }));
    sessionStorage.setItem("businessAssociationName", value);
  };
  const handleCategoryChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      businessCategoryOfService: value,
    }));
  };
  const handleCategorySubChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      businessSubCategory: value,
    }));
  };

  const handleBranchChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      BranchCode: value,
    }));
    sessionStorage.setItem("BranchCode", value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "NextOfKinName" || name === "occupation" || name === "title") {
      const re = /^[a-zA-Z\s]*$/;
      if (re.test(value) || value === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Only letters and spaces are allowed",
        }));
      }
    } else if (name === "dateOfBirth" || name === "nextofKinDateOfBirth") {
      // Validation for dateOfBirth field
      const enteredDate = new Date(value);
      const today = new Date();
      if (enteredDate < today) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Date of birth must be before today",
        }));
      }
    } else {
      // For other fields
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      // Clear error for other fields
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const { isLoading: allBranchesLoading, refetch: refetchAllBranches } =
    useQuery({
      queryKey: ["allbranches"],
      queryFn: async () => {
        const response = await getAllBranches();
        setAllBranches(response);
        sessionStorage.setItem("allBranches", JSON.stringify(response));
        return response;
      },
      staleTime: 1000,
      enabled: allBranches?.length < 1,
    });

  const { isLoading: associationsLoading, refetch: refetchAssociations } =
    useQuery({
      queryKey: ["associations"],
      queryFn: async () => {
        const associationList = await listAssociations();
        setBusinessAssociations(associationList.Data?.Items || []);
        sessionStorage.setItem(
          "businessAssociations",
          JSON.stringify(associationList.Data?.Items || [])
        );
        return associationList;
      },
      staleTime: 1000,
      enabled: businessAssociations?.length < 1,
    });

  const validateUserBVN = async (event) => {
    event.preventDefault();
    try {
      if (!isTermsAccepted) {
        toast("You must accept the terms and conditions", { type: "error" });
        return;
      }
      setLoading(true);
      const validationPayload = {
        bvn: formData.bvn,
        dateOfBirth: formData.dateOfBirth.split("-").reverse().join("/"),
      };

      const response = await validateBVN(validationPayload);

      if (response.Code === "99") {
        toastMessage("error", response.Description);
        setLoading(false);
        return;
      }

      const UserRecord = JSON.parse(response);
      if (UserRecord.ResponseCode === "00") {
        setFormData((data) => ({
          ...data,
          firstName: UserRecord.FirstName || "",
          lastName: UserRecord.LastName || "",
          middleName: UserRecord.MiddleName || "",
          title: UserRecord.Title || "",
          phoneNumber: UserRecord.PhoneNumber || UserRecord.PhoneNumber2 || "",
          nin: UserRecord.NIN || "",
          dateOfBirth:
            formData.dateOfBirth.split("-").reverse().join("/") || "",
          email: UserRecord.Email || "",
          tin: UserRecord.TIN || "",
          stateOfOrigin: UserRecord.StateOfOrigin?.split(" ")[0] || "",
          gender: UserRecord.Gender || "",
          bvn: UserRecord.BVN || "",
        }));

        setLoading(false);
        setValidatedBVN(true);
      } else if (UserRecord.ResponseCode !== "00") {
        toastMessage("error", "Wrong BVN Validation Record Sent");
        setLoading(false);
      } else {
        throw new Error();
      }
    } catch (error) {
      // console.log(error, "error");
      // toastMessage("error", "Network error please try again later");

      setLoading(false);
    }
  };

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      await userSchema.validate(formData, { abortEarly: false });

      formData.EmailAddress = formData.email;
      const selectedBranchCode = sessionStorage.getItem("BranchCode");
      const selectedBusinessAssociationName = sessionStorage.getItem(
        "businessAssociationName"
      );
      const BranchCode = allBranches.find(
        (item) => item.branch == selectedBranchCode || formData.BranchCode
      );
      const AssociationID = businessAssociations.find(
        (item) =>
          item.Name == selectedBusinessAssociationName ||
          formData.businessAssociationName
      );

      formData.BranchCode = BranchCode?.brancH_CODE;
      formData.AssociationId = AssociationID?.Id;

      formData.nextofKinDateOfBirth = formData.nextofKinDateOfBirth
        .split("-")
        .reverse()
        .join("/");

      formData.BusinessSubCategory =
        businesSubCategory.indexOf(formData.businessCategory) || "1";
      formData.BusinessCategoryOfService =
        businessCategory.indexOf(formData.businessSubCategory) || "1";
      formData.tin = formData.tin;

      const response = await createMember(convertKeysToPascalCase(formData));

      if (response.Code !== "00") {
        toastMessage("error", response.Description);
        setLoading(false);
        return;
      }

      if (
        response.Code === "00" &&
        response?.Description.includes("Congratulations")
      ) {
        toastMessage("success", "Successfully registered a new member");
        setSuccessResponse(response?.Description);
        setOpenTab((openTab) => openTab + 1);
      } else if (
        response.Code === "00" &&
        !response?.Description.includes("Congratulations")
      ) {
        toastMessage("error", "Registration Failed");
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error);
      toastMessage("error", "Network error please try again later");
    } finally {
      setLoading(false);
    }
  };

  const verifyOtp = async (data) => {
    setLoading(true);
    // refetchData();
    const payload = {
      EmailAddress: formData.email || formData.EmailAddress,
      Otp: data,
      PhoneNumber: formData.phoneNumber || formData.PhoneNumber,
    };

    const response = await verifyUserOtp(payload);
    if (response && response.Code == "00") {
      setValidatedOTP(true);
      setOpenTab(1);
      toastMessage("success", "BVN Verified Successfully");
    } else {
      toastMessage("error", " Wrong OTP entered BVN Verification Failed");
    }
    setLoading(false);
  };

  const callCesendOtp = async (request) => {
    setLoading(true);
    if (request) {
      const payload = {
        EmailAddress: formData.email || formData.EmailAddress,
      };
      const response = await resendUserOtp(payload);
      toastMessage("success", response);
    }

    setLoading(false);
  };

  let isPersonalDetailsFilled =
    isFormNotEmpty(personalDetails, formData, errors) &&
    !error &&
    !passportError;
  const isBusinessDetailsFilled = isFormNotEmpty(
    initialBusinessDetails,
    formData,
    errors
  );

  isPersonalDetailsFilled =
    isPersonalDetailsFilled && formData.signature && formData.passport;

  const isNextOfKinDetailsFilled = isFormNotEmpty(
    initialNextOfKinDetails,
    formData,
    errors
  );
  const isFormAllField = isFormNotEmpty(
    Object.keys(initialState),
    formData,
    errors
  );

  return (
    <div className="w-screen h-screen flex flex-row">
      <section className="w-3/12  h-screen hidden md:block ">
        <div className=" hidden md:flex fixed flex flex-col w-1/5 p-1 text-black  rounded-lg items-center justify-start h-screen z-20  overflow-y-auto bg-green-700">
          {openTab <= 4 && <FormStepper openTab={openTab} />}
        </div>
      </section>
      <section className="col-md-8 col-sm-8 col-lg-8 flex-column d-flex justify-content-center w-5/6 ">
        <div>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-2  rounded">
            <div className="justify-center items-center px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab <= 1 ? "block" : "hidden"} id="link1">
                  <>
                    {!validatedBVN ? (
                      <form
                        onSubmit={validateUserBVN}
                        className="w-full mx-2 mt-4 p-4 rounded-lg"
                      >
                        <h3 className="text-xl font-semibold my-4">
                          BVN Verification
                        </h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-auto mb-14">
                          <div>
                            <label
                              htmlFor="bvn"
                              className="block text-xs font-medium text-gray-700"
                            >
                              BVN
                            </label>
                            <div className="relative">
                              <input
                                type={showBVN ? "number" : "password"}
                                id="bvn"
                                name="bvn"
                                value={formData.bvn}
                                onChange={handleChange}
                                placeholder="Enter BVN Number"
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.bvn && "border-red-500"
                                }`}
                                required
                                length="11"
                                onBlur={handleBlur}
                              />
                              <span
                                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                                onClick={toggleBVNVisibility}
                              >
                                {showBVN ? <FaRegEyeSlash /> : <FaRegEye />}
                              </span>
                            </div>

                            {
                              <div className="text-red-500 text-xs mt-1">
                                {errors.bvn}
                              </div>
                            }
                          </div>

                          <div>
                            <label
                              htmlFor="dateOfBirth"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Date of birth
                            </label>
                            <input
                              type="date"
                              id="dateOfBirth"
                              name="dateOfBirth"
                              placeholder="dd/mm/yyyy"
                              onBlur={handleBlur}
                              value={formData.dateOfBirth}
                              onChange={handleChange}
                              className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                errors.dateOfBirth && "border-red-500"
                              }`}
                              required
                            />
                            {
                              <div className="text-red-500 text-xs mt-1">
                                {errors.dateOfBirth}
                              </div>
                            }
                          </div>
                        </div>

                        {/* <div className="flex items-center justify-center mb-4">
                          <button
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                            className="text-green-900 hover:text-indigo-500 mb-4 text-xl font-semibold"
                          >
                            Read and accept terms & conditions
                          </button>
                        </div> */}
                        <div className="grid grid-cols-1 md:grid-cols-1 gap-4 h-8 items-center justify-center">
                          <button
                            className={`text-white font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${
                              !formData.bvn ||
                              errors.bvn ||
                              errors.dateOfBirth ||
                              loading
                                ? "bg-gray-200 text-black"
                                : "bg-emerald-700"
                            }`}
                            type="submit"
                            disabled={
                              !formData.bvn ||
                              errors.bvn ||
                              errors.dateOfBirth ||
                              loading
                            }
                          >
                            {"Next"}
                          </button>
                        </div>
                      </form>
                    ) : validateBVN && !validatedOTP ? (
                      <OTPInput
                        verifyOtp={verifyOtp}
                        callCesendOtp={callCesendOtp}
                      />
                    ) : (
                      <div className="container mx-auto mt-1 p-5">
                        <form
                          onSubmit={handleFormSubmit}
                          className="w-full mx-2"
                        >
                          <h3 className="text-xl font-semibold mt-4">
                            Personal Details
                          </h3>
                          <p className="mb-6">
                            Provide correct personal details information{" "}
                          </p>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="mb-8">
                              <span
                                htmlFor="passport"
                                className="block text-xs font-medium text-gray-700 mb-2"
                              >
                                Upload Passport |{" "}
                                <i className="text-xs text-red-400">
                                  image should not be more than 2MB
                                </i>
                              </span>
                              <label
                                className="flex flex-col items-center cursor-pointer appearance-none rounded-md border border-gray-300 bg-white p-4 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                                tabIndex="0"
                              >
                                <div className="rounded-full bg-gray-100 p-2 mb-2">
                                  <LuUploadCloud className="text-2xl text-gray-600" />
                                </div>
                                <span className="text-xs font-bold text-gray-600">
                                  Click to upload{" "}
                                  <span className="text-gray-400 font-medium">
                                    or drag and drop
                                  </span>
                                </span>
                                <span className="text-xs text-gray-600">
                                  SVG, PNG, JPG, or GIF (max. 800x400px)
                                </span>
                                <input
                                  type="file"
                                  className="sr-only"
                                  onChange={handleFileChange}
                                  accept="image/*"
                                />
                                {passportError && (
                                  <p className="text-xs text-red-500">
                                    {passportError}
                                  </p>
                                )}{" "}
                              </label>
                            </div>

                            <div className="flex items-start justify-start">
                              {!selectedFile && (
                                <div className="ml-4 mt-6 rounded-full bg-gray-100 p-2">
                                  <GoPerson className="text-7xl text-slate-600" />
                                </div>
                              )}
                              {selectedFile && (
                                <div className="ml-4 mt-6">
                                  <div className="rounded-full overflow-hidden bg-gray-300">
                                    <img
                                      // src={URL.createObjectURL(selectedFile)}
                                      src={
                                        "data:image/jpg;base64," +
                                        formData.passport
                                      }
                                      alt="Selected File"
                                      style={{
                                        width: "80px",
                                        height: "80px",
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            <div>
                              <label
                                htmlFor="bvn"
                                className="block text-xs font-medium text-gray-700"
                              >
                                BVN
                              </label>
                              <input
                                type="password"
                                id="bvn"
                                name="bvn"
                                value={formData.bvn}
                                onChange={handleChange}
                                placeholder="Enter BVN Number"
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.bvn && "border-red-500"
                                }`}
                                required
                                length="11"
                                onBlur={handleBlur}
                                disabled
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.bvn}
                                </div>
                              }
                            </div>

                            <div>
                              <label
                                htmlFor="nin"
                                className="block text-sm font-medium text-gray-700"
                              >
                                NIN
                              </label>
                              <input
                                type="number"
                                id="nin"
                                name="nin"
                                // value={formData.nin}
                                // onChange={handleUserChange}
                                value={formData.nin}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter NIN Number"
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.nin && "border-red-500"
                                }`}
                                required
                                length="11"
                                // disabled
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.nin}
                                </div>
                              }
                            </div>

                            <div>
                              <label
                                htmlFor="firstName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                First Name
                              </label>
                              <input
                                type="firstName"
                                id="firstName"
                                name="firstName"
                                // value={formData.firstName}
                                // onChange={handleUserChange}
                                placeholder="Enter First Name"
                                onBlur={handleBlur}
                                value={formData.firstName}
                                onChange={handleChange}
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.firstName && "border-red-500"
                                }`}
                                required
                                disabled
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.firstName}
                                </div>
                              }
                            </div>
                            <div>
                              <label
                                htmlFor="lastName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Last Name
                              </label>
                              <input
                                type="lastName"
                                id="lastName"
                                name="lastName"
                                placeholder="Enter Last Name"
                                value={formData.lastName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.lastName && "border-red-500"
                                }`}
                                required
                                disabled
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.lastName}
                                </div>
                              }
                            </div>
                            <div>
                              <label
                                htmlFor="lastName"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Middle Name
                              </label>
                              <input
                                type="middleName"
                                id="middleName"
                                name="middleName"
                                placeholder="Enter Middle Name"
                                onBlur={handleBlur}
                                value={formData.middleName}
                                onChange={handleChange}
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.middleName && "border-red-500"
                                }`}
                                required
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.middleName}
                                </div>
                              }
                            </div>
                            <div>
                              <label
                                htmlFor="title"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Title
                              </label>
                              <input
                                type="title"
                                id="title"
                                name="title"
                                placeholder="Enter your title"
                                onBlur={handleBlur}
                                value={formData.title}
                                onChange={handleChange}
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.title && "border-red-500"
                                }`}
                                required
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.title}
                                </div>
                              }
                            </div>
                            <div>
                              <label
                                htmlFor="phoneNumber"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Phone Number
                              </label>
                              <input
                                type="phoneNumber"
                                id="phoneNumber"
                                name="phoneNumber"
                                placeholder="Enter Phone Number"
                                onBlur={handleBlur}
                                value={formData.phoneNumber}
                                onChange={handleChange}
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.phoneNumber && "border-red-500"
                                }`}
                                required
                                disabled
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.phoneNumber}
                                </div>
                              }
                            </div>
                            <div>
                              <label
                                htmlFor="dateOfBirth"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Date of birth
                              </label>
                              <input
                                // type="date"
                                id="dateOfBirth"
                                name="dateOfBirth"
                                placeholder="dd/mm/yyyy"
                                onBlur={handleBlur}
                                value={formData.dateOfBirth}
                                onChange={handleChange}
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.dateOfBirth && "border-red-500"
                                }`}
                                required
                                disabled
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.dateOfBirth}
                                </div>
                              }
                            </div>
                            <div>
                              <label
                                htmlFor="gender"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Gender
                              </label>
                              {/* <Select
                                      options={["Male", "Female"]}
                                      onSelectChange={handleGenderChange}
                                    /> */}
                              <input
                                type="text"
                                id="gender"
                                name="gender"
                                value={formData.gender}
                                onChange={handleChange}
                                placeholder="Enter Gender"
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.gender && "border-red-500"
                                }`}
                                required
                                length="11"
                                onBlur={handleBlur}
                                disabled
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.gender}
                                </div>
                              }
                            </div>
                            <div>
                              <label
                                htmlFor="stateOfOrigin"
                                className="block text-sm font-medium text-gray-700"
                              >
                                State of Origin
                              </label>
                              {/* <Select
                                      options={allStates}
                                      onSelectChange={handleStateChange}
                                    /> */}

                              <input
                                type="text"
                                id="stateOfOrigin"
                                name="stateOfOrigin"
                                value={formData.stateOfOrigin}
                                onChange={handleChange}
                                placeholder="Enter State Of Origin"
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.stateOfOrigin && "border-red-500"
                                }`}
                                required
                                length="11"
                                onBlur={handleBlur}
                                disabled
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.stateOfOrigin}
                                </div>
                              }
                            </div>
                            <div>
                              <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Email Address
                              </label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                placeholder="Enter Email Address"
                                onBlur={handleBlur}
                                value={formData.email}
                                onChange={handleChange}
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.email && "border-red-500"
                                }`}
                                required
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.email}
                                </div>
                              }{" "}
                            </div>
                            <div>
                              <label
                                htmlFor="tin"
                                className="block text-sm font-medium text-gray-700"
                              >
                                TIN
                              </label>
                              <input
                                type="tin"
                                id="tin"
                                name="tin"
                                placeholder="Enter TIN Number"
                                onBlur={handleBlur}
                                value={formData.tin}
                                onChange={handleChange}
                                className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                                  errors.tin && "border-red-500"
                                }`}
                              />
                              {
                                <div className="text-red-500 text-xs mt-1">
                                  {errors.tin}
                                </div>
                              }{" "}
                            </div>

                            <div className="flex flex-col">
                              <div>
                                <span
                                  htmlFor="signature"
                                  className="block text-xs font-medium text-gray-700 mb-2"
                                >
                                  Upload Signature |{" "}
                                  <i className="text-xs text-red-400">
                                    file should not be more than 2MB
                                  </i>
                                </span>
                                <label
                                  className="flex flex-col items-center cursor-pointer appearance-none rounded-md border border-gray-300 bg-white p-4 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                                  tabIndex="0"
                                >
                                  <div className="rounded-full bg-gray-100 p-2 mb-2">
                                    <LuUploadCloud className="text-2xl text-gray-600" />
                                  </div>

                                  <span className="text-xs font-bold text-gray-600">
                                    Click to upload{" "}
                                    <span className="text-gray-400 font-medium">
                                      or drag and drop
                                    </span>
                                  </span>

                                  <span className="text-xs text-gray-600">
                                    SVG, PNG, JPG, or GIF (max. 800x400px)
                                  </span>

                                  <input
                                    type="file"
                                    className="sr-only"
                                    onChange={handleFileSignatureChange}
                                    accept="image/*"
                                  />
                                  {error && (
                                    <p className="text-xs text-red-500">
                                      {error}
                                    </p>
                                  )}
                                </label>
                              </div>
                              {signature && (
                                <div className="mt-4 border border-gray-300 rounded-md p-4">
                                  {signature.type.startsWith("image/") ? (
                                    <img
                                      // src={URL.createObjectURL(signature)}
                                      src={
                                        "data:image/jpg;base64," +
                                        formData.signature
                                      }
                                      alt="Uploaded File"
                                      className="rounded-md max-w-full max-h-32"
                                    />
                                  ) : (
                                    <div className="flex flex-row">
                                      <div
                                        className={`border-4 rounded-full p-1 inline-flex border-green-100 shadow-l bg-green-100`}
                                        data-aos="zoom-in"
                                        data-aos-duration="800"
                                        data-aos-delay="100"
                                      >
                                        <CiFileOn className="text-xl" />
                                      </div>
                                      <div className="flex flex-col ml-4">
                                        <p className="text-xs text-gray-600 font-bold">
                                          {`${signature.name}  ${signature.name
                                            .split(".")
                                            .pop()}`}{" "}
                                        </p>
                                        <p className="text-xs text-gray-600">
                                          {formatBytes(signature.size)}- 100%
                                          uploaded
                                        </p>
                                      </div>

                                      <GiCheckMark className="text-xl text-white bg-green-700 border-1 rounded-full ml-auto" />
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </>
                </div>
                <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                  <div className=" mx-auto p-5">
                    <form onSubmit={handleFormSubmit} className="w-full mx-2">
                      <h3 className="text-xl font-semibold mt-4">
                        Business/ Other Details
                      </h3>
                      <p className="mb-6">
                        Provide correct Business/Other details information
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="occupation"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Occupation
                          </label>
                          <input
                            type="text"
                            id="occupation"
                            name="occupation"
                            placeholder="Enter Occupation"
                            onBlur={handleBlur}
                            value={formData.occupation}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.occupation && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.occupation}
                            </div>
                          }
                        </div>

                        {/* Last Name */}
                        <div>
                          <label
                            htmlFor="businessAddressNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Address Number
                          </label>
                          <input
                            type="text"
                            id="businessAddressNumber"
                            name="businessAddressNumber"
                            placeholder="Enter Address Number"
                            onBlur={handleBlur}
                            value={formData.businessAddressNumber}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessAddressNumber && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.businessAddressNumber}
                            </div>
                          }
                        </div>

                        <div>
                          <label
                            htmlFor="businessState"
                            className="block text-sm font-medium text-gray-700"
                          >
                            State of Business
                          </label>
                          <Select
                            options={allStates}
                            onSelectChange={handleBusinessState}
                          />
                        </div>
                        <div disabled={LGA.length < 1}>
                          <label
                            htmlFor="businessLocalGovernment"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Local Government
                          </label>
                          <Select
                            options={LGA}
                            onSelectChange={handleBusinessLGA}
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="businessCity"
                            className="block text-sm font-medium text-gray-700"
                          >
                            City/Town
                          </label>
                          <input
                            type="businessCity"
                            id="businessCity"
                            name="businessCity"
                            placeholder="Enter City"
                            onBlur={handleBlur}
                            value={formData.businessCity}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessCity && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.businessCity}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="businessAssociationName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Association Name
                          </label>
                          <Select
                            options={[
                              ...businessAssociations?.map(
                                (item, index) => item.Name
                              ),
                            ]}
                            onSelectChange={handleBusinessAssociationChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="businessCategoryOfService"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Category of Service
                          </label>
                          <Select
                            options={businessCategory}
                            onSelectChange={handleCategoryChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="businessSubCategory"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sub Category of Service
                          </label>
                          <Select
                            options={businesSubCategory}
                            onSelectChange={handleCategorySubChange}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="businessName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name of Business
                          </label>
                          <input
                            type="text"
                            id="businessName"
                            name="businessName"
                            placeholder="Enter Business Name"
                            onBlur={handleBlur}
                            value={formData.businessName}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessName && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.businessName}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="businessRegistrationNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Business Registration Number (Optional)
                          </label>
                          <input
                            type="text"
                            id="businessRegistrationNumber"
                            name="businessRegistrationNumber"
                            placeholder="Enter Business Registration Number"
                            onBlur={handleBlur}
                            value={formData.businessRegistrationNumber}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessRegistrationNumber &&
                              "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.businessRegistrationNumber}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="marketName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Market Name
                          </label>
                          <input
                            type="text"
                            id="marketName"
                            name="marketName"
                            placeholder="Enter Market Name"
                            onBlur={handleBlur}
                            value={formData.marketName}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.marketName && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.marketName}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="marketAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Market Address
                          </label>
                          <input
                            type="text"
                            id="marketAddress"
                            name="marketAddress"
                            placeholder="Enter Market Address"
                            onBlur={handleBlur}
                            value={formData.marketAddress}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.marketAddress && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.marketAddress}
                            </div>
                          }
                        </div>

                        <div>
                          <label
                            htmlFor="branchCode"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Account Branch
                          </label>
                          {allBranches && (
                            <Select
                              options={[
                                ...allBranches?.map(
                                  (item, index) => item.branch
                                ),
                              ]}
                              onSelectChange={handleBranchChange}
                            />
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <div className=" mx-auto mt-1 p-5">
                    <form onSubmit={handleFormSubmit} className="w-full mx-2">
                      <h3 className="text-xl font-semibold mt-4">
                        Next of Kin Details
                      </h3>
                      <p className="mb-6">
                        Provide correct Next of Kin details
                      </p>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="NextOfKinName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name of Next of Kin
                          </label>
                          <input
                            type="text"
                            id="NextOfKinName"
                            name="NextOfKinName"
                            placeholder="Enter Full Name"
                            onBlur={handleBlur}
                            value={formData.NextOfKinName}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.NextOfKinName && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.NextOfKinName}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="nextofKinDateOfBirth"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Next of Kin DOB
                          </label>
                          <input
                            type="date"
                            id="nextofKinDateOfBirth"
                            name="nextofKinDateOfBirth"
                            placeholder="dd/mm/yyyy"
                            onBlur={handleBlur}
                            value={formData.nextofKinDateOfBirth}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nextofKinDateOfBirth && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nextofKinDateOfBirth}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="nextofKinPhoneNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Next of Kin Phone Number
                          </label>
                          <input
                            type="number"
                            id="nextofKinPhoneNumber"
                            name="nextofKinPhoneNumber"
                            placeholder="Enter Phone Number"
                            onBlur={handleBlur}
                            value={formData.nextofKinPhoneNumber}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nextofKinPhoneNumber && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nextofKinPhoneNumber}
                            </div>
                          }
                        </div>

                        <div>
                          <label
                            htmlFor="nextofKinEmailAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Next of Kin Email Address
                          </label>
                          <input
                            type="email"
                            id="nextofKinEmailAddress"
                            name="nextofKinEmailAddress"
                            placeholder="Enter Email Address"
                            onBlur={handleBlur}
                            value={formData.nextofKinEmailAddress}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nextofKinEmailAddress && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nextofKinEmailAddress}
                            </div>
                          }
                        </div>

                        {/* next of kin address */}
                        <div>
                          <label
                            htmlFor="nextofKinHomeAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Next of Kin Home Address
                          </label>
                          <input
                            type="text"
                            id="nextofKinHomeAddress"
                            name="nextofKinHomeAddress"
                            placeholder="Enter Home Address"
                            onBlur={handleBlur}
                            value={formData.nextofKinHomeAddress}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nextofKinHomeAddress && "border-red-500"
                            }`}
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nextofKinHomeAddress}
                            </div>
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className={openTab === 4 ? "block" : "hidden"} id="link3">
                  <h3 className="text-xl font-semibold mt-4">Review Details</h3>
                  <p className="mb-6">Confirm all provided Details</p>
                  <div className="px-6">
                    <br />
                    <div className="flex items-center ">
                      <span className="mr-2 text-xl">Personal details</span>
                      <hr className="flex-grow border-t border-gray-300" />
                      <hr className="flex-grow border-t border-gray-300" />
                    </div>
                    <br />
                    <br />
                    <div className="w-full mx-2">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-8">
                          <span
                            htmlFor="passport"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Uploaded Passport
                          </span>
                          <label
                            className="flex flex-col items-center cursor-pointer appearance-none rounded-md border border-gray-300 bg-white p-4 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                            tabIndex="0"
                          >
                            <div className="rounded-full bg-gray-100 p-2 mb-2">
                              <LuUploadCloud className="text-2xl text-gray-600" />
                            </div>

                            <span className="text-xs font-bold text-gray-600">
                              Click to upload{" "}
                              <span className="text-gray-400 font-medium">
                                or drag and drop
                              </span>
                            </span>

                            <span className="text-xs text-gray-600">
                              SVG, PNG, JPG, or GIF (max. 800x400px)
                            </span>

                            <input
                              type="file"
                              className="sr-only"
                              onChange={handleFileChange}
                              accept="image/*"
                              disabled
                            />
                          </label>
                        </div>

                        <div className="flex items-start justify-start">
                          {!selectedFile && (
                            <div className="ml-4 mt-6 rounded-full bg-gray-100 p-2">
                              <GoPerson className="text-7xl text-slate-600" />
                            </div>
                          )}
                          {selectedFile && (
                            <div className="ml-4 mt-6">
                              <div className="rounded-full overflow-hidden bg-gray-300">
                                <img
                                  // src={URL.createObjectURL(selectedFile)}
                                  src={
                                    "data:image/jpg;base64," + formData.passport
                                  }
                                  alt="Selected File"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div>
                          <label
                            htmlFor="bvn"
                            className="block text-xs font-medium text-gray-700"
                          >
                            BVN
                          </label>
                          <input
                            type="password"
                            id="bvn"
                            name="bvn"
                            value={formData.bvn}
                            onChange={handleChange}
                            placeholder="Enter BVN Number"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.bvn && "border-red-500"
                            }`}
                            required
                            length="11"
                            onBlur={handleBlur}
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.bvn}
                            </div>
                          }
                        </div>

                        <div>
                          <label
                            htmlFor="nin"
                            className="block text-sm font-medium text-gray-700"
                          >
                            NIN
                          </label>
                          <input
                            type="text"
                            id="nin"
                            name="nin"
                            value={formData.nin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter NIN Number"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nin && "border-red-500"
                            }`}
                            required
                            length="11"
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nin}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="firstName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First Name
                          </label>
                          <input
                            type="firstName"
                            id="firstName"
                            name="firstName"
                            placeholder="Enter First Name"
                            onBlur={handleBlur}
                            value={formData.firstName}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.firstName && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.firstName}
                            </div>
                          }
                        </div>

                        <div>
                          <label
                            htmlFor="lastName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last Name
                          </label>
                          <input
                            type="lastName"
                            id="lastName"
                            name="lastName"
                            placeholder="Enter Last Name"
                            value={formData.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.lastName && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.lastName}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="lastName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Middle Name
                          </label>
                          <input
                            type="middleName"
                            id="middleName"
                            name="middleName"
                            placeholder="Enter Middle Name"
                            onBlur={handleBlur}
                            value={formData.middleName}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.middleName && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.middleName}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title
                          </label>
                          <input
                            type="title"
                            id="title"
                            name="title"
                            placeholder="Enter Middle Name"
                            onBlur={handleBlur}
                            value={formData.title}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.title && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.title}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="phoneNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone Number
                          </label>
                          <input
                            type="phoneNumber"
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            onBlur={handleBlur}
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.phoneNumber && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.phoneNumber}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="dateOfBirth"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Date of birth
                          </label>
                          <input
                            type="text"
                            id="dateOfBirth"
                            name="dateOfBirth"
                            placeholder="dd/mm/yyyy"
                            onBlur={handleBlur}
                            value={formData.dateOfBirth}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.dateOfBirth && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.dateOfBirth}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="gender"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Gender
                          </label>

                          <input
                            type="text"
                            id="gender"
                            name="gender"
                            value={formData.gender}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter gender"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.gender && "border-red-500"
                            }`}
                            required
                            length="11"
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.gender}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="stateOfOrigin"
                            className="block text-sm font-medium text-gray-700"
                          >
                            State of Origin
                          </label>

                          <input
                            type="text"
                            id="stateOfOrigin"
                            name="stateOfOrigin"
                            value={formData.stateOfOrigin}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter gender"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.stateOfOrigin && "border-red-500"
                            }`}
                            required
                            length="11"
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.stateOfOrigin}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email Address
                          </label>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter Email Address"
                            onBlur={handleBlur}
                            value={formData.email}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.email && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.email}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="tin"
                            className="block text-sm font-medium text-gray-700"
                          >
                            TIN
                          </label>
                          <input
                            type="tin"
                            id="tin"
                            name="tin"
                            placeholder="Enter TIN Number"
                            onBlur={handleBlur}
                            value={formData.tin}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.tin && "border-red-500"
                            }`}
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.tin}
                            </div>
                          }
                        </div>

                        <div className="flex flex-col">
                          <div>
                            <span
                              htmlFor="signature"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Upload Signature
                            </span>
                            <label
                              className="flex flex-col items-center cursor-pointer appearance-none rounded-md border border-gray-300 bg-white p-4 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                              tabIndex="0"
                            >
                              <div className="rounded-full bg-gray-100 p-2 mb-2">
                                <LuUploadCloud className="text-2xl text-gray-600" />
                              </div>

                              <span className="text-xs font-bold text-gray-600">
                                Click to upload{" "}
                                <span className="text-gray-400 font-medium">
                                  or drag and drop
                                </span>
                              </span>

                              <span className="text-xs text-gray-600">
                                SVG, PNG, JPG, or GIF (max. 800x400px)
                              </span>

                              <input
                                type="file"
                                className="sr-only"
                                onChange={handleFileSignatureChange}
                                accept="image/*"
                                disabled
                              />
                              {error && (
                                <p className="text-xs text-red-500">{error}</p>
                              )}
                            </label>
                          </div>
                          {signature && (
                            <div className="mt-4 border border-gray-300 rounded-md p-4">
                              {signature.type.startsWith("image/") ? (
                                <img
                                  // src={URL.createObjectURL(signature)}
                                  src={
                                    "data:image/jpg;base64," +
                                    formData.signature
                                  }
                                  alt="Uploaded File"
                                  className="rounded-md max-w-full max-h-32"
                                />
                              ) : (
                                <div className="flex flex-row">
                                  <div
                                    className={`border-4 rounded-full p-1 inline-flex border-green-100 shadow-l bg-green-100`}
                                    data-aos="zoom-in"
                                    data-aos-duration="800"
                                    data-aos-delay="100"
                                  >
                                    <CiFileOn className="text-xl" />
                                  </div>
                                  <div className="flex flex-col ml-4">
                                    <p className="text-xs text-gray-600 font-bold">
                                      {`${signature.name}  ${signature.name
                                        .split(".")
                                        .pop()}`}{" "}
                                    </p>
                                    <p className="text-xs text-gray-600">
                                      {formatBytes(signature.size)}- 100%
                                      uploaded
                                    </p>
                                  </div>

                                  <GiCheckMark className="text-xl text-white bg-green-700 border-1 rounded-full ml-auto" />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* second form */}
                    <br />
                    <br />
                    <div className="flex items-center ">
                      <span className="mr-2 text-xl">
                        Business/Account details
                      </span>
                      <hr className="flex-grow border-t border-gray-300" />
                      <hr className="flex-grow border-t border-gray-300" />
                    </div>
                    <br />
                    <br />
                    <div className="w-full mx-2">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="occupation"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Occupation
                          </label>
                          <input
                            type="text"
                            id="occupation"
                            name="occupation"
                            placeholder="Enter Occupation"
                            onBlur={handleBlur}
                            value={formData.occupation}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.occupation && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.occupation}
                            </div>
                          }
                        </div>

                        <div>
                          <label
                            htmlFor="businessAddressNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Address Number
                          </label>
                          <input
                            type="text"
                            id="businessAddressNumber"
                            name="businessAddressNumber"
                            placeholder="Enter Address Number"
                            onBlur={handleBlur}
                            value={formData.businessAddressNumber}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessAddressNumber && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.businessAddressNumber}
                            </div>
                          }
                        </div>

                        <div>
                          <label
                            htmlFor="businessState"
                            className="block text-sm font-medium text-gray-700"
                          >
                            State of Business
                          </label>
                          {/* <Select
                                  value={formData.businessState}
                                  options={allStates}
                                  onSelectChange={handleBusinessState}
                                /> */}
                          <input
                            type="text"
                            id="stateOfOrigin"
                            name="stateOfOrigin"
                            value={formData.businessState}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter gender"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessState && "border-red-500"
                            }`}
                            required
                            length="11"
                            disabled
                          />
                        </div>
                        <div disabled={LGA.length < 1}>
                          <label
                            htmlFor="businessLocalGovernment"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Local Government
                          </label>
                          {/* <Select
                                  value={formData.businessLocalGovernment}
                                  options={LGA}
                                  onSelectChange={handleBusinessLGA}
                                /> */}

                          <input
                            type="text"
                            id="stateOfOrigin"
                            name="stateOfOrigin"
                            value={formData.businessLocalGovernment}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter gender"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessLocalGovernment && "border-red-500"
                            }`}
                            required
                            length="11"
                            disabled
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="businessCity"
                            className="block text-sm font-medium text-gray-700"
                          >
                            City/Town
                          </label>
                          <input
                            type="businessCity"
                            id="businessCity"
                            name="businessCity"
                            placeholder="Enter City"
                            onBlur={handleBlur}
                            value={formData.businessCity}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessCity && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.businessCity}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="businessAssociationName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Association Name
                          </label>

                          <input
                            type="text"
                            id="stateOfOrigin"
                            name="stateOfOrigin"
                            value={formData.businessAssociationName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter gender"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessAssociationName && "border-red-500"
                            }`}
                            required
                            length="11"
                            disabled
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="businessCategoryOfService"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Category of Service
                          </label>

                          <input
                            type="text"
                            id="stateOfOrigin"
                            name="stateOfOrigin"
                            value={formData.businessCategoryOfService}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter gender"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessCategoryOfService &&
                              "border-red-500"
                            }`}
                            required
                            length="11"
                            disabled
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="businessSubCategory"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Sub Category of Service
                          </label>
                          {/* <Select
                                  value={formData.businessSubCategory}
                                  options={["Haulage", "Traders"]}
                                  onSelectChange={handleCategorySubChange}
                                /> */}
                          <input
                            type="text"
                            id="businessSubCategory"
                            name="businessSubCategory"
                            value={formData.businessSubCategory}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter gender"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessSubCategory && "border-red-500"
                            }`}
                            required
                            length="11"
                            disabled
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="businessName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name of Business
                          </label>
                          <input
                            type="text"
                            id="businessName"
                            name="businessName"
                            placeholder="Enter Business Name"
                            onBlur={handleBlur}
                            value={formData.businessName}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessName && "border-red-500"
                            }`}
                            disabled
                            required
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.businessName}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="businessRegistrationNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Business Registration Number (Optional)
                          </label>
                          <input
                            type="text"
                            id="businessRegistrationNumber"
                            name="businessRegistrationNumber"
                            placeholder="Enter Business Registration Number"
                            onBlur={handleBlur}
                            value={formData.businessRegistrationNumber}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.businessRegistrationNumber &&
                              "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.businessRegistrationNumber}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="marketName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Market Name
                          </label>
                          <input
                            type="text"
                            id="marketName"
                            name="marketName"
                            placeholder="Enter Market Name"
                            onBlur={handleBlur}
                            value={formData.marketName}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.marketName && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.marketName}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="marketAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Market Address
                          </label>
                          <input
                            type="text"
                            id="marketAddress"
                            name="marketAddress"
                            placeholder="Enter Market Address"
                            onBlur={handleBlur}
                            value={formData.marketAddress}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.marketAddress && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.marketAddress}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="businessSubCategory"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Account Branch{" "}
                          </label>

                          <input
                            type="text"
                            id="BranchCode"
                            name="BranchCode"
                            value={formData.BranchCode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Select branch code"
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.BranchCode && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* third form */}
                    <br />
                    <br />
                    <div className="flex items-center ">
                      <span className="mr-2 text-xl">Next of Kin details</span>
                      <hr className="flex-grow border-t border-gray-300" />
                      <hr className="flex-grow border-t border-gray-300" />
                    </div>
                    <br />
                    <br />
                    <div className="w-full mx-2">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div>
                          <label
                            htmlFor="NextOfKinName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name of Next of Kin
                          </label>
                          <input
                            type="text"
                            id="NextOfKinName"
                            name="NextOfKinName"
                            placeholder="Enter Full Name"
                            onBlur={handleBlur}
                            value={formData.NextOfKinName}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.NextOfKinName && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.NextOfKinName}
                            </div>
                          }
                        </div>
                        <div>
                          <label
                            htmlFor="nextofKinDateOfBirth"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Next of Kin DOB
                          </label>
                          <input
                            type="text"
                            id="nextofKinDateOfBirth"
                            name="nextofKinDateOfBirth"
                            placeholder="dd/mm/yyyy"
                            onBlur={handleBlur}
                            value={formData.nextofKinDateOfBirth}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nextofKinDateOfBirth && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nextofKinDateOfBirth}
                            </div>
                          }
                        </div>
                        {/* next of kin phone number */}

                        <div>
                          <label
                            htmlFor="nextofKinPhoneNumber"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Next of Kin Phone Number
                          </label>
                          <input
                            type="text"
                            id="nextofKinPhoneNumber"
                            name="nextofKinPhoneNumber"
                            placeholder="Enter Phone Number"
                            onBlur={handleBlur}
                            value={formData.nextofKinPhoneNumber}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nextofKinPhoneNumber && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nextofKinPhoneNumber}
                            </div>
                          }
                        </div>

                        <div>
                          <label
                            htmlFor="nextofKinEmailAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Next of Kin Email Address
                          </label>
                          <input
                            type="email"
                            id="nextofKinEmailAddress"
                            name="nextofKinEmailAddress"
                            placeholder="Enter Email Address"
                            onBlur={handleBlur}
                            value={formData.nextofKinEmailAddress}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nextofKinEmailAddress && "border-red-500"
                            }`}
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nextofKinEmailAddress}
                            </div>
                          }
                        </div>

                        {/* next of kin address */}
                        <div>
                          <label
                            htmlFor="nextofKinHomeAddress"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Next of Kin Home Address
                          </label>
                          <input
                            type="text"
                            id="nextofKinHomeAddress"
                            name="nextofKinHomeAddress"
                            placeholder="Enter Home Address"
                            onBlur={handleBlur}
                            value={formData.nextofKinHomeAddress}
                            onChange={handleChange}
                            className={`mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500 ${
                              errors.nextofKinHomeAddress && "border-red-500"
                            }`}
                            required
                            disabled
                          />
                          {
                            <div className="text-red-500 text-xs mt-1">
                              {errors.nextofKinHomeAddress}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                  <div className=" mx-auto mt-1 p-5">
                    <div className="flex flex-col items-center justify-center text-center m-12 p-12">
                      <div className="my-4">
                        <h4 className="text-4xl font-semibold text-gray-700 mb-6">
                          Registration Complete
                        </h4>
                      </div>
                      <p className="my-4">
                        Congratulations, your registration has been successfully
                        completed, and your MATAN ID is{" "}
                        <span className="font-bold">
                          {`${regexMATANIDPattern.exec(successResponse)}`}.
                        </span>
                      </p>
                      {/* Continue button */}
                      <div className="mt-4 w-full">
                        <button
                          className="bg-emerald-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none transition-all duration-150 w-1/2"
                          type="button"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          Continue
                        </button>
                      </div>
                      <p className="mt-2 text-green-700">
                        Your Alternative Bank Account will be created shortly
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*footer*/}
        {validatedOTP && validatedBVN && (
          <div
            className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b"
            disabled={loading}
          >
            <button
              className={`text-slate-700 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-solid border-slate-100 hover:bg-slate-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150 ${
                loading ? "bg-gray-200 text-black" : "bg-emerald-700"
              }`}
              type="button"
              onClick={() => {
                if (openTab > 1 && openTab <= 4) {
                  setOpenTab((currentStep) => currentStep - 1);
                } else {
                  navigate("/");
                }
              }}
              disabled={loading}
            >
              {openTab === 1 || openTab === 5 ? "Close" : "Back"}
            </button>
            {openTab <= 4 && (
              <button
                className={`bg-emerald-700 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 ${
                  loading ||
                  errors.values ||
                  (openTab === 1 && !isPersonalDetailsFilled) ||
                  (openTab === 2 && !isBusinessDetailsFilled) ||
                  (openTab === 3 && !isNextOfKinDetailsFilled)
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-emerald-700"
                }`}
                type="button"
                onClick={() => {
                  if (openTab === 4) {
                    handleFormSubmit();
                  } else {
                    if (
                      businessAssociations?.length <= 1 ||
                      allBranches?.length <= 1
                    )
                      if (openTab === 1 && !isPersonalDetailsFilled)
                        // refetchData();
                        return; // Prevent incrementing if personal details are not filled
                    if (openTab === 2 && !isBusinessDetailsFilled) return; // Prevent incrementing if business details are not filled
                    if (openTab === 3 && !isNextOfKinDetailsFilled) return; // Prevent incrementing if next of kin details are not filled
                    setOpenTab((openTab) => openTab + 1);
                  }
                }}
                disabled={
                  loading ||
                  (openTab === 2 && !isPersonalDetailsFilled) ||
                  (openTab === 3 && !isBusinessDetailsFilled) ||
                  (openTab === 4 && !isNextOfKinDetailsFilled) ||
                  (openTab === 5 && !isFormAllField)
                }
              >
                {openTab === 4 ? "Submit" : "Next"}
              </button>
            )}
          </div>
        )}
      </section>
      {/* spinner to show if loading */}
      <div
        className={`${
          loading ? "block" : "hidden"
        } fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center`}
      >
        <div className="animate-spin rounded-full h-11 w-11 border-t-2 border-b-4 border-gray-900"></div>
      </div>
      {/* <TermsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onAccept={(isAccepted) => setIsTermsAccepted(isAccepted)}
        isTermsAccepted={isTermsAccepted}
      /> */}
    </div>
  );
}
