import React from "react";
import ItemsContainer from "./ItemsContainer";
import SocialIcons from "./SocialIcons";
import { Icons } from "./Menus";

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white mb-4">
      <ItemsContainer />
      <div className="md:flex md:justify-between md:items-center sm:px-12 py-7">
        {/* <h1
          className="lg:text-4xl text-3xl md:mb-0 mb-6 lg:leading-normal font-semibold
         md:w-2/5"
        >
          <span className="text-teal-400">Free</span> until you're ready to
          launch
        </h1> */}
        <div className="relative">
          <input
            type="text"
            placeholder="Input your email"
            className="text-gray-800 sm:w-72 w-full sm:mr-5 mr-1 lg:mb-0 mb-4 py-2.5 rounded-lg px-2 focus:outline-none"
          />
          <button className="bg-green-700 hover:bg-teal-500 duration-300 px-2 py-2.5 font-[Poppins] rounded-lg text-white md:w-auto w-full absolute right-0 top-0 bottom-0 m-auto">
            Try for free
          </button>
        </div>
      </div>
      <div
        className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 gap-10
      text-start mx-8 pt-2 text-white text-sm pb-8 w-screen justify-between"
      >
        <span className="">
          Copyright © 2024 MATAN - Market Traders Association of Nigeria. All
          rights reserved.
        </span>
        <SocialIcons />
      </div>
    </footer>
  );
};

export default Footer;
