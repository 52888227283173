import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import {
  decryptData,
  encryptData,
  decryptDatas,
} from "../reusables/crypto/AdminCrypto";
import axios from "axios";
import { toast } from "react-toastify";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))
      : null
  );
  let [user, setUser] = useState(() =>
    localStorage.getItem("userDetails")
      ? jwt_decode(localStorage.getItem("userDetails"))
      : null
  );
  let [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // let changePassword = async (data_) => {
  //   const payload = encryptData(data_);
  //   let response = await fetch(
  //     `${process.env.REACT_APP_ADMIN_BASEURL}/api/Authentication/changePassword`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     }
  //   );
  //   let result = response ? await response?.json() : null;
  //   if (result) return decryptData(result);
  // };

  let changePassword = async (data_) => {
    try {
      const payload = encryptData(data_);
      const response = await fetch(
        `${process.env.REACT_APP_ADMIN_BASEURL}/api/Authentication/changePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.status === 401) {
        toast("Unauthorized", {
          type: "error",
        });
        return;
      }

      if (!response.ok) {
        const errorMessage = await response.text(); // Get the error message

        toast("An error occurred during the request.", {
          type: "error",
        });
        return;
      }

      const result = response ? await response.json() : null;

      // Decrypt the data before returning
      return result ? decryptData(result) : null;
    } catch (error) {
      console.error("Error changing password:", error.message);
      throw error; // Re-throw the error so it can be caught elsewhere if needed
    }
  };

  const loginUser = async (data_) => {
    try {
      const payload = encryptData(data_);
      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_BASEURL}/api/Authentication/login`,
        payload
      );

      const data = decryptData(response.data);
      const email = btoa(data_.email);
      if (data && data.Code === "00") {
        navigate(`/${email}/verify-otp`);
      }
      return { success: true, data: response.data };
    } catch (error) {
      const result = JSON.parse(JSON.stringify(error.response.data));
      const data = decryptDatas(result);
      if (data == "You already have an active session") {
        logOut(data_.email);
      }
      return { success: false, error: data };
    }
  };
  const verifyLoginOTP = async (data_) => {
    try {
      const payload = encryptData(data_);
      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_BASEURL}/api/Authentication/verifyLogin`,
        payload
      );

      if (response.status === 200) {
        const result = response.data;
        const decryptedData = decryptDatas(result);
        let variableDes = JSON.parse(decryptedData);

        if (decryptedData && variableDes.Token) {
          setAuthTokens(variableDes);
          const user = jwt_decode(variableDes.Token);
          setUser(user);
          localStorage.setItem("userDetails", JSON.stringify(variableDes));
          if (variableDes.Role.toLowerCase().includes("admin")) {
            navigate("/dashboardAdmin");
          } else if (variableDes.Role === "Agent") {
            navigate("/dashboardAgent");
          }
        }
      }
    } catch (error) {
      console.log(error, "error");
      if (error.response && error.response.data) {
        const result = error.response.data;
        const data = decryptDatas(result);
        return { success: false, error: data };
      } else {
        return { success: false, error: "An unexpected error occurred." };
      }
    }
  };

  // const verifyLoginOTP = async (data_) => {
  //   try {
  //     const payload = encryptData(data_);
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_ADMIN_BASEURL}/api/Authentication/verifyLogin`,
  //       payload
  //     );

  //     console.log(response, "auth");

  //     if (response.status == 200) {
  //       const result = response.data;
  //       const decryptedData = JSON.parse(JSON.stringify(decryptDatas(result)));

  //       console.log(decryptedData, "decrypted data");
  //       console.log(decryptedData.Token, "decrypted data");

  //       if (decryptedData.Token != null) {
  //         console.log(decryptedData, "data");
  //         setAuthTokens(decryptedData.Token);
  //         const user = jwt_decode(decryptedData.Token);
  //         setUser(user);
  //         localStorage.setItem("userDetails", JSON.stringify(decryptedData));
  //         console.log(1111);
  //         if (decryptedData.Role == "Admin") {
  //           console.log(2222);
  //           navigate("/dashboardAdmin");
  //         } else if (decryptedData.Role == "Agent") {
  //           console.log(3333);
  //           navigate("/dashboardAgent");
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error, "error");
  //     if (error.response && error.response.data) {
  //       const result = error.response.data;
  //       const data = decryptDatas(result);
  //       return { success: false, error: data };
  //     } else {
  //       return { success: false, error: "An unexpected error occurred." };
  //     }
  //   }
  // };
  const logOut = async (email) => {
    try {
      const userEmail =
        email ||
        user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
      const payload = encryptData(userEmail);

      const response = await axios.post(
        `${process.env.REACT_APP_ADMIN_BASEURL}/api/Authentication/logout`,
        payload
      );

      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("userDetails");
      navigate("/");
    } catch (error) {
      setAuthTokens(null);
      setUser(null);
      localStorage.removeItem("userDetails");
      navigate("/");
    }
  };

  let contextData = {
    user: user,
    authTokens: authTokens,
    setAuthTokens: setAuthTokens,
    setUser: setUser,
    loginUser: loginUser,
    changePassword: changePassword,
    logOut: logOut,
    verifyLoginOTP: verifyLoginOTP,
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.Token));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};
