import React from "react";
import { IoClose } from "react-icons/io5";
import AgentAuth from "../../context/AgentContext/agentAuth";
import AgentSearch from "../../reusables/AgentsReuseables/AgentSearch";
import Table from "../../reusables/AgentsReuseables/agentDetailTable";
import Card from "../../reusables/AgentsReuseables/Card";
import FilterDropdown from "../../reusables/AgentsReuseables/Agentfilter";

const options = [
  { id: 1, label: "Cash" },
  { id: 2, label: "Matan Card" },
  { id: 3, label: "Bank Transfer" },
  { id: 4, label: "QR CODE" },
  { id: 5, label: "Traders" },
  { id: 6, label: "Haulers" },
];

export default function MemberDetailsModal({ currentMember }) {
  const taxCollection = [];
  const {
    agentState,
    setMemberDetailModal,
    search,
    findMember,
    showSpinner,
    setShowSpinner,
    filter,
  } = AgentAuth();

  const filteredData = agentState?.filter;

  const removedClickedFilter = (item) => {
    const filterItem = filteredData.filter((filterItem) => filterItem !== item);
    filter(filterItem);
  };

  return (
    <div>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none no-scrollbar"
        style={{
          width: "100%",
        }}
      >
        <div className="relative  my-auto mx-auto" style={{ width: "80%" }}>
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-sm font-semibold">{"Tax Transactions"}</h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setMemberDetailModal(false)}
              >
                <span className="bg-transparent text-green-900 opacity-100 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  <IoClose className="text-2xl" />
                </span>
              </button>
            </div>

            {/*body*/}
            <div className="flex flex-wrap">
              <div className="w-full">
                <div className="w-full mx-4">
                  <h3 className="text-xl font-semibold">
                    {currentMember.name || "No Name"}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {currentMember.matanId || "Not Available"}
                  </p>
                </div>
                <div className="flex flex-row w-ful bg-white mx-4 my-0 justify-between ">
                  <AgentSearch placeholder="Search for trades" />
                  <div className="flex flex-row mr-1 justify-end">
                    {filteredData &&
                      filteredData.map((item, index) => (
                        <div key={index}>
                          <Card
                            textTitle={item}
                            onClick={removedClickedFilter}
                          />
                        </div>
                      ))}
                    <FilterDropdown
                      title="Filter"
                      className="bg-green-200"
                      options={options}
                      location="userDetails"
                    />
                  </div>
                </div>
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-2  rounded">
                  <div className="px-4 py-5 flex-auto">
                    <div className="tab-content tab-space">
                      <div className={1 === 1 ? "block" : "hidden"} id="link1">
                        <Table data={taxCollection} title="taxCollection" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*footer*/}

            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
              <button
                className="text-white bg-green-700 font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-solid border-slate-100 hover:bg-slate-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  setMemberDetailModal(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}
