import React, { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { CiWavePulse1 } from "react-icons/ci";
import { GoDotFill } from "react-icons/go";
import { LuEye } from "react-icons/lu";

import formatDate from "../dateConverter";
import AgentAuth from "../../context/AgentContext/agentAuth";

function Table({ data, itemsPerPage = 10, title = "members" }) {
  const { agentState } = AgentAuth();

  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const filteredData = filterAndSearchData(data, title);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedData = filteredData.slice(startIndex, endIndex);

  const pageCount = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  const handleCheckboxChange = (matanId) => {
    const updatedSelectedRows = [...selectedRows];

    if (updatedSelectedRows.includes(matanId)) {
      updatedSelectedRows.splice(updatedSelectedRows.indexOf(matanId), 1);
    } else {
      updatedSelectedRows.push(matanId);
    }

    setSelectedRows(updatedSelectedRows);
  };

  const handleSubmission = () => {
    // Handle the submission of selected rows
  };

  function filterAndSearchData(data, title) {
    const searchedItems = agentState?.search?.toLowerCase();
    const filteredItems = agentState?.filter;

    return data.filter((item) => {
      let matchesFilter;

      if (filteredItems?.length > 0 && title === "members") {
        const includesStatus = filteredItems.some((filterItem) =>
          ["active", "inactive"].includes(filterItem)
        );

        const includesCategory = filteredItems.some((filterItem) =>
          ["haulage", "traders"].includes(filterItem)
        );

        if (includesStatus && includesCategory) {
          matchesFilter =
            filteredItems.includes(item.status.toLowerCase()) &&
            filteredItems.includes(item.category.toLowerCase());
        } else if (includesStatus) {
          matchesFilter = filteredItems.includes(item.status.toLowerCase());
        } else if (includesCategory) {
          matchesFilter = filteredItems.includes(item.category.toLowerCase());
        } else {
          matchesFilter = true;
        }
      } else if (filteredItems?.length > 0 && title === "taxCollection") {
        const includesStatus = filteredItems.some((filterItem) =>
          ["pending", "failed", "successful"].includes(filterItem)
        );

        const includesPaymentMethod = filteredItems.some((filterItem) =>
          [
            "cash",
            "matan card",
            "bank transfer",
            "qr code",
            "traders",
            "haulers",
          ].includes(filterItem)
        );

        if (includesStatus && includesPaymentMethod) {
          matchesFilter =
            filteredItems.includes(item.status.toLowerCase()) &&
            filteredItems.includes(item.paymentMethode.toLowerCase());
        } else if (includesStatus) {
          matchesFilter = filteredItems.includes(item.status.toLowerCase());
        } else if (includesPaymentMethod) {
          matchesFilter = filteredItems.includes(
            item.paymentMethode.toLowerCase()
          );
        }
      } else {
        matchesFilter = true;
      }

      const matchesSearch =
        !searchedItems ||
        item?.matanId?.toLowerCase().includes(searchedItems) ||
        item?.TaxID?.toLowerCase().includes(searchedItems) ||
        item?.email?.toLowerCase().includes(searchedItems) ||
        item?.amount?.toLowerCase().includes(searchedItems) ||
        item?.phone?.toLowerCase().includes(searchedItems) ||
        item?.VAT?.toLowerCase().includes(searchedItems) ||
        item?.name?.toLowerCase().includes(searchedItems) ||
        item?.createdAt?.toLowerCase().includes(searchedItems);

      return matchesSearch && matchesFilter;
    });
  }
  return (
    <div className="overflow-y-auto h-80vh no-scrollbar w-full ">
      <table className="min-w-full bg-white border border-gray-300 text-sm text-left">
        <thead className="text-xs text-gray-700  bg-gray-50">
          <tr className="bg-gray-100 h-12">
            <th className="py-2 px-4 border-b"></th>

            <th className="py-2 px-4 border-b whitespace-nowrap">Tax ID</th>

            <th className="py-2 px-4 border-b whitespace-nowrap">
              Date & Time
            </th>
            <th className="py-2 px-4 border-b whitespace-nowrap">Amount</th>
            <th className="py-2 px-4 border-b whitespace-nowrap">VAT</th>
            <th className="py-2 px-4 border-b whitespace-nowrap">
              Payment Method
            </th>
            <th className="py-2 px-4 border-b whitespace-nowrap">Status</th>
          </tr>
        </thead>
        <tbody className="group">
          {displayedData.map((item, index) => (
            <tr key={index} className="border-b hover:bg-gray-200 bg-white ">
              <td className="py-2 px-4 border-b">
                <input
                  type="checkbox"
                  onChange={() => handleCheckboxChange(item.matanId)}
                  checked={selectedRows.includes(item.matanId)}
                />
              </td>

              <td className="p-2 font-small text-gray-900 whitespace-nowrap">
                <p className="text-[12px] mb-3 text-left font-semibold">
                  {item.TaxID}
                </p>
              </td>
              {/* Add other columns as needed */}
              <td className="p-2 font-small text-gray-900 whitespace-nowrap">
                {formatDate(item.createdAt)}
              </td>
              <td className="p-2 font-small text-gray-900 whitespace-nowrap">
                {item.amount}
              </td>
              <td className="p-2 font-small text-gray-900 whitespace-nowrap">
                {item.VAT}
              </td>

              <td className="p-2 font-small text-gray-900 whitespace-nowrap">
                {item.paymentMethode}
              </td>
              <td className="p-2 font-small text-gray-900 whitespace-nowrap">
                <div className="flex flex-row items-center border-1 rounded-lg p-1 justify-start">
                  <span
                    className={`flex items-center rounded-lg p-0 ${
                      item.status === "Failed"
                        ? "text-red-500 bg-red-100"
                        : item.status === "Pending"
                        ? "text-yellow-500 bg-yellow-100"
                        : "text-green-600 bg-green-100"
                    }`}
                  >
                    <GoDotFill
                      className={`text-[20px] mr-1 ${
                        item.status === "Failed"
                          ? "text-red-500"
                          : item.status === "Pending"
                          ? "text-yellow-500"
                          : "text-green-600"
                      }`}
                    />
                    {item.status}
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-4 flex items-center justify-between whitespace-nowrap">
        <div className="flex">
          <span>Page</span>
          <Pagination pageCount={pageCount} onPageChange={handlePageChange} />
        </div>

        <div className="flex space-x-4 mr-6">
          <button
            className="bg-white-500 text-black px-4 py-2 rounded border hover:bg-green-600 hover:text-white"
            onClick={() => {
              if (currentPage > 1) setCurrentPage(currentPage - 1);
            }}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <button
            className="bg-white-500 text-black px-4 py-2 rounded border hover:bg-green-600 hover-text-white"
            onClick={() => {
              if (currentPage < pageCount) setCurrentPage(currentPage + 1);
            }}
            disabled={currentPage === pageCount}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Table;

const Pagination = ({ pageCount, onPageChange, currentPage }) => {
  return (
    <ul className="pagination">
      {Array.from({ length: pageCount }, (_, index) => (
        <li key={index} className="inline-block mx-1/2">
          <button
            className={`${
              currentPage === index + 1
                ? "bg-green-500 text-white underline"
                : "bg-white-500 text-black"
            } px-3 py-1 rounded`}
            onClick={() => onPageChange(index + 1)}
          >
            {index + 1}
          </button>
        </li>
      ))}
    </ul>
  );
};
