import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";

import AgentAuth from "../../context/AgentContext/agentAuth";
import AdminAuth from "../../context/AdminContext/adminAuth";

function AgentSearch({
  placeholder = "Search by Matan ID, Phone Number & Name",
  width = "40%",
  showIcon = true,
  location = "members",
}) {
  const {
    agentState,
    searchMember,
    searchTaxCollection,
    setShowSpinner,
    setAllMembers,
    setAllTaskCOllections,
    setTotalMembers,
  } = AgentAuth();
  const {
    adminState,
    searchMember: searchMemberAdmin,
    searchTaxCollection: searchTaxCollectionAdmin,
    setShowSpinner: setShowSpinnerAdmin,
    setAllMembers: setAllMembersAdmin,
    setAllTaskCOllections: setAllTaskCOllectionsAdmin,
    setTotalMembers: setTotalMembersAdmin,
    setAllAgents,
    searchAgent,
    setTotalAgents,
  } = AdminAuth();
  const [searchedInput, setSearchedInput] = useState("");

  const handleChange = (e) => {
    setSearchedInput(e.target.value, searchedInput);
  };
  const searchFunction = async (e) => {
    e.preventDefault();
    if (searchedInput.length <= 1) {
      return;
    }
    let response;
    try {
      switch (location) {
        case "members":
          setShowSpinner(true);
          setAllMembers([]);

          response = await searchMember(searchedInput);
          setAllMembers([...JSON.parse(response.Data)]);
          setShowSpinner(false);
          break;
        case "taxCollection":
          setShowSpinner(true);
          setAllTaskCOllections([]);
          response = await searchTaxCollection(searchedInput);
          setAllTaskCOllections([...JSON.parse(response.Data)]);
          setShowSpinner(false);
          break;
        case "adminSearchMember":
          setShowSpinnerAdmin(true);
          response = await searchMemberAdmin(
            `pageIndex=1&pageSize=100&searchValue=${searchedInput}`
          );
          setAllMembersAdmin([...response?.Data?.Items]);
          setTotalMembersAdmin(response?.Data?.TotalCount);
          setShowSpinnerAdmin(false);

          break;
        case "adminSearchAgent":
          setShowSpinnerAdmin(true);
          setAllAgents([]);

          response = await searchAgent(
            `pageIndex=1&pageSize=100&name=${searchedInput}`
          );
          setAllAgents([...response?.Data?.Items]);
          setTotalAgents(response?.Data?.TotalCount);
          setShowSpinnerAdmin(false);

          break;

        default:
          return;
      }
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      setShowSpinner(false);
    }
  };

  return (
    <form
      className="flex items-center border-2 border-slate rounded-lg h-8 mt-6 "
      style={{
        width: width,
      }}
      onSubmit={searchFunction}
    >
      {showIcon && (
        <CiSearch className="text-[18px] text-gray-500 ml-2 mr-1 text-green-700" />
      )}
      <input
        className="flex-grow h-full pl-4 text-xs text-gray-500 bg-transparent outline-none"
        type="text"
        placeholder={placeholder}
        name="search"
        value={searchedInput}
        onChange={handleChange}
      />
    </form>
  );
}

export default AgentSearch;
