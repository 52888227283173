import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { GoGear } from "react-icons/go";
import { SlPeople } from "react-icons/sl";
import { PiBriefcaseThin } from "react-icons/pi";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { MdOutlinePersonPin } from "react-icons/md";
import { LuLogOut } from "react-icons/lu";

import { imageObj } from "../../icon";
import AuthContext from "../../context/AuthContext";

const SideNav = ({ currentLink = "dashboard" }) => {
  let { user, logOut, setUser } = useContext(AuthContext);

  const handleLogout = () => {
    localStorage.removeItem("authTokens");
    localStorage.removeItem("userDetails");
    logOut();
    setUser(null);
  };

  const userEmail = user
    ? user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]
    : "";
  const [activeLink, setActiveLink] = useState(currentLink);

  const userRole = "Agent";

  const navigationLinks = [
    {
      to: userRole === "Admin" ? "/dashboardAdmin" : "/dashboardAgent",
      label: "Dashboard",
      icon: <MdOutlineSpaceDashboard className="text-sm mr-2" />,
      name: "dashboard",
    },
    {
      to: userRole === "Admin" ? "/taxAdmin" : "/collectionAgent",
      label: "Tax Collection",
      icon: <PiBriefcaseThin className="text-xl mr-2" />,
      name: "collection",
    },
    {
      to: userRole === "Admin" ? "/membersAdmin" : "/membersAgent",
      label: "Members",
      icon: <SlPeople className="text-xl mr-2" />,
      name: "members",
    },
    ...(userRole === "Admin"
      ? [
          {
            to: "/agent",
            label: "Agent",
            icon: <MdOutlinePersonPin className="text-xl mr-2" />,
            name: "agent",
          },
        ]
      : []),
  ];

  return (
    <div className="h-full bg-white-800 text-black p-4 border overflow-x-hidden border-r-2 no-scrollbar flex flex-col">
      <div className="flex flex-col items-start">
        {userRole === "Admin" && (
          <div className="flex mb-4">
            <img src={imageObj.Sterlingbrand} className="w-7 h-7" alt="" />
            <h1 className="text-sm text-gray-600 font-semibold mt-[0px] ml-2">
              MATAN
            </h1>
          </div>
        )}
        <div className="flex mb-4">
          <h1 className="text-sm text-gray-600 font-semibold mt-[0px] ml-2">
            {userRole === "Admin" ? "MATAN" : "BBS"}
          </h1>
        </div>
        <div className="flex flex-col items-start p-2 w-full">
          {navigationLinks.map(({ to, label, icon, name }) => (
            <Link
              key={name}
              to={to}
              className={`flex items-center text-sm mt-4 w-[180px] py-1 ${
                activeLink === name
                  ? "text-green-700 font-bold bg-green-100 rounded-sm"
                  : ""
              }`}
            >
              {icon}
              {label}
            </Link>
          ))}
        </div>
        {/* <div className="fixed bottom-14 left-0 w-4/5 p-4 mx-3"> */}
        <div className="flex flex-col items-start mt-[540px]">
          <Link
            to="/profileSetting"
            className={`flex items-center font-semibold text-sm w-[180px] py-1  ${
              activeLink === "settingAgent"
                ? "text-green-700 font-bold bg-green-100 rounded-sm"
                : ""
            }`}
          >
            <GoGear
              className={`text-xl mr-4 ${
                activeLink === "settingAgent"
                  ? "text-green-700 text-2xl bg-green-100"
                  : ""
              }`}
            />
            Settings
          </Link>
          <hr className="mt-6 mb-1 border-t border-gray-200 w-[200px]" />
          <div className="flex mt-3 items-center">
            <img src={imageObj.Avatar} alt="" className="w-9 h-9" />
            <div className="text-[12px] ml-3">
              <p className="text-gray-900 font-semibold">
                {userEmail.split("@")[0]}
              </p>{" "}
              <p className="text-gray-600">{userEmail}</p>
              <button onClick={handleLogout} className="ml-auto">
                <LuLogOut className="hover:text-green-700 transition ease-in-out duration-500 text-gray-600 w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
