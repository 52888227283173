import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import FilterDropdown from "./Agentfilter";
import LoadingSpinner from "../spinner";
import Card from "./Card";
import formatDate from "../dateConverter";
import EditAssociationDetails from "../shared/modal/AssociationModal";
import SearchInput from "../shared/input-fields/SearchInput";
import adminAuth from "../../context/AdminContext/adminAuth";

const filterOptions = [
  { Id: 3, label: "Active" },
  { Id: 4, label: "Inactive" },
];

const Statuses = ["Active", "inActive"];

const headCells = [
  { id: "Name", disablePadding: true, label: "Association Name" },
  { id: "Action", disablePadding: false, label: "Action" },
  { id: "CreatedBy", disablePadding: false, label: "Created By" },
  { id: "CreatedAt", disablePadding: false, label: "Date Created" },
  { id: "Status", disablePadding: false, label: "Status" },
];

const EnhancedTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) => {
  const createSortHandler = (property) => (event) =>
    onRequestSort(event, property);

  return (
    <TableHead>
      <TableRow className="bg-gray-100">
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: "auto" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({ numSelected, handleSearch }) => {
  const [filteredData, setFilteredData] = useState([]);

  const handleSelectionChange = (selectedItems) => {
    setFilteredData(selectedItems);
  };

  const removedClickedFilter = (item) => {
    setFilteredData(filteredData.filter((filterItem) => filterItem !== item));
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
      className="mb-4"
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <SearchInput
          placeholder="Search for Association"
          onSearch={handleSearch}
          debounceTime={5000}
        />
      )}
      <div
        className="flex flex-row mr-1 justify-end"
        style={{ width: "70%", height: "100%" }}
      >
        {filteredData.map((item, index) => (
          <Card
            key={index}
            textTitle={item}
            onClick={() => removedClickedFilter(item)}
          />
        ))}
        <FilterDropdown
          title="Filter"
          className="bg-green-200"
          options={filterOptions}
          onSelectionChange={handleSelectionChange}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

const EnhancedTable = ({ handleTableValue }) => {
  const [order, setOrder] = useState("asc");
  const [action, setAction] = useState("Edit");
  const [orderBy, setOrderBy] = useState("DateCreated");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState(" ");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [allAssociations, setAllAssociations] = useState([]);
  const [totalAssociations, setTotalAssociations] = useState(0);
  const [loading, setLoading] = useState(false);

  const { listAssociations } = adminAuth();

  const closeModal = () => {
    setShowDetails(false);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const payload = {
      page: newPage,
      rowsPerPage,
    };
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    const payload = {
      page: 0,
      rowsPerPage: +event.target.value,
    };
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleEditClick = (row) => {
    setShowDetails(true);
    setAction("Edit");
    setSelectedRow(row);
  };

  const handleDeleteClick = (row) => {
    setShowDetails(true);
    setAction("Delete");
    setSelectedRow(row);
  };

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["associations"],
    queryFn: async () => {
      setLoading(true);

      const response = await listAssociations(
        `pageIndex=${page + 1}&pageSize=${rowsPerPage}&name=${searchText}`
      );

      setAllAssociations(response.Data?.Items);
      setTotalAssociations(response?.Data?.TotalCount);
      handleTableValue(response?.Data?.TotalCount);
      setLoading(false);

      return response;
    },
    staleTime: 10000,
    cacheTime: 0,
  });

  const handleSearch = (query) => {
    setSearchText(query);
  };

  useEffect(() => {
    refetch();
  }, [searchText, page, rowsPerPage, refetch]);
  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleSearch={handleSearch}
        />
        <TableContainer className="min-h-[90vh] max-h-[98vh] overflow-y-auto no-scrollbar">
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={(event) =>
                setSelected(
                  event.target.checked ? allAssociations.map((n) => n.Id) : []
                )
              }
              onRequestSort={(event, property) => {
                const isAsc = orderBy === property && order === "asc";
                setOrder(isAsc ? "desc" : "asc");
                setOrderBy(property);
              }}
              rowCount={allAssociations?.length || 0}
            />
            {isLoading || loading ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <LoadingSpinner />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {allAssociations.map((row, index) => {
                  const isItemSelected = isSelected(row.Id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.Id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, row.Id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        sx={{ textTransform: "capitalize", width: "20%" }}
                      >
                        {row.Name || "---"}
                      </TableCell>
                      <TableCell align="left">
                        <div className="flex items-center">
                          <button
                            onClick={() => handleEditClick(row)}
                            className="flex flex-row p-2 rounded-lg"
                          >
                            <FiEdit2 className="mt-1 mr-2" />
                          </button>
                          <button
                            onClick={() => handleDeleteClick(row)}
                            className="flex flex-row p-2 rounded-lg text-red-700"
                          >
                            <FiTrash className="mt-1 mr-2" />
                          </button>
                        </div>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.CreatedBy || "---"}
                      </TableCell>
                      <TableCell align="left">
                        {(row?.Created && formatDate(row?.Created)) || "---"}
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ textTransform: "capitalize" }}
                      >
                        <div
                          className={`flex items-center ${
                            row.Status === "active"
                              ? "text-green-700 text-md"
                              : "text-red-600 text-md"
                          }`}
                        >
                          <GoDotFill />
                          {row.Status?.toLowerCase()}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 75, 100]}
          component="div"
          count={totalAssociations}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {showDetails && (
        <EditAssociationDetails
          open={showDetails}
          onClose={closeModal}
          item={selectedRow}
          action={action}
        />
      )}
    </Box>
  );
};

EnhancedTable.propTypes = {
  handleTableValue: PropTypes.number.isRequired,
};

export default EnhancedTable;
