import React, { useState } from "react";
import { GiCheckMark } from "react-icons/gi";
import { FaRegDotCircle } from "react-icons/fa";

const MultiForm = ({ openTab }) => {
  return (
    <div className="flex flex-col items-left justify-between mt-4 mx-0 mb-0 w-56">
      <div>
        <h1 className="text-2xl font-bold text-white mb-4 text-center ">
          MATAN
        </h1>
      </div>
      <div className="parent flex flex-row my-4 parent-container">
        <div className=" flex flex-col items-center ml-3 hidden lg:block">
          <div className="child flex flex-col items-center ">
            <div
              className={`border-4 rounded-full text-green-900 p-0 inline-flex border-white shadow-l ${
                openTab > 0 ? "bg-green-700" : ""
              }`}
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="100"
            >
              {openTab >= 0 ? (
                <GiCheckMark className="text-xs text-green-700 bg-white" />
              ) : (
                <FaRegDotCircle className="bg-black text-black" />
              )}
            </div>

            <div
              className={`${
                openTab > 0 ? "border-white" : "border-slate-400"
              } border-2 h-10 w-0.5 border`}
            ></div>
          </div>

          <div className="child flex flex-col items-center">
            <div
              className={`border-4 rounded-full text-white p-0 inline-flex border-white ${
                openTab > 1 ? "bg-green-700" : ""
              }`}
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="300"
            >
              {openTab >= 1 ? (
                <GiCheckMark className="text-xs text-green-700 bg-white" />
              ) : (
                <FaRegDotCircle className="text-black" />
              )}
            </div>
            <div
              className={`${
                openTab > 1 ? "border-white" : "border-slate-400"
              } border-2 h-10 w-0.5 border`}
            ></div>
          </div>

          <div className="child flex flex-col items-center">
            <div
              className={`border-4 rounded-full text-white p-0 inline-flex border-white ${
                openTab > 2 ? "bg-green-700" : ""
              }`}
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="300"
            >
              {openTab >= 2 ? (
                <GiCheckMark className="text-xs text-green-700 bg-white" />
              ) : (
                <FaRegDotCircle className="text-black" />
              )}
            </div>
            <div
              className={`${
                openTab > 2 ? "border-white" : "border-slate-400"
              } border-2 h-10 w-0.5 border`}
            ></div>
          </div>

          <div className="child flex flex-col items-center">
            <div
              className={`border-4 rounded-full text-white p-0 inline-flex border-white ${
                openTab > 3 ? "bg-green-700" : ""
              }`}
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="500"
            >
              {openTab >= 3 ? (
                <GiCheckMark className="text-xs text-green-700 bg-white" />
              ) : (
                <FaRegDotCircle className="text-black" />
              )}
            </div>
            <div
              className={`${
                openTab > 3 ? "border-white" : "border-slate-400"
              } border-2 h-10 w-0.5 border`}
            ></div>
          </div>

          <div className="child flex flex-col items-center">
            <div
              className={`border-4 rounded-full text-white p-0 inline-flex border-white ${
                openTab > 4 ? "bg-green-700" : ""
              }`}
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="700"
            >
              {openTab > 3 ? (
                <GiCheckMark className="text-xs text-green-700 bg-white" />
              ) : (
                <FaRegDotCircle className="text-black" />
              )}
            </div>
          </div>
        </div>
        <div className="parent flex flex-col items-start w-auto   ">
          <div
            className={`child text-white px-2 flex flex-col border-white items-center justify-center ${
              openTab == 0 ? "text-2xl font-bold" : "text-white "
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold  text-left ">
              BVN Verification
            </span>
            <span className="px-2 text-xs cursor-pointer "></span>
          </div>
          <div
            className={`child text-white p-2 mt-4 flex flex-col border-white items-center justify-center ${
              openTab === 1 ? "text-2xl font-bold" : "text-white "
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold  text-left ">
              Personal Details
            </span>
            <span className="px-2 text-xs cursor-pointer ">
              Provide Personal Details
            </span>
          </div>

          <div
            className={`child text-white p-2 mt-4 flex flex-col border-white items-center justify-center ${
              openTab === 2 ? "text-2xl font-bold" : "text-white"
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold ">
              Business/Other details
            </span>
            <span className="px-2 text-xs font-normal ">
              Provide Business Details
            </span>
          </div>

          <div
            className={`child p-2 mt-4 flex flex-col border-white items-center justify-center ${
              openTab === 3 ? "text-2xl font-bold" : "text-white"
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold ">Next of Kin</span>
            <span className="px-2 text-xs font-normal ">
              Provide Next of Kin Details
            </span>
          </div>

          <div
            className={` child p-2 mt-4 flex flex-col border-white items-center justify-center ${
              openTab === 4 ? "text-2xl font-bold" : "text-white"
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold">Review Details</span>
            <span className="px-2 text-xs font-normal">
              Confirm all provided Details
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiForm;
