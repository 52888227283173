import * as React from "react";
import { useNavigate } from "react-router-dom";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { imageObj } from "../../icon";

export default function ImageAvatars() {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/dashboardAgent");
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      className="md:ml-8 md:my-0 my-7 cursor-pointer"
      onClick={handleClick}
    >
      <Avatar alt="Remy Sharp" src={imageObj.Avatar} />
    </Stack>
  );
}
