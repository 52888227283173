const MaskEmail = (email) => {
  const atIndex = email.indexOf("@");
  if (atIndex === -1) {
    return "*".repeat(email.length);
  } else {
    const parts = email.split("@");
    const firstPart = parts[0];
    const lastPart = parts[1];
    return (
      firstPart.charAt(0) + "***@***" + lastPart.charAt(lastPart.length - 1)
    );
  }
};

export default MaskEmail;
