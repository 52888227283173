import * as yup from "yup";
const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

const changePasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "At least 8 characters")
    .matches(passwordRules, {
      message:
        "min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.",
    })
    .required("Can't be empty"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Can't be empty"),
  otp: yup.string().required("Can't be empty"),
});

const loginSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Can't be empty"),
  password: yup
    .string()
    .min(8, "At least 8 characters")
    // .matches(passwordRules, {
    //    message:
    //       'min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.',
    // })
    .required("Can't be empty"),
});

const onboardAdminSchema = yup.object().shape({
  email: yup.string().email("Email is invalid").required("Can't be empty"),
  password: yup
    .string()
    .min(8, "At least 8 characters")
    // .matches(passwordRules, {
    //    message:
    //       'min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.',
    // })
    .required("Can't be empty"),
  phoneNumber: yup.string().required("Can't be empty"),
  role: yup.object().required("Can't be empty"),
});

const otpSchema = yup.object().shape({
  otp: yup
    .string()
    // .min(6, 'OTP should be 6 digits')
    .required("Can't be empty"),
});

const requestReasonSchema = yup.object().shape({
  requestReason: yup.string().required("Can't be empty"),
});

const newAssociationSchema = yup.object().shape({
  Name: yup.string().required("Association name can't be empty"),
  Description: yup.string().required("Association description can't be empty"),
});
const newAdminSchema = yup.object().shape({
  Email: yup
    .string()
    .required()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Email must follow the correct format"
    ),
  PhoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(/^\d{11}$/, "Phone Number must be exactly 11 digits"),
  FirstName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "First name can only contain letters")
    .required("Admin first name can't be empty"),
  LastName: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Last name can only contain letters")
    .required("Admin last name can't be empty"),
  status: yup.string().optional(),
});

const changeDefaultPasswordSchema = yup.object().shape({
  password: yup.string().required("Old password is required"),
  newPassword: yup
    .string()
    .required("New password is required")
    .min(8, "Password must be at least 8 characters long")
    .matches(
      /(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$#!%*?&]/,
      "Password must contain at least one letter, one number, and one special character"
    ),
});

const schemas = {
  changePasswordSchema,
  otpSchema,
  newAssociationSchema,
  requestReasonSchema,
  loginSchema,
  onboardAdminSchema,
  newAdminSchema,
  changeDefaultPasswordSchema,
};

export default schemas;
