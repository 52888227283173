import { useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";

export default function TextInput({
  label,
  labelColor = "text-black",
  name,
  type = "text",
  placeholder,
  errorMessage,
  fieldName,
  defaultValue,
  disabled,
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="relative my-2 w-full ">
      {label && (
        <label
          htmlFor={name}
          className={`text-sm mb-2 inline-flex ${
            errorMessage ? "text-red" : labelColor
          }`}
        >
          {label}
          {errorMessage === "" ? "" : "*"}
        </label>
      )}

      <div
        className={`flex items-center w-full input-wrapper rounded-lg ${
          errorMessage ? "border-2 border-red" : "border border-grey-5"
        }`}
      >
        {type === "email" && <MdOutlineMailOutline className="text-gray-500" />}

        <input
          type={isPasswordVisible && type === "password" ? "text" : type}
          min="0"
          step="any"
          placeholder={placeholder}
          id={name}
          name={name}
          defaultValue={defaultValue}
          disabled={disabled}
          {...fieldName}
          onBlur={fieldName.onBlur} // Trigger validation on blur
          className={`w-full py-[10px] pl-4 text-sm rounded-lg`}
        />
        {type === "password" && (
          <div className="absolute right-2">
            <button
              type="button"
              className="material-symbols-outlined text-[20px]"
              onClick={handleTogglePasswordVisibility}
            >
              {isPasswordVisible ? "visibility" : "visibility_off"}
            </button>
          </div>
        )}
      </div>
      <p className="text-red-500 my-1 text-xs">{errorMessage}</p>
    </div>
  );
}
