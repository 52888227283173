import React, { useState } from "react";

import AgentAuth from "../../context/AgentContext/agentAuth";
import RegistrationModal from "../modal/form";
import UserDetailModal from "../modal/userDetails";
import EnhancedTable from "../../reusables/AgentsReuseables/NewMemberCompleteTable";

import PageLayout from "../../reusables/shared/layout/PageLayout";

function Members() {
  const { memberDetailModal, currentMember, agentAction, totalMembers } =
    AgentAuth();
  const [selectedOption, setSelectedOption] = useState("allMembers");
  const [totalOnboardedMembers, setTotalOnboardedMembers] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleTableValue = (data) => {
    setTotalOnboardedMembers(data);
  };

  return (
    <PageLayout
      title="Members"
      subTitle="View/Create Members."
      buttonShown="create"
      button1Text="Export"
      button2Text="New Member"
      totalValue={totalMembers}
      setShowDetails={setModalOpen}
      noButton={false}
    >
      <section
        className="pl-3 pt-1 overflow-x-hidden md:mt-2"
        style={{
          width: "100%",
        }}
      >
        <div className="flex text-[12px] mt-6 justify-around w-3/6">
          <button
            onClick={() => handleOptionClick("")}
            className={`${
              selectedOption === "allMembers"
                ? "text-green-700 font-bold border-b-2 border-green-700 rounded-sm"
                : ""
            }`}
          >
            All Categories
          </button>
          <button
            onClick={() => handleOptionClick("Trader")}
            className={`${
              selectedOption === "Trader"
                ? "text-green-700 font-bold border-b-2 border-green-700 rounded-sm"
                : ""
            }`}
          >
            Traders
          </button>
          <button
            onClick={() => handleOptionClick("Haulage")}
            className={`${
              selectedOption === "Haulage"
                ? "text-green-700 font-bold border-b-2 border-green-700 rounded-sm"
                : ""
            }`}
          >
            Haulage
          </button>
        </div>

        <EnhancedTable
          handleTableValue={handleTableValue}
          totalValue={totalOnboardedMembers}
          selectedOption={selectedOption}
        />
      </section>
      {modalOpen && (
        <RegistrationModal
          action={agentAction}
          modalOpen={modalOpen}
          setModalOpen={() => setModalOpen(false)}
        />
      )}
      {memberDetailModal && <UserDetailModal currentMember={currentMember} />}
    </PageLayout>
  );
}

export default Members;
