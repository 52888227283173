import React, { useState, useContext } from "react";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import TextInput from "../shared/input-fields/TextInput";
import toastMessage from "../Toast";
import AuthContext from "../../context/AuthContext";
import schemas from "../Validation";

const LoginFormComponent = () => {
  const { loginSchema } = schemas;
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const { loginUser } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data) => {
    try {
      const response = await loginUser(data);
      if (!response.success) {
        const errorDescription = JSON.parse(response.error)?.Description;
        toastMessage(
          "error",
          errorDescription || "Network error. Please try again later."
        );
      }
    } catch (err) {
      console.error(err);
      toastMessage(
        "error",
        "An error occurred during login. Please try again."
      );
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <form
      className="flex flex-wrap w-full grid-cols-2 gap-4 mt-20 px-6"
      onSubmit={handleSubmit(onSubmit)}
    >
      <h1 className="text-3xl font-bold w-full text-center mb-6">
        Welcome back
      </h1>

      <p className="text-gray-700 w-full text-center mb-6">
        Please provide your details to login to the platform
      </p>

      <TextInput
        label="Username"
        name="email"
        fieldName={register("email")}
        placeholder="Enter Email"
        errorMessage={errors.email?.message}
      />

      <section className="pl-3 pr-6 w-full">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="password"
        >
          Password
        </label>
        <div className="relative">
          <input
            id="password"
            className={`border mb-2 py-2 px-3 rounded text-gray-700 w-full focus:bg-primary ${
              errors.password ? "border-red-500" : ""
            }`}
            name="password"
            type={showPassword ? "text" : "password"}
            placeholder="* * * * * * * *"
            {...register("password")}
          />
          <span
            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
          </span>
        </div>
        {errors.password && (
          <p className="text-red-500 text-xs italic">
            {errors.password.message}
          </p>
        )}
      </section>

      <section className="pl-3 pr-6 w-full">
        <button
          className="bg-green-700 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:border-none w-full"
          type="submit"
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <div className="animate-spin rounded-full h-11 w-11 border-t-2 border-b-4 border-gray-900"></div>
            </div>
          ) : (
            "Sign in"
          )}
        </button>
      </section>

      <section className="flex flex-row mt-8 px-14 w-full justify-around">
        <p className="text-black underline">Terms of use</p>
        <p className="text-black ml-8 underline">FAQ</p>
      </section>
    </form>
  );
};

export default LoginFormComponent;
