import React, { useEffect } from "react";
import DefaultPic from "../../reusables/DefaultPic";
import { useState } from "react";
import OTPInput from "../OTP/OTPInput";
import { useNavigate } from "react-router-dom";
// import '../App.css'

const AuthLogin = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const onchangeOtp = (value) => {
    setOtp(value);

    if (otp === "") {
      navigate("/auth");
    } else {
      setTimeout(() => {
        // navigate("/dashboardAdmin");
        navigate("/dashboardAdmin");
        localStorage.setItem("isAuth", JSON.stringify({ token: true }));
      }, 1000);
    }
  };

  return (
    <>
      <main className="md:grid md:grid-cols-2 md:items-center md:h-[100vh] md:ml-[120px]">
        <DefaultPic />

        <section>
          <div className=" text-center md:text-left mt-20 md:mt-14 md:mt-0 mb-7">
            <h1 className="font-bold text-[20px] mb-4 md:mb-3">OTP</h1>
            <p className="text-gray-500 md:block hidden text-[13px]">
              Please enter your OTP number to Verify your <br /> BVN
            </p>
          </div>

          <div>
            <OTPInput
              length={6}
              className="flex gap-5 ml-[110px] md:ml-0"
              inputClassName="w-8 h-9 outline-[#15803D] placeholder-gray-300 text-gray-600 font-semibold py-2 text-2xl text-center border border-gray-300 rounded-md focus:border border-gray-200 focus:ring-1 "
              isNumberInput
              autoFocus
              onChangeOTP={(otp) => setOtp(otp)}
            />
          </div>

          <div>
            <button
              onClick={onchangeOtp}
              className=" ml-[110px] md:ml-0  hover:bg-emerald-900 transition ease-in-out duration-500 mt-5 w-[294px] mr-52 text-[14px] h-[36px] rounded-lg bg-[#15803D] text-[#fff]"
            >
              Submit OTP
            </button>
          </div>

          <div className="flex pb-7 md:pb-0 justify-evenly mt-7  md:mr-[280px]  text-gray-500">
            <a className="underline ml-7 md:ml-0 underline-offset-2 hover:cursor-pointer text-[11px]">
              Terms of use
            </a>
            <a className="underline underline-offset-2 hover:cursor-pointer text-[11px]">
              FAQ
            </a>
          </div>
        </section>
      </main>
    </>
  );
};

export default AuthLogin;
