import React, { useState } from "react";
// import { handleBulkModal } from "../services/RxjsServices";
import { imageObj } from "../../icon";
import { IoCloseSharp } from "react-icons/io5";

const BulkModal = () => {
  const [isBulkModalOpen, setBulkModalOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [validateFile, setValidateFile] = useState("");
  const [fileData, setFileData] = useState(null);
  const [update, setUpdate] = useState("");
  const allowedFileTypes = ["csv", "xls", "xlsx", "pdf"];
  const [base64String, setBase64String] = useState();

  const closeBulkModal = () => {
    setBulkModalOpen(false);
    // handleBulkModal(false);
  };

  const sendFileUpload = () => {
    let sendFile = {
      base64String: base64String,
    };
    // handleBulkModal(false);
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.name;
      const getIndex = fileType.split(".");

      const reader = new FileReader();
      reader?.readAsDataURL(selectedFile);
      reader.onload = (_event) => {
        setBase64String(reader.result.split(",")[1]);
      };

      if (allowedFileTypes.includes(getIndex[1])) {
        const imageUrl = URL.createObjectURL(selectedFile);
        setFileData(imageUrl);
        setValidateFile("");

        const totalSteps = 100;
        let currentStep = 0;

        const interval = setInterval(() => {
          currentStep += 1;
          setProgress((currentStep * 100) / totalSteps);

          if (currentStep === totalSteps) {
            clearInterval(interval);
            setUpdate("File uploaded successfully!");
          }
        }, 50);
      } else {
        setValidateFile("Invalid file uploaded");
        setFileData(null);
      }
    }
  };

  const isDisplayPic = fileData !== null;

  return (
    <>
      <div className="fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm">
        <div className="bg-white bg-opacity-90 overflow-y-auto mt-3 w-3/4 h-[530px] max-w-5xl p-4 rounded-lg shadow-lg dark:bg-gray-700">
          <div className="relative">
            {/* Modal content */}
            <div className="bg-white rounded-lg px-2 py-1">
              {/* Your modal content goes here */}
              <div className="flex justify-between border-b shadow-sm pb-2 ">
                <p className="text-xl font-semibold">Bulk Add</p>
                <button onClick={closeBulkModal}>
                  <IoCloseSharp />
                </button>
              </div>

              <div>
                <p className="absolute mt-5 text-[8px] ml-4 md:ml-11 font-semibold md:text-xs">
                  Upload File
                </p>
              </div>

              <div className="shadow mt-4 py-5 md:py-6 mt-3">
                <div className="overflow-x-hidden overflow-y-hidden pb-12">
                  {/* <img src={showBlindToggle === false ? imageObj.Eyeoff : imageObj.Eyeon} alt="" className="w-5 absolute mt-6 ml-[238px]" onClick={togglePasswordEye}/> */}
                  <div class="ml-10 items-center md:h-[360px] w-full">
                    <label
                      for="dropzone-file"
                      class="flex flex-col items-center justify-center w-40 h-20 md:w-11/12 md:h-48 border-2 border-gray-100 rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div class="flex flex-col items-center justify-center pt-2 pb-3">
                        <svg
                          class="w-3 h-3 md:w-4 md:h-4 mb-1 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p class="mb-0.5 text-[8px] md:text-[10px] text-gray-500 dark:text-gray-400">
                          <span class="font-semibold">Click to upload</span> or
                          drag and drop
                        </p>
                        <p class="md:text-[10px] text-[8px] text-gray-500 dark:text-gray-400">
                          CSV, XLS (MAX. 800x400px)
                        </p>
                      </div>
                      <input
                        onChange={handleFileUpload}
                        id="dropzone-file"
                        type="file"
                        class="hidden"
                      />
                    </label>

                    <p className="text-[7px] md:text-[20px] ml-12 md:ml-[330px] md:mt-1 text-red-700">
                      {validateFile}
                    </p>
                  </div>

                  <section className="-mt-[100px] px-3">
                    {/* <button onClick={simulateUpload}>Upload</button> */}
                    {progress > 0 && (
                      <div>
                        <div className=" flex ml-2 border border-green-400 px-6 py-6 rounded-xl">
                          <progress
                            value={progress}
                            max="100"
                            className="w-[802px] mt-1"
                          />
                          <p className="ml-1 text-sm">{progress}%</p>
                        </div>

                        <div>
                          <p className="text-center mt-2 text-green-700">
                            {update}
                          </p>
                        </div>
                      </div>
                    )}
                  </section>
                </div>
              </div>
              <div className="mt-3  float-right mr-[17px] text-xs pb-2 md:text-sm text-center md:flex md:justify-end md:mr-3">
                <button
                  className="border bg-gray-100 mx-3 py-1 px-2 rounded"
                  onClick={closeBulkModal}
                >
                  Cancel
                </button>

                <button
                  onClick={sendFileUpload}
                  className="border text-white text-xs md:text-sm bg-gray-100 bg-green-600 rounded py-1 px-2"
                >
                  Import Users
                </button>
              </div>
            </div>

            <div className="progress"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkModal;
