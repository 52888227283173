import React from "react";
import { GiCheckMark } from "react-icons/gi";

const MultiForm = ({ openTab }) => {
  return (
    <div>
      <div className="hidden lg:flex flex flex-col lg:flex-row items-center   justify-between mt-4 mx-20 mb-0">
        <div className="flex flex-col lg:flex-row  items-center justify-center w-full">
          <div
            className={`border-4 rounded-full text-green-900 p-0 inline-flex border-green-700 shadow-l ${
              openTab > 1 ? "bg-green-700" : ""
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="100"
          >
            <GiCheckMark className="text-xs text-white" />
          </div>

          <div
            className="flex flex-col lg:flex-row items-center flex-grow"
            data-aos="zoom-out-right"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            <div
              className={`${
                openTab > 1 ? "border-green-700" : "border-slate-200"
              } border-2 lg:h-0.5 lg:w-full h-10 w-0.5 border`}
            ></div>

            <div
              className={`${
                openTab > 1 ? "border-green-700" : "border-slate-200"
              } border-2 lg:h-0.5 lg:w-full h-10 w-0.5 border`}
            ></div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row  items-center justify-center w-full">
          <div
            className={`border-4 rounded-full text-green-700 p-0 inline-flex border-green-700 ${
              openTab > 2 ? "bg-green-700" : ""
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <GiCheckMark className="text-xs text-white" />
          </div>
          <div
            className="flex flex-col lg:flex-row items-center flex-grow"
            data-aos="zoom-out-right"
            data-aos-duration="800"
            data-aos-delay="400"
          >
            <div
              className={`${
                openTab > 2 ? "border-green-700" : "border-slate-200"
              } border-2 lg:h-0.5 lg:w-full h-10 w-0.5 border`}
            ></div>

            <div
              className={`${
                openTab > 2 ? "border-green-700" : "border-slate-200"
              } border-2 lg:h-0.5 lg:w-full h-10 w-0.5 border`}
            ></div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row  items-center justify-center w-full">
          <div
            className={`border-4 rounded-full text-green-700 p-0 inline-flex border-green-700 ${
              openTab > 3 ? "bg-green-700" : ""
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="500"
          >
            <GiCheckMark className="text-xs text-white" />
          </div>
          <div
            className="flex flex-col lg:flex-row  items-center flex-grow"
            data-aos="zoom-out-right"
            data-aos-duration="800"
            data-aos-delay="600"
          >
            <div
              className={`${
                openTab > 3 ? "border-green-700" : "border-slate-200"
              } border-2 lg:h-0.5 lg:w-full h-10 w-0.5 border`}
            ></div>

            <div
              className={`border-4 rounded-full text-green-700 p-0 inline-flex border-green-700 ${
                openTab > 4 ? "bg-green-700" : ""
              }`}
              data-aos="zoom-in"
              data-aos-duration="800"
              data-aos-delay="700"
            >
              <GiCheckMark className="text-xs text-white" />
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:flex flex flex-col lg:flex-row items-center   justify-between mt-2  mb-0 w-full">
        <div className="flex flex-col lg:flex-row  items-center justify-center w-full">
          <div
            className={` text-green-700 p-2 flex flex-col border-green-700 items-center justify-center ${
              openTab === 1 ? "text-green-700" : "text-slate-400"
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold ">Personal Details</span>
            <span className="px-2 text-xs ">Provide Personal Details</span>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row  items-center justify-center w-full">
          <div
            className={` text-green-700 p-2 flex flex-col border-green-700 items-center justify-center ${
              openTab === 2 ? "text-green-700" : "text-slate-400"
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold ">
              Business/Other details
            </span>
            <span className="px-2 text-xs font-normal ">
              Provide Business Details
            </span>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row  items-center justify-center w-full">
          <div
            className={`p-2 flex flex-col border-green-700 items-center justify-center ${
              openTab === 3 ? "text-green-700" : "text-slate-400"
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold ">Next of Kin</span>
            <span className="px-2 text-xs font-normal ">
              Provide Next of Kin Details
            </span>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-center w-full">
          <div
            className={`p-2 flex flex-col border-green-700 items-center justify-center ${
              openTab === 4 ? "text-green-700" : "text-slate-400"
            }`}
            data-aos="zoom-in"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <span className="px-2 text-xs font-bold">Review Details</span>
            <span className="px-2 text-xs font-normal">
              Confirm all provided Details
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiForm;
