import React, { useState, useEffect, useContext } from "react";
import { CiImport } from "react-icons/ci";
import { FaArrowDown } from "react-icons/fa6";
import { FaArrowUp } from "react-icons/fa";
import Chart from "../assets/Chart.svg";
import map from "../assets/map.svg";
import downChart from "../assets/downChart.svg";
import LineChart from "../reusables/Chart";
import { useNavigate } from "react-router-dom";
import Progressbar from "../reusables/Progressbar";
import AuthContext from "../context/AuthContext";
import PageLayout from "../reusables/shared/layout/PageLayout";
import { DatePicker } from "@mantine/dates";

const DashboardAdmin = () => {
  let { user } = useContext(AuthContext);
  const [date, setDate] = useState("");
  const [allMembersRecords, setAllMembersRecords] = useState(0);
  const [displayNav, setDisplayNav] = useState(false);
  const [value, setValue] = useState([]);

  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const statePerformance = [30, 20, 10, 10, 5];
  const handleSelect = (ranges) => {
    setDateRange([ranges.selection]);
  };

  const handleSetAllMembersRecords = (total) => {
    setAllMembersRecords(total);
  };

  const handleCancel = () => {
    // Handle cancel action
  };

  const handleApprove = () => {
    // Handle approve action
  };

  const handleDateChange = (date) => {
    setDate(date);
  };

  const showSideNav = () => {
    setDisplayNav(!displayNav);
  };
  return (
    <PageLayout setAllMembersRecords={handleSetAllMembersRecords}>
      <section className="pl-3 pt-1 w-full overflow-x-hidden">
        <div className="flex justify-between pt-5">
          <div>
            <div className="flex border rounded-lg  shadow-sm">
              <button className="border-r border shadow  px-2">
                12 months
              </button>
              <button className="border-r border shadow px-2">30 Days</button>
              <button className="border-r border shadow  px-2">7 Days</button>
              <button className="border-r border shadow  px-2">24 Hours</button>
            </div>
          </div>

          <button className="flex md:hidden mr-4 border-2 -mt-1 shadow-sm px-3 py-1 rounded-lg">
            <CiImport className="mt-1 mr-2" />
            <p>Export</p>
          </button>
          {/* <div className="">

          </div> */}
        </div>

        <section
          className="ml-20 md:ml-0 md:grid md:grid-cols-3"
          style={{
            width: "100%",
          }}
        >
          <div className="m-4 border shadow-lg w-[290px] py-5 px-2">
            <div className="flex flex-col w-3/4">
              <p className="text-[13px] text-black-500 text-left tracking-wider">
                Members <br />
              </p>
              <h3 className="text-[27px] mb-3 text-left font-semibold">
                40.5M
              </h3>
              <div className="flex items-center">
                <FaArrowUp className="text-[13px] text-gray-500 mr-1 text-green-700 " />
                <p className="text-[13px] text-gray-500 text-left tracking-wider">
                  <scan className="text-green-700 "> 12% </scan>vs last month
                </p>
              </div>
            </div>
            <div className=" -mt-14 ml-36">
              <img src={Chart} alt="chart" className="w-28" />
            </div>
          </div>

          <div className="m-4 border shadow-lg w-[290px] py-5 px-2">
            <div className="flex flex-col w-3/4">
              <p className="text-[13px] text-black-500 text-left tracking-wider">
                Collection <br />
              </p>
              <h3 className="text-[27px] mb-3 text-left font-semibold">
                26.4k
              </h3>
              <div className="flex items-center">
                <FaArrowDown className="text-[13px] text-gray-500 mr-1 text-red-700 " />
                <p className="text-[13px] text-gray-500 text-left tracking-wider">
                  <scan className="text-red-700 "> 2% </scan>vs last month
                </p>
              </div>
            </div>
            <div className=" -mt-14 ml-36">
              <img src={downChart} alt="chart" className="w-28" />
            </div>
          </div>

          <div className="m-4 border shadow-lg w-[290px] py-5 px-2">
            <div className="flex flex-col w-3/4">
              <p className="text-[13px] text-black-500 text-left tracking-wider">
                Members <br />
              </p>
              <h3 className="text-[27px] mb-3 text-left font-semibold">
                116.7B
              </h3>
              <div className="flex items-center">
                <FaArrowUp className="text-[13px] text-gray-500 mr-1 text-green-700 " />
                <p className="text-[13px] text-gray-500 text-left tracking-wider">
                  <scan className="text-green-700 "> 12% </scan>vs last month
                </p>
              </div>
            </div>
            <div className=" -mt-14 ml-36">
              <img src={Chart} alt="chart" className="w-28" />
            </div>
          </div>
        </section>
        <div
          className="flex flex-col w-[975px] bg-white mt-4 border ml-3"
          style={{
            height: "63vh",
            width: "98%",
          }}
        >
          <div
            className="flex flex-col w-3/4 m-2  bg-white-100 h-14"
            style={{
              width: "90%",
            }}
          >
            <p className="text-xs md:text-[13px] text-black-500 text-left tracking-wider">
              Members <br />
            </p>
            <div className="flex items-center">
              <p className="text-[8px] md:text-[13px] text-gray-500 text-left tracking-wider">
                Info graphics of MATAN members across Nigeria
              </p>
            </div>
          </div>
          <div
            className="flex flex-row  m-2 bg-white-400 "
            style={{
              width: "100%",
              height: "34vh",
            }}
          >
            <div className="flex items-center bg-white-300 w-60 md:w-3/4 m-2">
              <img src={map} alt="map" />
            </div>

            <div className="flex flex-col bg-white-300 w-1/5 px-2 mx-6">
              <h3 className="text-2xl font-bold mb-4">{"40.5M"}</h3>
              {statePerformance.map((readingValue, index) => (
                <Progressbar
                  key={index}
                  bgcolor="#268246"
                  progress={readingValue}
                  height={5}
                  state="Lagos"
                />
              ))}
            </div>
          </div>
        </div>
        <div className=" mt-3">
          <div
            className="m-4 border shadow-lg  md:w-[990px] md:h-[250px] py-5 px-4"
            style={{
              width: "98%",
            }}
          >
            <div className="">
              <p className="text-xs md:text-[17px] font-semibold text-black-500 text-left tracking-wider">
                Active Users right now
              </p>
              <p className="text-xs md:text-[13px] text-gray-300 text-left tracking-wider">
                Tax Collection Analysis
              </p>
              {/* <DatePicker type="range" value={value} onChange={setValue} /> */}

              <LineChart />
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
};

export default DashboardAdmin;
