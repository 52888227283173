import React, { useState } from "react";
import EnhancedTable from "../reusables/AgentsReuseables/NewMemberCompleteTable";
import AdminAuth from "../context/AdminContext/adminAuth";
import PageLayout from "../reusables/shared/layout/PageLayout";

const Members = () => {
  const [selectedOption, setSelectedOption] = useState("allMembers");
  const [selectedExButton, setSelectedExButton] = useState(null);
  const [totalOnboardedMembers, setTotalOnboardedMembers] = useState(0);

  const removeHandleExportClick = (elem) => {
    setSelectedExButton(!elem);
  };

  const handleExportClick = (elem) => {
    setSelectedExButton(elem);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const handleTableValue = (data) => {
    setTotalOnboardedMembers(data);
  };

  return (
    <PageLayout
      title="Members"
      subTitle="View/Create Members."
      totalValue={totalOnboardedMembers}
    >
      <section
        style={{
          width: "100%",
        }}
        className="pl-3 pt-1 min-w-screen overflow-y-auto no-scrollbar "
        onMouseDown={removeHandleExportClick}
      >
        <div className="flex text-[12px] mt-6 justify-around w-3/6">
          <button
            onClick={() => handleOptionClick("")}
            className={`${
              selectedOption === "allMembers"
                ? "text-green-700 font-bold border-b-2 border-green-700 rounded-sm"
                : ""
            }`}
          >
            All Categories
          </button>
          <button
            onClick={() => handleOptionClick("Trader")}
            className={`${
              selectedOption === "Trader"
                ? "text-green-700 font-bold border-b-2 border-green-700 rounded-sm"
                : ""
            }`}
          >
            Traders
          </button>
          <button
            onClick={() => handleOptionClick("Haulage")}
            className={`${
              selectedOption === "Haulage"
                ? "text-green-700 font-bold border-b-2 border-green-700 rounded-sm"
                : ""
            }`}
          >
            Haulage
          </button>
        </div>
        <hr className="my-0 border-t border-gray-200 w-full" />

        <EnhancedTable
          handleTableValue={handleTableValue}
          totalValue={totalOnboardedMembers}
          selectedOption={selectedOption}
        />
      </section>
    </PageLayout>
  );
};

export default Members;
