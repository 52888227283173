import React from "react";

function AddButton({
  title = "Sign in",
  bgColor = "bg-green-700",
  color = "text-white",
  icon = null,
  className = "",
  onClick,
}) {
  const handleButtonCLick = () => {
    onClick();
  };
  return (
    <div className="flex flex-col mt-8">
      <button
        className={`flex justify-center items-center ${bgColor} rounded-lg px-4 py-2 text-xs ${color}  ${className}`}
        onClick={handleButtonCLick}
      >
        {icon && <span className="mr-2 text-xs">{icon}</span>}
        {title}
      </button>
    </div>
  );
}

export default AddButton;
