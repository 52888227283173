import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LuUploadCloud } from "react-icons/lu";
import { GoPerson } from "react-icons/go";

import TextInput from "../../reusables/shared/input-fields/TextInput";
import DropdownSelect from "../../reusables/shared/input-fields/SelectDropdown";
import { validationSchema } from "../../Agent/validation/userRegistration";
import formatBytes from "../../reusables/AgentsReuseables/functions/formatBytes";
import AdminAuth from "../../context/AdminContext/adminAuth";
import PageLayout from "../../reusables/shared/layout/PageLayout";

const Settings = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [formValues, setFormValues] = useState({
    title: "",
    passport: "",
  });
  const { createAgent, toastMessage } = AdminAuth();
  const queryClient = useQueryClient();

  const MAX_FILE_SIZE = 2 * 1024 * 1024;

  const handleDropdownChange = (selectedValue) => {
    setFormValues((prevData) => ({
      ...prevData,
      title: selectedValue,
    }));
    setValue("title", selectedValue);
  };

  const handleFileChange = async (event) => {
    setError(null);
    const file = event.target.files[0];
    if (file && file.size <= MAX_FILE_SIZE) {
      try {
        const base64String = await convertFileToBase64(file);
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          passport: base64String,
        }));
        setSelectedFile(file);
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    } else {
      setSelectedFile(null);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        passport: "",
      }));
      setError(
        `File size exceeds the maximum allowed (${formatBytes(MAX_FILE_SIZE)}).`
      );
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const { mutateAsync: createAgentEntries, isPending } = useMutation({
    mutationFn: async (formData) => {
      try {
        await validationSchema.validate(formData, { abortEarly: false });
        const response = await createAgent({ ...formData });

        if (!(response.Message === "Successful")) {
          setShowSpinner(false);
          toastMessage("error", response.Message);
          return;
        }
      } catch (error) {
        // console.log(error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["agents"] });
    },
  });

  const onSubmit = async (data) => {
    try {
      await createAgentEntries({ ...data, passport: formValues.passport });
    } catch (validationErrors) {
      // console.log("Validation errors:", validationErrors);
    }
  };

  const genderArray = [
    { value: "", label: "Select" },
    { value: "Role", label: "Role" },
    { value: "Admin", label: "Admin" },
    { value: "Agent", label: "Agent" },
    { value: "Other", label: "Other" },
  ];

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const allowedFileTypes = ["svg", "png", "jpeg", "jpg"];
  const [validateFile, setValidateFile] = useState("");
  const [fileData, setFileData] = useState(null);
  const hasError = Object.keys(errors).length > 0;

  return (
    <PageLayout
      title="Settings"
      subTitle={"Update your info and personal details here"}
      noButton={false}
    >
      <section className="ml-3 w-80% mt-[-20px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:ml-7 mt-4 flex justify-end items-center space-x-4">
            <button
              className="btn red-outline font-medium w-1/4 md:w-auto w-32"
              type="button"
              // onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className={`border text-white text-sm bg-green-600 rounded p-2 w-32 ${
                !isValid || hasError
                  ? "opacity-50 cursor-not-allowed bg-slate-300"
                  : ""
              }`}
              type="submit"
              disabled={!isValid || hasError}
            >
              {!isPending ? "Save" : "Loading...."}
            </button>
          </div>

          <div className="md:ml-7 mt-4 flex items-center space-x-4">
            <p className="font-semibold w-[65px] md:w-[149px]">Name</p>
            <div className="flex flex-1 space-x-4">
              <TextInput
                name="lastName"
                fieldName={register("lastName")}
                placeholder="Enter Last Name"
                required
                errorMessage={errors.lastName?.message}
                className="w-full"
              />
              <TextInput
                name="firstName"
                fieldName={register("firstName")}
                placeholder="Enter First Name"
                required
                errorMessage={errors.firstName?.message}
                className="w-full"
              />
            </div>
          </div>

          <div className="md:ml-7 mt-4">
            <div className="flex items-center space-x-4">
              <p className="font-semibold w-[65px] md:w-[149px]">
                Email address
              </p>
              <div className="flex flex-1 space-x-4 items-center">
                <TextInput
                  name="email"
                  fieldName={register("email")}
                  placeholder="Enter Email"
                  errorMessage={errors.email?.message}
                  className="w-full"
                  type="email"
                />
              </div>
            </div>

            <div className="flex items-center space-x-4 mt-4">
              <p className="font-semibold w-[65px] md:w-[149px]">Role</p>
              <div className="flex flex-1 space-x-4">
                <DropdownSelect
                  options={genderArray}
                  value={formValues.title}
                  onChange={handleDropdownChange}
                  id="gender-select"
                  className="w-full"
                />
              </div>
            </div>
          </div>

          <div className="md:ml-7 mt-4">
            <div className="flex items-center space-x-4">
              <p className="font-semibold w-[65px] md:w-[149px]">
                Phone Number
              </p>
              <div className="flex flex-1 space-x-4 items-center">
                <TextInput
                  name="phoneNumber"
                  fieldName={register("phoneNumber")}
                  placeholder="Enter Phone Number"
                  errorMessage={errors.phoneNumber?.message}
                  className="w-full"
                  type="tel"
                />
              </div>
            </div>
          </div>

          <div className="md:ml-7 mt-4 w-full">
            <div className="flex items-center space-x-4 w-full">
              <div className="w-[120px] md:w-auto">
                <p className="font-semibold">Your Photo</p>
                <p className="font-light text-xs">
                  This will be displayed on your profile
                </p>
              </div>
              <div className="w-full">
                <div className="flex items-center space-x-4">
                  <label className="flex items-center cursor-pointer appearance-none rounded-md border border-gray-300 bg-white p-4 text-sm transition hover:border-gray-400 focus:border-solid focus:border-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75">
                    <div className="rounded-full bg-gray-100 p-2">
                      <LuUploadCloud className="text-2xl text-gray-600" />
                    </div>
                    <span className="text-xs font-bold text-gray-600">
                      Click to upload{" "}
                      <span className="text-gray-400 font-medium">
                        or drag and drop
                      </span>
                    </span>
                    <span className="text-xs text-gray-600">
                      SVG, PNG, JPG, or GIF (max. 800x400px)
                    </span>
                    <input
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                      accept="image/*"
                    />
                    {error && <p className="text-xs text-red-500">{error}</p>}
                  </label>

                  {!selectedFile ? (
                    <div className="rounded-full bg-gray-100 p-2">
                      <GoPerson className="text-7xl text-slate-600" />
                    </div>
                  ) : (
                    <div>
                      <div className="rounded-full overflow-hidden bg-gray-300">
                        <img
                          src={"data:image/jpg;base64," + formValues.passport}
                          alt="Selected File"
                          style={{
                            width: "80px",
                            height: "80px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </PageLayout>
  );
};

export default Settings;
