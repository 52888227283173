import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";
import { FaArrowDown } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";

import Chart from "../../assets/Chart.svg";
import downChart from "../../assets/downChart.svg";
import map from "../../assets/map.svg";
import ReactChart from "../../reusables/AgentsReuseables/Charts";
import Progressbar from "../../reusables/Progressbar";
import PageLayout from "../../reusables/shared/layout/PageLayout";

function Dashboard() {
  const [displayNav, setDisplayNav] = useState(false);

  const showSideNav = () => {
    setDisplayNav(!displayNav);
  };

  // useEffect(() => {
  //   const closeSideNav = () => {
  //     if (displayNav) {
  //       setDisplayNav(false);
  //     }
  //   };
  //   window.addEventListener("click", closeSideNav);
  //   return () => window.removeEventListener("click", closeSideNav);
  // }, [displayNav]);
  const statePerformance = [30, 20, 10, 10, 5];

  return (
    <PageLayout>
      <section className="pl-3 pt-1 w-full overflow-x-hidden">
        <div
          className="flex flex-col w-full bg-white mt-4 border rounded-lg m-2"
          style={{
            height: "auto",
            width: "99%",
          }}
        >
          <div
            className="flex flex-col w-3/4 m-2  bg-white-100 h-14"
            style={{
              width: "100%",
            }}
          >
            <p className="text-[13px] text-black-500 text-left tracking-wider">
              Members <br />
            </p>
            <div className="flex items-center">
              <p className="text-[13px] text-gray-500 text-left tracking-wider">
                Info graphics of MATAN members across Nigeria
              </p>
            </div>
          </div>
          <div
            className="flex flex-row m-2 bg-white-400  justify-between"
            style={{ height: "auto" }}
          >
            <div className="flex bg-white-300 w-3/5 m-0">
              <img
                src={map}
                alt="map"
                style={{ width: "100%", height: "100%" }}
              />
            </div>

            <div className="flex flex-col bg-white-300 w-1/5 px-2 mx-6">
              <h3 className="text-2xl font-bold mb-4">{"40.5M"}</h3>
              {statePerformance.map((readingValue, index) => (
                <Progressbar
                  key={index}
                  bgcolor="#268246"
                  progress={readingValue}
                  height={5}
                  state="Lagos"
                />
              ))}
            </div>
          </div>
        </div>
        <div
          className="flex flex-col w-full bg-white mt-8  mb-0 border"
          style={{ height: "auto" }}
        >
          <h1 className="font-bold text-2xl m-4">Active users right now</h1>
          <p className="text-sm mx-4">Tax Collection analysis</p>
          <div
            className="flex items-center bg-white-300 w-full "
            style={{ height: "100%", width: "100%" }}
          >
            <ReactChart />
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default Dashboard;
