import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { CiWavePulse1 } from "react-icons/ci";
import { alpha } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";
import FilterDropdown from "./Agentfilter";
import LoadingSpinner from "../spinner";
import Card from "./Card";
import formatDate from "../dateConverter";
import EditAssociationDetails from "../shared/modal/AssociationModal";
import SearchInput from "../shared/input-fields/SearchInput";
import adminAuth from "../../context/AdminContext/adminAuth";
import MaskString from "./functions/MaskedEmail";
import Associations from "../../pages/Association";

const filterOptions = [
  { id: 1, label: "Haulage" },
  { id: 2, label: "Traders" },
  { id: 3, label: "Active" },
  { id: 4, label: "Inactive" },
];

const statuses = ["Active", "inActive"];
const Statuses = ["Active", "inActive"];
const categories = ["", "Traders", "Haulage"];

const headCells = [
  { id: "MatanID", numeric: false, disablePadding: true, label: "Matan ID" },
  { id: "Action", numeric: true, disablePadding: false, label: "Action" },
  { id: "Email", numeric: true, disablePadding: false, label: "Email" },
  { id: "Phone", numeric: true, disablePadding: false, label: "Phone" },
  {
    id: "AccountNumber",
    numeric: true,
    disablePadding: false,
    label: "Account Number",
  },
  {
    id: "CreatedAt",
    numeric: true,
    disablePadding: false,
    label: "Date Created",
  },
  { id: "Category", numeric: true, disablePadding: false, label: "Category" },
  { id: "Status", numeric: true, disablePadding: false, label: "Status" },
];

const EnhancedTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) => {
  const createSortHandler = useCallback(
    (property) => (event) => onRequestSort(event, property),
    [onRequestSort]
  );

  return (
    <TableHead>
      <TableRow className="bg-gray-100">
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: "auto" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({ numSelected, handleSearch }) => {
  const [filteredData, setFilteredData] = useState([]);

  const handleSelectionChange = (selectedItems) =>
    setFilteredData(selectedItems);

  const removedClickedFilter = (item) => {
    setFilteredData((prev) => prev.filter((filterItem) => filterItem !== item));
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
      className="mb-4"
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <SearchInput
          placeholder="Search for Association"
          onSearch={handleSearch}
          debounceTime={5000}
        />
      )}
      <div
        className="flex flex-row mr-1 justify-end"
        style={{ width: "70%", height: "100%" }}
      >
        {filteredData.map((item, index) => (
          <Card
            key={index}
            textTitle={item}
            onClick={() => removedClickedFilter(item)}
          />
        ))}
        <FilterDropdown
          title="Filter"
          className="bg-green-200"
          options={filterOptions}
          onSelectionChange={handleSelectionChange}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

const EnhancedTable = ({
  handleTableValue,
  user = "Admin",
  selectedOption,
}) => {
  const [order, setOrder] = useState("asc");
  const [action, setAction] = useState("Edit");
  const [orderBy, setOrderBy] = useState("DateCreated");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchText, setSearchText] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const { listMembers, listMembersSwitchTabs } = adminAuth();

  const closeModal = () => setShowDetails(false);

  const handleClick = useCallback(
    (event, id) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
    },
    [selected]
  );

  const handleChangePage = useCallback((event, newPage) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }, []);

  const handleChangeDense = useCallback((event) => {
    setDense(event.target.checked);
  }, []);

  const isSelected = useCallback(
    (id) => selected.indexOf(id) !== -1,
    [selected]
  );

  const handleEditClick = useCallback((row) => {
    setShowDetails(true);
    setAction("Edit");
    setSelectedRow(row);
  }, []);

  const handleDeleteClick = useCallback((row) => {
    setShowDetails(true);
    setAction("Delete");
    setSelectedRow(row);
  }, []);

  const handleSearch = useCallback((query) => {
    setSearchText(query);
  }, []);

  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["members", page, rowsPerPage, searchText, selectedOption],
    queryFn: async () => {
      setLoading(true);
      const queryString = `pageIndex=${
        page + 1
      }&pageSize=${rowsPerPage}&searchValue=${searchText}`;
      const response =
        selectedOption !== "Trader" && selectedOption !== "Haulage"
          ? await listMembers(queryString)
          : await listMembersSwitchTabs(
              `${queryString}&tabValue=${selectedOption}`
            );

      setRecords(response.Data?.Items);
      setTotalRecords(response?.Data?.TotalCount);
      handleTableValue(response?.Data?.TotalCount);
      setLoading(false);
      return response;
    },
    staleTime: 0,
    cacheTime: 0,
    keepPreviousData: true,
    onSuccess: (response) => {},
    onError: (err) => console.error("Error fetching members:", err),
  });

  useEffect(() => {
    refetch();
  }, [page, rowsPerPage, searchText, selectedOption, refetch]);

  const emptyRows = useMemo(
    () =>
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - records.length) : 0,
    [page, rowsPerPage, records]
  );

  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const sortedRows = useMemo(() => {
    const comparator = (a, b) => {
      if (orderBy === "Status" || orderBy === "Category") {
        return statuses.indexOf(a[orderBy]) - statuses.indexOf(b[orderBy]);
      }
      if (orderBy === "CreatedAt") {
        return new Date(a[orderBy]) - new Date(b[orderBy]);
      }
      if (orderBy === "MatanID" || orderBy === "AccountNumber") {
        return parseInt(a[orderBy]) - parseInt(b[orderBy]);
      }
      return a[orderBy]?.localeCompare(b[orderBy]);
    };

    const stabilizedThis = records.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const orderByComparator = comparator(a[0], b[0]);
      if (orderByComparator !== 0) return orderByComparator;
      return a[1] - b[1];
    });

    return order === "desc"
      ? stabilizedThis.reverse().map((el) => el[0])
      : stabilizedThis.map((el) => el[0]);
  }, [records, order, orderBy]);

  return (
    <Box sx={{ width: "100%", mb: 3 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleSearch={handleSearch}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={(event) =>
                setSelected(
                  event.target.checked ? records.map((n) => n.MatanID) : []
                )
              }
              onRequestSort={handleRequestSort}
              rowCount={records.length}
            />

            <TableBody>
              {sortedRows.map((row, index) => {
                const isItemSelected = isSelected(row.MatanID);
                const labelId = `enhanced-table-checkbox-${index}`;
                let rowStatus = row?.Status;
                let CategoryOfService =
                  row?.BusinessCategoryOfService || row.CategoryOfService;

                if (user === "agent") {
                  rowStatus = Statuses[rowStatus];
                  CategoryOfService = categories[CategoryOfService + 1];
                } else {
                  rowStatus = rowStatus;
                  CategoryOfService = categories[CategoryOfService + 2];
                }

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.MatanID)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.MatanID}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className="p-2 font-small text-gray-900 whitespace-nowrap text-[12px]"
                    >
                      <p className="text-[12px] mb-1 text-left font-semibold">
                        {row?.MatanId || "MATAN/LAGOS/561236"}
                      </p>
                      <p className="text-gray-500 text-left tracking-wider">
                        {`${row?.LastName} ${row?.FirstName}`}
                      </p>
                    </TableCell>
                    <TableCell
                      align="left"
                      className="font-small text-gray-900 whitespace-nowrap transition duration-300 ease-in-out"
                    >
                      <span
                        className="flex flex-row items-center "
                        onClick={() => {
                          setSelectedRow(row);
                        }}
                      >
                        <CiWavePulse1
                          className="text-[20px] text-gray-500 mr-0 hover:text-green-700 cursor-pointer"
                          title="View Details"
                          onClick={() => {
                            setSelectedRow(row);
                          }}
                        />
                        <FiEdit2
                          className="text-[15px] text-gray-500 hover:text-blue-700 cursor-pointer"
                          title="Edit"
                          onClick={() => {
                            setSelectedRow(row);
                          }}
                        />
                      </span>
                    </TableCell>{" "}
                    <TableCell align="right">
                      {MaskString(row.Email) || "---"}
                    </TableCell>
                    <TableCell align="right">{row.PhoneNumber}</TableCell>
                    <TableCell align="right">
                      {row.AccountNumber || "Pending"}
                    </TableCell>
                    <TableCell align="right">
                      {formatDate(row?.Created_At)}
                    </TableCell>
                    <TableCell align="right">{CategoryOfService}</TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row items-center border-1 rounded-lg p-1 justify-start">
                        <span
                          className={`flex items-center rounded-lg p-0 ${
                            rowStatus?.toLowerCase() !== "active"
                              ? "text-red-500 bg-red-100"
                              : "text-green-600 bg-green-100"
                          }`}
                        >
                          <GoDotFill
                            className={`text-[20px] mr-1 ${
                              rowStatus?.toLowerCase() === "inactive"
                                ? "text-red-500"
                                : "text-green-600"
                            }`}
                          />
                          {/* {row.Status || Statuses[row?.Status]} */}
                          {rowStatus}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalRecords}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      {showDetails && (
        <EditAssociationDetails
          action={action}
          row={selectedRow}
          open={showDetails}
          closeModal={closeModal}
          refetch={refetch}
        />
      )}
      {(isLoading || loading) && <LoadingSpinner />}
    </Box>
  );
};

EnhancedTable.propTypes = {
  handleTableValue: PropTypes.func,
  user: PropTypes.string,
  selectedOption: PropTypes.string,
};

export default EnhancedTable;
