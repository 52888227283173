import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { FiEdit2 } from "react-icons/fi";
import { GoDotFill } from "react-icons/go";

import { visuallyHidden } from "@mui/utils";
import FilterDropdown from "./Agentfilter";
import formatDate from "../dateConverter";
import AdminAuth from "../../context/AdminContext/adminAuth";
import LoadingSpinner from "../spinner";
import EditAgentModal from "../Modals/EditAgentModal";
import Card from "./Card";
import SearchInput from "../shared/input-fields/SearchInput";

const filterOptions = [
  { Id: 3, label: "Active" },
  { Id: 4, label: "Inactive" },
];

const headCells = [
  { id: "AgentId", numeric: false, disablePadding: true, label: "Agent ID" },
  { id: "Name", numeric: false, disablePadding: true, label: "Name" },
  { id: "Action", numeric: true, disablePadding: false, label: "Action" },
  { id: "Email", numeric: true, disablePadding: false, label: "Email" },
  { id: "Phone", numeric: true, disablePadding: false, label: "Phone" },
  {
    id: "Created",
    numeric: true,
    disablePadding: false,
    label: "Date Created",
  },
  { id: "Role", numeric: true, disablePadding: false, label: "Role" },
  { id: "Status", numeric: true, disablePadding: false, label: "Status" },
];

const EnhancedTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}) => {
  const createSortHandler = useCallback(
    (property) => (event) => onRequestSort(event, property),
    [onRequestSort]
  );

  return (
    <TableHead>
      <TableRow className="bg-gray-100">
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: "auto" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id && (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              )}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = ({ numSelected, handleSearch }) => {
  const [filteredData, setFilteredData] = useState([]);

  const handleSelectionChange = (selectedItems) =>
    setFilteredData(selectedItems);

  const removedClickedFilter = (item) => {
    setFilteredData((prev) => prev.filter((filterItem) => filterItem !== item));
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
      className="mb-4"
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <SearchInput
          placeholder="Search for Agent"
          onSearch={handleSearch}
          debounceTime={5000}
        />
      )}
      <div className="flex flex-row mr-1 justify-end" style={{ width: "70%" }}>
        {filteredData.map((item, index) => (
          <Card
            key={index}
            textTitle={item}
            onClick={() => removedClickedFilter(item)}
          />
        ))}
        <FilterDropdown
          title="Filter"
          className="bg-green-200"
          options={filterOptions}
          onSelectionChange={handleSelectionChange}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
};

export default function EnhancedTable({ handleTableValue }) {
  const {
    setAgentAction: setAdminAgentAction,
    listAgents,
    searchAgent,
  } = AdminAuth();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DateCreated");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [currentAgent, setCurrentAgent] = useState({});
  const [editAgent, setEditAgent] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const toggleModal = () => setEditAgent((prev) => !prev);

  const fetchAgents = useCallback(async () => {
    return searchText.length > 1
      ? searchAgent(
          `pageIndex=${page + 1}&pageSize=${rowsPerPage}&name=${searchText}`
        )
      : listAgents(`pageIndex=${page + 1}&pageSize=${rowsPerPage}`);
  }, [searchText, page, rowsPerPage, searchAgent, listAgents]);

  const { data, isLoading } = useQuery({
    queryKey: ["agents", page, rowsPerPage, searchText],
    queryFn: fetchAgents,
    onSuccess: (response) => {},
    staleTime: 10000,
    cacheTime: 0,
  });

  const allAgents = useMemo(() => data?.Data?.Items || [], [data]);
  const totalAgents = useMemo(() => data?.Data?.TotalCount || 0, [data]);

  const sendValueToParent = () => {
    handleTableValue(totalAgents);
  };

  useEffect(() => {
    handleTableValue(totalAgents);
  }, [totalAgents]);

  return (
    <Box sx={{ width: "100%", mb: 3 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleSearch={setSearchText}
        />
        <TableContainer className="min-h-[90vh] max-h-[98vh] overflow-y-auto">
          <Table aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => {}}
              onRequestSort={handleRequestSort}
              rowCount={totalAgents}
            />

            <TableBody>
              {allAgents.map((row, index) => {
                const rowStatus =
                  row.Status === 0 || row.Status.toLowerCase() === "active"
                    ? "active"
                    : "inactive";
                const isItemSelected = isSelected(row.AgentId);
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.AgentId}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        onChange={() => {}}
                        inputProps={{
                          "aria-labelledby": `enhanced-table-checkbox-${index}`,
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.AgentID}</TableCell>
                    <TableCell>
                      <p className="text-[12px] mb-1 text-left font-semibold tracking-wider">
                        {`${row?.LastName} ${row?.FirstName}`}
                      </p>
                    </TableCell>
                    <TableCell>
                      <FiEdit2
                        onClick={() => {
                          toggleModal();
                          setCurrentAgent(row);
                          setAdminAgentAction("edit-agent");
                        }}
                      />
                    </TableCell>
                    <TableCell>{row.Email}</TableCell>
                    <TableCell>{row.PhoneNumber}</TableCell>
                    <TableCell>
                      {row.Created ? formatDate(row.Created) : "---"}
                    </TableCell>
                    <TableCell>{row.Role || "Agent"}</TableCell>
                    <TableCell align="left">
                      <div className="flex flex-row items-center border-1 rounded-lg p-1 justify-start">
                        <span
                          className={`flex items-center rounded-lg p-0 ${
                            rowStatus.toLowerCase() !== "active"
                              ? "text-red-500 bg-red-100"
                              : "text-green-600 bg-green-100"
                          }`}
                        >
                          <GoDotFill
                            className={`text-[20px] mr-1 ${
                              rowStatus.toLowerCase() === "inactive"
                                ? "text-red-500"
                                : "text-green-600"
                            }`}
                          />
                          {rowStatus}
                        </span>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={totalAgents}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Compact view"
      />
      {editAgent && (
        <EditAgentModal
          closeAgentEditModal={toggleModal}
          currentAgent={currentAgent}
        />
      )}
      {isLoading && <LoadingSpinner />}
    </Box>
  );
}

EnhancedTable.propTypes = {
  handleTableValue: PropTypes.func.isRequired,
};

EnhancedTable.propTypes = {
  handleTableValue: PropTypes.func.isRequired,
};
