import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

const PrivateRoute = ({ children, ...rest }) => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <Navigate to="/signIn" />;
  }

  const role =
    user["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"] ||
    "agent";

  if (role.toLowerCase().includes("admin") && rest.agentRoute === true) {
    return <Navigate to="/dashboardAdmin" />;
  }

  if (!role.toLowerCase().includes("admin") && rest.adminRoute === true) {
    return <Navigate to="/dashboardAgent" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
