import React, { useState } from "react";
import ModalAgent from "../reusables/shared/modal/AgentModal";
import BulkModal from "../reusables/Modals/BulkModal";
import AgentsTable from "../reusables/AgentsReuseables/agentCompleteTable";
import PageLayout from "../reusables/shared/layout/PageLayout";

const AgentPage = () => {
  const [isBulkModalOpen, setBulkModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [totalAgent, setTotalAgent] = useState(0);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleTableValue = (data) => {
    setTotalAgent(data);
  };

  return (
    <PageLayout
      title="Agents"
      subTitle="View/Create Agents."
      totalValue={totalAgent}
      showDetails={modalOpen}
      setShowDetails={setModalOpen}
      buttonShown="all"
      noButton={false}
    >
      <section
        className="pl-3 pt-1 overflow-x-hidden md:mt-2"
        style={{
          width: "98%",
        }}
      >
        <div className="mt-5 w-full">
          <AgentsTable
            handleTableValue={handleTableValue}
            totalValue={totalAgent}
          />

          {modalOpen && <ModalAgent closeModal={closeModal} />}
          <>{isBulkModalOpen && <BulkModal />}</>
        </div>
      </section>
    </PageLayout>
  );
};

export default AgentPage;
