import React from "react";
import { BsDownload } from "react-icons/bs";
import { CiCircleCheck } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";

import Button from "./addButton";
import AgentAuth from "../../context/AgentContext/agentAuth";

const TopCard = ({
  title = "Welcome back",
  subTitle = "Monitor Activities going on in the MATAN Platform",
  button1,
  button2,
  button3,
  totalValue = "17676",
  onButton1Click,
  onButton2Click,
  onButton3Click,
}) => {
  const { setModalOpen, setModalCollection, setAgentAction } = AgentAuth();

  const handleAddNewMember = () => {
    setAgentAction("newRegistration");
    if (onButton1Click) onButton1Click();
  };

  const handleAddCollection = () => {
    setModalCollection(true);
    if (onButton2Click) onButton2Click();
  };

  const handleExportCollection = () => {
    // Add export functionality here
    if (onButton3Click) onButton3Click();
  };

  const renderButtons = () => {
    if (title === "Tax Collections" || title === "Agents") {
      return (
        <div className="flex space-x-2">
          <Button
            title="Export"
            bgColor="bg-slate-100"
            color="text-black"
            icon={<BsDownload />}
            onClick={handleExportCollection}
          />
          {title != "Agents" && (
            <Button
              title="Verify Collection"
              bgColor="bg-green-200"
              color="text-black"
              icon={<CiCircleCheck className="text-l" />}
              onClick={handleExportCollection}
            />
          )}
          <Button
            title={`New ${title}`}
            icon={<FaPlus />}
            // onClick={handleAddCollection}
            onClick={handleAddNewMember}
          />
        </div>
      );
    }

    if (
      title === "Members" ||
      title === "Association" ||
      title == "MATAN Admin"
    ) {
      return (
        <div className="flex space-x-2 mx-4">
          <Button
            title={`New ${title}`}
            icon={<FaPlus />}
            onClick={handleAddNewMember}
          />
        </div>
      );
    }

    return null;
  };
  return (
    <div
      className="flex flex-row w-full bg-white m-4 p-1 rounded-lg shadow-md"
      style={{ height: "12vh" }}
    >
      <div className="flex flex-grow flex-col">
        <div className="flex items-left">
          <h2 className="text-2xl font-semibold">
            {title === "Tax Collections" ? "Tax Collection" : title}
          </h2>
          <p className="bg-gray-100 text-[12px] ml-3 tracking-wider items-center h-[3vh] font-semibold px-2 rounded-lg mt-2">
            {totalValue}
          </p>
        </div>
        <p className="text-xs text-gray-500 text-left tracking-wider ml-3 mt-1">
          {subTitle}
        </p>
      </div>
      {renderButtons()}
    </div>
  );
};

export default TopCard;
