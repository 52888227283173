import React from "react";
import { Line } from 'react-chartjs-2';
import Chart from "chart.js/auto";

const LineChart = () => {

    const labels = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];

    const data = {
        labels: labels,
        datasets: [
            {
                label: "Traders",
                backgroundColor: "#15803D",
                borderColor: "#15803D",
                data: [6100, 6500, 6300, 7000, 6200, 7300, 6400, 6000, 7100, 6800, 6300, 7000],
                tension: 0.4,
                borderWidth: 1,
                pointRadius: 0
               
            },
            {
                label: "Haulage",
                backgroundColor: "#15803D",
                borderColor: "#15803D",
                data: [3600, 4500, 4300, 5000, 4200, 4300, 4000, 4000, 4500, 4100, 5000,  4000],
                tension: 0.4,
                borderWidth: 1,
                pointRadius: 0
                
            },
        ],
        
        
    };
    const options = {
        maintainAspectRatio: false, // Set to false to allow custom width and height
        responsive: true,
        scales: {
            y: {
                beginAtZero: false,
                suggestedMin: 0
            },
          
        },
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    boxWidth: 8, // Adjust the width of the legend box
                    usePointStyle: true, // Use a circular point style
                },
            },
        },
        aspectRatio: 2, // Adjust the aspect ratio as needed
    };

    return (
        <div>
            <Line data={data}
             options={options}/>
        </div>
    );
};

export default LineChart;