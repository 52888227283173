import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const data = [
  { name: "Jan", uv: 137, pv: 137, amt: 137 },
  { name: "Feb", uv: 140, pv: 140, amt: 140 },
  { name: "Mar", uv: 150, pv: 150, amt: 150 },
  { name: "Apr", uv: 160, pv: 160, amt: 160 },
  { name: "May", uv: 170, pv: 170, amt: 170 },
  { name: "Jun", uv: 180, pv: 180, amt: 180 },
  { name: "Jul", uv: 190, pv: 190, amt: 190 },
  { name: "Aug", uv: 195, pv: 195, amt: 195 },
  { name: "Sep", uv: 200, pv: 200, amt: 200 },
  { name: "Oct", uv: 205, pv: 205, amt: 205 },
  { name: "Nov", uv: 210, pv: 210, amt: 210 },
  { name: "Dec", uv: 212, pv: 212, amt: 212 },
];

export default function App() {
  return (
    <AreaChart
      width={2400}
      height={400}
      data={data}
      margin={{
        top: 30,
        right: 30,
        left: 0,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="uv"
        stackId="1"
        stroke="#8884d8"
        fill="#fffffe"
      />
      <Area
        type="monotone"
        dataKey="pv"
        stackId="1"
        stroke="#82ca9d"
        fill="#82ca9d"
      />
      <Area
        type="monotone"
        dataKey="amt"
        stackId="1"
        stroke="#ffc658"
        fill="#fffffe"
      />
    </AreaChart>
  );
}
