import React from "react";
import { useNavigate } from "react-router-dom";

import firsalternative from "../assets/firsalternative.svg";
import balogunMkt from "../assets/balogunMkt.svg";
import handshake from "../assets/handshake.svg";
import danfo from "../assets/danfo.svg";
import danfo2 from "../assets/danfo2.svg";
import person1 from "../assets/person1.svg";
import matanBackground from "../assets/matanBackground.svg";
import MatanBackgroundMkt from "../assets/MatanBackgroundMkt.svg";
import HeaderNavbar from "../reusables/HomeComponent/NavBarindex";
import Footer from "../reusables/HomeComponent/footer";
import VerticalLinearStepper from "../reusables/HomeComponent/Stepper";
function Home() {
  const navigate = useNavigate();

  return (
    <main className="flex flex-col w-screen bg-gray-200 top">
      <section
        class="flex justify-center items-center h-screen bg-green-700 flex flex-col"
        style={{
          backgroundImage: `url(${MatanBackgroundMkt || matanBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "50vh",
        }}
      >
        <HeaderNavbar />
        <div className="relative top-11  w-4/5 mx-auto flex flex-col md:block hidden">
          <h4 className="text-2xl text-white font-semibold mb-4 md:text-left">
            Welcome to MATAN - Market Traders Association of Nigeria
          </h4>
          <span className="text-l text-white md:mr-52 mt-3 md:block hidden">
            Empowering Traders Nationwide Since 1995. At MATAN, we are the
            bedrock of Nigeria's vibrant market economy, representing over 40
            million traders across all 36 states and 774 local governments.
            Founded in 1995, we stand as the largest player in Nigeria's market
            space, fostering growth, unity, and prosperity.
          </span>
          <button
            className="btn bg-green-600 text-white font-semibold px-0 py-1 rounded duration-500 md:static  mt-3 w-1/6"
            onClick={() => navigate("/register")}
          >
            Register
          </button>
        </div>
      </section>
      <section className="flex flex-col items-center w-screen">
        <div class="my-11 mx-auto px-2 w-4/5">
          <div class="md:flex">
            <div class="flex-1 text-black text-center px-5 py-5 m-2 rounded">
              <div class="lg:flex lg:items-center">
                <div class="lg:flex-shrink-0">
                  <img class="rounded-lg lg:w-64" src={balogunMkt} alt="" />
                </div>
                <div class="mt-4 lg:mt-0 lg:ml-6">
                  <div class="uppercase tracking-wide text-3xl text-black font-bold">
                    who we are?
                  </div>
                  <p class="block mt-1 text-lg leading-tight font-semibold text-gray-900  justify-start text-start items-start">
                    MATAN, short for Market Traders Association of Nigeria, is
                    the pioneering umbrella association dedicated to championing
                    the rights and interests of traders nationwide. With a rich
                    legacy spanning over two decades, we've become the
                    cornerstone of Nigeria's trading community.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-11 mx-auto px-2 w-4/5">
          <div class="md:flex">
            <div class="flex-1 text-black text-center px-5 py-5 m-2 rounded">
              <div class="lg:flex lg:items-center">
                <div class="mt-4 lg:mt-0 lg:ml-6">
                  <div class="uppercase tracking-wide text-3xl text-black font-bold">
                    Our Mission
                  </div>
                  <p class="block mt-1 text-lg leading-tight font-semibold text-gray-900  justify-start text-start items-start">
                    We strive to empower traders by providing them with a
                    unified voice, access to resources, and a platform for
                    collective action. Our goal is to create a conducive
                    business environment that fosters growth, eliminates
                    exploitation, and promotes sustainable development.
                  </p>
                </div>
                <div class="lg:flex-shrink-0">
                  <img class="rounded-lg lg:w-64" src={person1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-11 mx-auto px-2 w-4/5">
          <div class="md:flex">
            <div class="flex-1 text-black text-center px-5 py-5 m-2 rounded">
              <div class="lg:flex lg:items-center">
                <div class="lg:flex-shrink-0">
                  <img class="rounded-lg lg:w-64" src={handshake} alt="" />
                </div>
                <div class="mt-4 lg:mt-0 lg:ml-6">
                  <div class="uppercase tracking-wide text-3xl text-black font-bold">
                    Our Partnerships
                  </div>
                  <p class="block mt-1 text-lg leading-tight font-semibold text-gray-900  justify-start text-start items-start">
                    MATAN is proud to collaborate with esteemed entities like
                    the Federal Inland Revenue Service (FIRS) and various
                    security agencies. Through these strategic partnerships, we
                    are spearheading efforts to eradicate multiple taxations,
                    combat illegal tax collection, and ensure the safety and
                    security of traders nationwide.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center w-screen">
        <div class="my-11 mx-auto px-2 w-4/5">
          <div class="md:flex">
            <div class="flex-1 text-black text-center px-5 py-5 m-2 rounded">
              <h3 className="text-3xl text-black font-bold w-full flex-start text-left mb-[-20px] ml-2">
                Why choose us
              </h3>

              <div class="lg:flex lg:items-center">
                <VerticalLinearStepper />
                <div class="lg:flex lg:items-start flex-col mr-4">
                  <div className="justify-start items-start text-left mt-2">
                    <div class=" tracking-wide text-xl text-black font-bold mb-4">
                      Unmatched Scale and Reach{" "}
                    </div>
                    <p class="block mt-1 text-lg leading-tight font-semibold text-gray-900  my-4">
                      Embark on your design adventure by initiating your
                      project. Share your vision and set the stage for a bespoke
                      design experience
                    </p>
                  </div>
                  <div className="justify-start items-start text-left mt-2">
                    <div class=" tracking-wide text-xl text-black font-bold mb-4">
                      Advocacy and Support{" "}
                    </div>
                    <p class="block mt-1 text-lg leading-tight font-semibold text-gray-900  my-4">
                      Collaborate closely to achieve design excellence refining
                      your vision and crafting brilliance into every aspect of
                      your space
                    </p>
                  </div>
                  <div className="justify-start items-start text-left mt-2">
                    <div class=" tracking-wide text-xl text-black font-bold mb-4">
                      Strategic Partnerships{" "}
                    </div>
                    <p class="block mt-1 text-lg leading-tight font-semibold text-gray-900  my-4">
                      Witness your vision becoming a reality as we execute the
                      design plan with precision. Celebrate the joy of your
                      newly transformed space
                    </p>
                  </div>
                </div>

                <div class="lg:flex-shrink-0">
                  <img class="rounded-lg lg:w-64" src={danfo2} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="my-11 mx-auto px-2 w-4/5 bg-gray-300">
          <div class="md:flex">
            <div class="flex-1 text-black text-center px-5 py-5 m-2 rounded">
              <div class="lg:flex lg:items-center">
                <div className="justify-start items-start text-left">
                  <div class="uppercase tracking-wide text-2xl text-black font-bold mb-4">
                    Ready to become part of the family{" "}
                  </div>
                  <p class="block mt-1 text-lg leading-tight font-semibold text-gray-900  my-4">
                    Ready to join the largest network of traders in Nigeria?
                    Become a member of MATAN today and unlock a world of
                    opportunities, support, and advocacy.
                  </p>
                  <button
                    className="btn bg-green-700 text-white font-semibold rounded duration-500 md:static mt-3 w-auto p-2"
                    onClick={() => navigate("/register")}
                  >
                    Become a member
                  </button>
                </div>

                <div class="lg:flex-shrink-0">
                  <img class="rounded-lg lg:w-64" src={danfo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="flex items-center justify-center w-screen my-6">
        <img
          src={firsalternative}
          alt="firsalternative"
          className="w-full h-14 "
        />
      </div>
      <Footer />
    </main>
  );
}

export default Home;
