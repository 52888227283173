import CryptoJS from "crypto-js";

const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_KEY);
const iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_CRYPTO_IV);

export const encryptData = (data, action) => {
  let plainText = "";
  if (typeof data === "string") {
    plainText = data;
  } else {
    plainText = JSON.stringify(data);
  }
  const encryptedData = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(plainText),
    key,
    {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  const result = {
    dataValue: encryptedData.toString(),
  };
  if (action) {
    return encryptedData.toString();
  } else {
    return result;
  }
};

export const decryptData = (encryptedText, action) => {
  const decryptedData = CryptoJS.AES.decrypt(encryptedText, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  if (decryptedData) {
    const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedText);
  } else {
    return null;
  }
};
