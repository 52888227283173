import React, { useState } from "react";
import EnhancedTable from "../../reusables/AgentsReuseables/MatanAdmin";
import MatanAdminModal from "../../reusables/shared/modal/MatanAdminModal";
import PageLayout from "../../reusables/shared/layout/PageLayout";

function MatanAdmin() {
  const [totalAssociations, setTotalAssociations] = useState(0);
  const [showDetails, setShowDetails] = useState(false);

  const closeModal = () => {
    setShowDetails(false);
  };

  const handleTableValue = (data) => {
    setTotalAssociations(data);
  };

  return (
    <PageLayout
      title="MATAN Admin"
      subTitle="View/Create MATAN Admin."
      totalValue={totalAssociations}
      showDetails={showDetails}
      setShowDetails={setShowDetails}
      buttonShown="create"
      noButton={false}
    >
      <section
        className="pl-3 pt-1 overflow-x-hidden md:mt-2"
        style={{
          width: "100%",
        }}
      >
        <EnhancedTable
          handleTableValue={handleTableValue}
          totalValue={totalAssociations}
        />
      </section>
      {showDetails && (
        <MatanAdminModal onClose={closeModal} open={showDetails} />
      )}
    </PageLayout>
  );
}

export default MatanAdmin;
