import React from "react";
import { imageObj } from "../icon";

const DefaultPic = () =>{
    return(
        <section className="pt-8 md:mt-5">
            <img src={imageObj.MatanPic} alt="" className="w-[370px] md:w-[420px] mx-auto md:mx-0 "/>
            <div className="text-[12px] text-center md:mr-52 mt-3  text-gray-500">
                Powered by The Alternative Bank
            </div>
        </section>

    )
}

export default DefaultPic;