import React from "react";
import { TermsCondition } from "./TermsCondition";

function TermsModal({ isOpen, onClose, onAccept, isTermsAccepted }) {
  if (!isOpen) return null;

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    onAccept(checked);
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 w-full">
      <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-5xl">
        <h2 className="text-xl font-bold mb-4">Terms & Conditions</h2>
        <p>
          Last updated: <span className="date">2022-11-22</span>
        </p>
        <div className="h-4/5 overflow-y-auto">
          <TermsCondition />
        </div>
        <div className="flex items-center mt-4 mb-4 justify-between">
          <div>
            <input
              id="modal-terms-checkbox"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              checked={isTermsAccepted}
              onChange={handleCheckboxChange}
              // disabled={isTermsAccepted}
            />
            <label
              htmlFor="modal-terms-checkbox"
              className="ml-2 text-sm text-gray-900"
            >
              I accept terms & conditions
            </label>
          </div>
          <button
            onClick={onClose}
            className="bg-green-800 text-white px-4 py-2 rounded-lg hover:bg-indigo-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default TermsModal;
