import axios from "axios";
import { encryptData, decryptData } from "../crypto/AgentCrypto";
const api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    config.data = encryptData(config.data);

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    const data = decryptData(response.data);
    return data;
  },
  (error) => {
    return decryptData(error.response.data);
  }
);

export default api;
