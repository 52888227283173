import React, { useState, useContext } from "react";

import { useNavigate, useParams } from "react-router-dom";
import OTPInput from "../../Auth/OTP/OTPInput";
import AgentAuth from "../../context/AgentContext/agentAuth";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import AuthContext from "../../context/AuthContext";

const OtpFormComponent = () => {
  const { toastMessage, resendUserOtp, verifyUserOtp } = AgentAuth();
  const { verifyLoginOTP } = useContext(AuthContext);

  const queryClient = useQueryClient();
  const { email } = useParams();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");

  const [resendOTP, setResendOTP] = useState(false);
  const disableButton = otp.length <= 5 ? true : false;

  const { mutateAsync: verifyOtp, isPending } = useMutation({
    mutationFn: async (data) => {
      const payload = {
        EmailAddress: atob(email),
        Otp: otp,
      };

      const response = await verifyLoginOTP(payload);
      if (response && !response.success) {
        const badResponse = JSON.parse(response.error);
        const Description = badResponse.Description;
        toastMessage("error", JSON.parse(JSON.stringify(Description)));
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });
  const { mutateAsync: callCesendOtp, isPending: resendingOtp } = useMutation({
    mutationFn: async (request) => {
      if (request) {
        const payload = {
          EmailAddress: atob(email),
        };
        const response = await resendUserOtp(payload);
        toastMessage("success", response);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
    },
  });

  return (
    <main className="flex items-center justify-center w-full gap-4 ">
      <section className="text-center">
        <div className="mt-20 mb-7">
          <h1 className="font-bold text-2xl mb-3">Enter O.T.P</h1>
          <p className="text-gray-500 text-sm md:text-base">
            Please enter OTP sent to your registered email and phone number
          </p>
        </div>

        <div className="flex justify-center">
          <OTPInput
            length={6}
            className="flex gap-5"
            inputClassName="w-8 h-9 outline-[#15803D] placeholder-gray-300 text-gray-600 font-semibold py-2 text-2xl text-center border border-gray-300 rounded-md focus:border border-gray-200 focus:ring-1"
            isNumberInput
            autoFocus
            onChangeOTP={(otp) => setOtp(otp)}
          />
        </div>
        {isPending || resendingOtp ? (
          <div className="flex justify-center items-center w-full h-14">
            <div className="animate-spin rounded-full h-11 w-11 border-t-2 border-b-4 border-gray-900"></div>
          </div>
        ) : (
          <div className="mt-5">
            <button
              onClick={verifyOtp}
              className={`w-[294px] h-[36px] rounded-lg ${
                disableButton || isPending
                  ? "bg-gray-400"
                  : "bg-[#15803D] hover:bg-emerald-900 transition ease-in-out duration-500"
              } text-[14px] text-white`}
              disabled={disableButton || isPending}
            >
              Continue
            </button>
          </div>
        )}

        <p
          className={` my-5 text-sm 
          ${!resendOTP ? "text-red-400" : "text-green-700"}
        `}
        >
          Haven't received OTP?{" "}
          <button
            onClick={callCesendOtp}
            className="text-[#15803D] font-bold cusor-pointer hover:text-emerald-900 transition ease-in-out duration-500"
            disabled={resendingOtp}
          >
            Resend OTP
          </button>
        </p>
      </section>
    </main>
  );
};

export default OtpFormComponent;
