import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import AgentAuth from "../../context/AgentContext/agentAuth";
import Select from "../../reusables/AgentsReuseables/SelectDropDown";
import AgentSearch from "../../reusables/AgentsReuseables/AgentSearch";
import Spinner from "../../reusables/AgentsReuseables/Spinner";

import barcode from "../../assets/barcode.svg";
import Button from "../../reusables/AgentsReuseables/addButton";
export default function RegistrationModal({ show, close, color = "emerald" }) {
  const data = [];
  const {
    agentState,
    modalCollection,
    setModalCollection,
    search,
    findMember,
    showSpinner,
    setShowSpinner,
  } = AgentAuth();

  const initialcollectionTrade = {
    matanId: "",
    firstName: "",
    middleName: "",
    amount: "",
    paymentMethode: "",
    lastName: "",
    tradeScale: "",
  };
  const initialcollectionHaulage = {
    matanId: "",
    firstName: "",
    middleName: "",
    amount: "",
    paymentMethode: "",
    lastName: "",
    haulageScale: "",
    pickupLocation: "",
    dropoffLocation: "",
  };

  const [collectionDetails, setcollectionDetails] = useState(
    initialcollectionTrade
  );
  const [haulageDetails, setHaulageDetails] = useState(
    initialcollectionHaulage
  );
  const [currentStep, setCurrentStep] = useState(0);

  const [openTab, setOpenTab] = useState(1);
  const [smallScreen, setSmallScreen] = useState(false);
  const [member, setMember] = useState({});

  const handlecollectionDetailsChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "amount":
        const parsedValue = parseFloat(value);
        setcollectionDetails((prevData) => ({
          ...prevData,
          [name]: !isNaN(parsedValue) && parsedValue >= 0 ? parsedValue : 0,
        }));
        break;

      default:
        setcollectionDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    }
  };

  const handleHaulageDetailsChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "amount":
        const parsedValue = parseFloat(value);
        setHaulageDetails((prevData) => ({
          ...prevData,
          [name]: !isNaN(parsedValue) && parsedValue >= 0 ? parsedValue : 0,
        }));
        break;

      default:
        setHaulageDetails((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onPrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();

    // setState((p) => ({ ...p, showOptions: !state.showOptions }));
  };

  const initialFormData = { lastName: "", firstName: "", email: "", role: "" };

  const handleRadioChange = (option) => {};

  useEffect(() => {
    if (modalCollection) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [modalCollection]);

  useEffect(() => {
    if (agentState.search) {
      setShowSpinner(true);
      const foundMember = data.find((item) =>
        item.matanId.toLowerCase().includes(agentState.search.toLowerCase())
      );
      setMember(foundMember);
    }
  }, [agentState.search]);

  return (
    <div>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none no-scrollbar"
        style={{
          width: "90%",
        }}
      >
        <div
          className="relative  my-auto mx-auto"
          style={{
            width: `${openTab === 3 || smallScreen ? "35%" : "90%"}`,
          }}
        >
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
              <h3 className="text-xl font-semibold">
                {openTab === 3 ? "Reciept" : "New Collection"}
              </h3>
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setModalCollection(false)}
              >
                <span className="bg-transparent text-green-900 opacity-100 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  <IoClose className="text-2xl" />
                </span>
              </button>
            </div>

            {/*body*/}
            <div className="flex flex-wrap">
              <div className="w-full">
                {openTab <= 2 && (
                  <ul
                    className="flex mb-1 list-none flex-wrap my-2 py-1 flex-row border-b border-gray-200 bg-gray-100 rounded-lg mx-4"
                    role="tablist"
                  >
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center mx-1">
                      <a
                        className={
                          "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal mx-1 " +
                          (openTab === 1
                            ? "text-green bg-" + color + "-600 bg-white"
                            : "text-" + color + "-600 ")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(1);
                        }}
                        data-toggle="tab"
                        href="#link1"
                        role="tablist"
                      >
                        Trader
                      </a>
                    </li>
                    <li className="-mb-px mr-2 last:mr-0 flex-auto text-center mx-1">
                      <a
                        className={
                          "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal mx-1 " +
                          (openTab === 2
                            ? "text-green bg-" + color + "-600 bg-white"
                            : "text-" + color + "-600 ")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          setOpenTab(2);
                        }}
                        data-toggle="tab"
                        href="#link2"
                        role="tablist"
                      >
                        Haulage
                      </a>
                    </li>

                    {openTab > 4 && (
                      <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                        <a
                          className={
                            "text-xs font-bold uppercase px-5 py-3  rounded block leading-normal " +
                            (openTab === 5
                              ? "text-green bg-" + color + "-600"
                              : "text-" + color + "-600 bg-white")
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setOpenTab(5);
                          }}
                          data-toggle="tab"
                          href="#link3"
                          role="tablist"
                        >
                          Confirm all provided Details
                        </a>
                      </li>
                    )}
                  </ul>
                )}
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-2  rounded">
                  <div className="px-4 py-5 flex-auto">
                    <div className="tab-content tab-space">
                      <div
                        className={openTab === 1 ? "block" : "hidden"}
                        id="link1"
                      >
                        {smallScreen ? (
                          <div className="w-ful" style={{ height: "50vh" }}>
                            <h1 className="font-bold mb-[-10px]">MATAN ID</h1>
                            <AgentSearch
                              placeholder="Enter Matan ID"
                              width="100%"
                              showIcon={false}
                            />
                            <div className="flex flex-col items-center justify-center mt-12">
                              {showSpinner && <Spinner showLoading={false} />}{" "}
                            </div>
                          </div>
                        ) : (
                          <div className=" mx-auto mt-1 p-5">
                            <form
                              onSubmit={handleSubmit}
                              className="w-full mx-2"
                            >
                              <div className="mb-4">
                                <label
                                  htmlFor="matanId"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  MATAN ID
                                </label>
                                <input
                                  type="matanId"
                                  id="matanId"
                                  name="matanId"
                                  value={collectionDetails.matanId}
                                  placeholder="Enter MATAN ID"
                                  onChange={handlecollectionDetailsChange}
                                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                  required
                                />
                              </div>
                              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                  <label
                                    htmlFor="firstName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={collectionDetails.firstName}
                                    onChange={handlecollectionDetailsChange}
                                    placeholder="Enter firstName"
                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                    required
                                    length="11"
                                  />
                                </div>

                                {/* Last Name */}
                                <div>
                                  <label
                                    htmlFor="middleName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Middle Name
                                  </label>
                                  <input
                                    type="text"
                                    id="middleName"
                                    name="middleName"
                                    value={collectionDetails.middleName}
                                    onChange={handlecollectionDetailsChange}
                                    placeholder="Enter Middle Name"
                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                    required
                                    length="11"
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor="lastName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={collectionDetails.lastName}
                                    onChange={handlecollectionDetailsChange}
                                    placeholder="Enter Middle Name"
                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                    required
                                    length="11"
                                  />
                                </div>

                                <div>
                                  <label
                                    htmlFor="businessAddressLGA"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Trade Scale
                                  </label>
                                  <Select
                                    options={["Small", "Medium", "Large"]}
                                    onClick={handleClick}
                                  />
                                </div>
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="amount"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Amount
                                </label>
                                <input
                                  type="amount"
                                  id="amount"
                                  name="amount"
                                  value={collectionDetails.amount}
                                  placeholder="Enter City"
                                  onChange={handlecollectionDetailsChange}
                                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                  required
                                />
                              </div>
                              <span className="">Payment Method</span>

                              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* First radio button */}
                                <div className="flex flex-row items-center justify-start">
                                  <input
                                    type="radio"
                                    id="radioOption1"
                                    name="radioGroup"
                                    value="option1"
                                    checked={
                                      collectionDetails.selectedOption ===
                                      "option1"
                                    }
                                    onChange={() =>
                                      handleRadioChange("option1")
                                    }
                                    className="mr-2"
                                  />
                                  <label htmlFor="radioOption1">
                                    Cash Deposit
                                  </label>
                                </div>

                                {/* Second radio button */}
                                <div className="flex flex-row items-center justify-start">
                                  <input
                                    type="radio"
                                    id="radioOption2"
                                    name="radioGroup"
                                    value="option2"
                                    checked={
                                      collectionDetails.selectedOption ===
                                      "option2"
                                    }
                                    onChange={() =>
                                      handleRadioChange("option2")
                                    }
                                    className="mr-2"
                                  />
                                  <label htmlFor="radioOption2">
                                    MATAN CARD
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                      <div
                        className={openTab === 2 ? "block" : "hidden"}
                        id="link2"
                      >
                        {smallScreen ? (
                          <div className="w-ful" style={{ height: "50vh" }}>
                            <h1 className="font-bold mb-[-10px]">MATAN ID</h1>
                            <AgentSearch
                              placeholder="Enter Matan ID"
                              width="100%"
                              showIcon={false}
                            />

                            <div className="flex flex-col items-center justify-center mt-12">
                              {showSpinner && <Spinner showLoading={false} />}{" "}
                            </div>
                          </div>
                        ) : (
                          <div className=" mx-auto mt-1 p-5">
                            <form
                              onSubmit={handleSubmit}
                              className="w-full mx-2"
                            >
                              <div className="mb-4">
                                <label
                                  htmlFor="matanId"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  MATAN ID
                                </label>
                                <input
                                  type="matanId"
                                  id="matanId"
                                  name="matanId"
                                  value={haulageDetails.matanId}
                                  placeholder="Enter MATAN ID"
                                  onChange={handleHaulageDetailsChange}
                                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                  required
                                />
                              </div>
                              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                  <label
                                    htmlFor="firstName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={haulageDetails.firstName}
                                    onChange={handleHaulageDetailsChange}
                                    placeholder="Enter firstName"
                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                    required
                                    length="11"
                                  />
                                </div>

                                {/* Last Name */}
                                <div>
                                  <label
                                    htmlFor="middleName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Middle Name
                                  </label>
                                  <input
                                    type="text"
                                    id="middleName"
                                    name="middleName"
                                    value={haulageDetails.middleName}
                                    onChange={handleHaulageDetailsChange}
                                    placeholder="Enter Middle Name"
                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                    required
                                    length="11"
                                  />
                                </div>
                                <div>
                                  <label
                                    htmlFor="lastName"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={haulageDetails.lastName}
                                    onChange={handleHaulageDetailsChange}
                                    placeholder="Enter Middle Name"
                                    className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                    required
                                    length="11"
                                  />
                                </div>

                                <div>
                                  <label
                                    htmlFor="businessAddressLGA"
                                    className="block text-sm font-medium text-gray-700"
                                  >
                                    Haulage Scale
                                  </label>
                                  <Select
                                    options={[
                                      "4 Tyres",
                                      "6 Tyres",
                                      "8 Tyres",
                                      "10 Tyres",
                                      "12 Tyres",
                                      "14 Tyres",
                                      "16 Tyres",
                                      "18 Tyres",
                                      "20 Tyres",
                                      "22 Tyres",
                                      "24 Tyres",
                                      "26 Tyres",
                                      "28 Tyres",
                                      "30 Tyres",
                                      "32 Tyres",
                                      "34 Tyres",
                                      "36 Tyres",
                                      "38 Tyres",
                                      "40 Tyres",
                                      "42 Tyres",
                                      "44 Tyres",
                                      "46 Tyres",
                                      "48 Tyres",
                                      "50 Tyres",
                                      "52 Tyres",
                                      "54 Tyres",
                                      "56 Tyres",
                                      "58 Tyres",
                                      "60 Tyres",
                                      "62 Tyres",
                                    ]}
                                    onClick={handleClick}
                                  />
                                </div>
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="pickupLocation"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Pick-Up Location
                                </label>
                                <input
                                  id="pickupLocation"
                                  name="pickupLocation"
                                  value={haulageDetails.pickupLocation}
                                  placeholder="42, Lagos Street Ikeja, Lagos State Nigeria"
                                  onChange={handleHaulageDetailsChange}
                                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                  required
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="dropoffLocation"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Drop-off Location{" "}
                                </label>
                                <input
                                  id="dropoffLocation"
                                  name="dropoffLocation"
                                  value={haulageDetails.dropoffLocation}
                                  placeholder="5, Abuja RoadKano, Kano StateNigeria"
                                  onChange={handleHaulageDetailsChange}
                                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                  required
                                />
                              </div>
                              <div className="mb-4">
                                <label
                                  htmlFor="amount"
                                  className="block text-sm font-medium text-gray-700"
                                >
                                  Amount
                                </label>
                                <input
                                  type="amount"
                                  id="amount"
                                  name="amount"
                                  value={collectionDetails.amount}
                                  placeholder="Enter City"
                                  onChange={handlecollectionDetailsChange}
                                  className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                                  required
                                />
                              </div>
                              <span className="">Payment Method</span>

                              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* First radio button */}
                                <div className="flex flex-row items-center justify-start">
                                  <input
                                    type="radio"
                                    id="radioOption1"
                                    name="radioGroup"
                                    value="option1"
                                    checked={
                                      haulageDetails.selectedOption ===
                                      "option1"
                                    }
                                    onChange={() =>
                                      handleRadioChange("option1")
                                    }
                                    className="mr-2"
                                  />
                                  <label htmlFor="radioOption1">
                                    Cash Deposit
                                  </label>
                                </div>

                                {/* Second radio button */}
                                <div className="flex flex-row items-center justify-start">
                                  <input
                                    type="radio"
                                    id="radioOption2"
                                    name="radioGroup"
                                    value="option2"
                                    checked={
                                      haulageDetails.selectedOption ===
                                      "option2"
                                    }
                                    onChange={() =>
                                      handleRadioChange("option2")
                                    }
                                    className="mr-2"
                                  />
                                  <label htmlFor="radioOption2">
                                    MATAN CARD
                                  </label>
                                </div>
                              </div>
                            </form>
                          </div>
                        )}
                      </div>
                      <div
                        className={openTab === 3 ? "block" : "hidden"}
                        id="link3"
                      >
                        <div className=" mx-auto mt-1 p-5">
                          <form onSubmit={handleSubmit} className="w-full mx-2">
                            <div className="flex flex-col lg:flex-row  items-center justify-center w-full">
                              <div
                                className={`border-1 rounded-full  p-3 inline-flex border-green-100 shadow-l ${"bg-green-100"}`}
                                data-aos="zoom-in"
                                data-aos-duration="800"
                                data-aos-delay="100"
                              >
                                <FaCheckCircle className="text-3xl text-green-700" />
                              </div>
                            </div>
                            <div className="mb-0  p-2 flex flex-col justify-center items-center w-full">
                              <p className="text-sm">Payment Success!</p>
                            </div>
                            <div className="mb-4  p-2 flex flex-col justify-center items-center w-full">
                              <p className="text-l font-bold">NGN 17,200.00</p>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                              <div>
                                <p className="text-xs">Ref Number!</p>
                              </div>

                              <div className="ml-auto">
                                <div className="ml-auto">
                                  <p className="text-xs font-bold">
                                    MATAN/LAGOS/562162.
                                  </p>
                                </div>
                              </div>
                              <div>
                                <div>
                                  <p className="text-xs">Payment Time</p>
                                </div>
                              </div>
                              <div className="ml-auto">
                                <p className="text-xs font-bold">
                                  25-02-2023, 13:22:16
                                </p>
                              </div>
                              <div>
                                <p className="text-xs">Payment Method </p>
                              </div>
                              <div className="ml-auto">
                                <p className="text-xs font-bold items-end justify-items-end">
                                  Bank Transfer{" "}
                                </p>
                              </div>
                              <div>
                                <p className="text-xs">Matan ID </p>
                              </div>
                              <div className="ml-auto">
                                <p className="text-xs font-bold items-end justify-items-end">
                                  Antonio Roberto{" "}
                                </p>
                              </div>
                              <div>
                                <p className="text-xs">Amount </p>
                              </div>
                              <div className="ml-auto">
                                <p className="text-xs font-bold items-end justify-items-end">
                                  NGN 1,000,000{" "}
                                </p>
                              </div>
                              <div>
                                <p className="text-xs">Payment for </p>
                              </div>
                              <div className="ml-auto">
                                <p className="text-xs font-bold items-end justify-items-end">
                                  IDR 193.00{" "}
                                </p>
                              </div>
                            </div>

                            <div className="flex flex-col justify-center items-center p-4">
                              <div className="flex flex-col items-center mr-4">
                                <Button
                                  title="Print Receipt"
                                  color="text-white"
                                  onClick={() => {
                                    setModalCollection(false);
                                  }}
                                />
                                <p className="text-xs mt-2">Print Receipt</p>
                              </div>
                              <div className="mt-2 mr-4">
                                <img
                                  src={barcode}
                                  alt="barcode"
                                  className="w-20 h-20"
                                />
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*footer*/}
            {openTab <= 2 && (
              <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                <button
                  className="text-slate-700 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 border border-solid border-slate-100 hover:bg-slate-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-3 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => {
                    if (currentStep > 0) {
                      setOpenTab((currentStep) => currentStep - 1);
                      onPrev();
                    } else {
                      setModalCollection(false);
                    }
                  }}
                >
                  Cancel
                </button>
                {openTab <= 4 && (
                  <button
                    className={`${
                      smallScreen
                        ? "bg-green-400 text-gray-100 cursor-not-allowed"
                        : "bg-emerald-700 text-white active:bg-green-600 hover:bg-emerald-800"
                    } font-bold uppercase text-sm px-6 py-2 rounded shadow outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150`}
                    type="button"
                    onClick={() => {
                      // if (currentStep === steps.length - 1) {
                      //   // handleSubmit();
                      //   setModalCollection(false);
                      // } else {
                      setOpenTab((openTab) => {
                        return openTab + 1;
                      });
                      // onNext();
                      // }
                    }}
                    disabled={smallScreen}
                  >
                    {"Create"}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
}
