import { Segment, Button, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
// import { observer } from "mobx-react-lite";
import React from "react";

const DuplicateTab = () => {
  return (
    <Segment style={{ marginTop: 200, cursor: "unset" }} placeholder>
      <Header icon>
        <Icon name="search" />
        Oops - Multiple Tabs not Supported. Tab already open on browser.
      </Header>
    </Segment>
  );
};

export default DuplicateTab;
