import Item from "./Item";
import cbnLogo from "../../../assets/cbnLogo.png";
import altBankLogo from "../../../assets/altBankLogo.png";

import { PRODUCTS, RESOURCES, COMPANY, SUPPORT } from "./Menus";
const ItemsContainer = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-5 lg:grid-cols-5 gap-6 sm:px-8 px-5 py-16">
      <div className="flex-block mr-5 sm:col-span-2 lg:col-span-2">
        <div className="flex my-2">
          <p> Licensed by CBN</p>
          <img alt="cbn logo" src={cbnLogo} className="ml-2 h-11" />
          <span className="text-gray-400 text-3xl mx-2"> | </span>
          Powered by
          <img alt="cbn logo" src={altBankLogo} className="ml-2 h-8" />
        </div>
      </div>

      <Item Links={PRODUCTS} title="MATAN" />
      {/* <Item Links={RESOURCES} title="Platform" /> */}
      <Item Links={COMPANY} title="Resources" />
      <Item Links={SUPPORT} title="Office" />
    </div>
  );
};

export default ItemsContainer;
