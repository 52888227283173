import * as yup from "yup";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;

export const basicDetails = yup.object().shape({
  email: yup.string().email("Please enter a valid email"),
  // email: yup.string().email("Please enter a valid email").required("Required"),
  age: yup.number().positive().integer().required("Required"),
  password: yup
    .string()
    .min(5)
    .matches(passwordRules, { message: "Please create a stronger password" })
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

export const userSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  nin: yup
    .string()
    .required("NIN is required")
    .length(11, "NIN must be 11 digits"),
  email: yup.string().email("Invalid email address"),
  lastName: yup.string().required("Last Name is required"),
  middleName: yup.string(),
  title: yup.string().required("Enter title"),
  phoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(/^\d{11}$/, "Phone Number must be exactly 11 digits"),
  bvn: yup
    .string()
    .required("BVN is required")
    .length(11, "BVN must be 11 digits"),
  tin: yup.string(),
  dateOfBirth: yup.string().required("Date of Birth is required"),
  gender: yup.string(),
  stateOfOrigin: yup.string(),
  occupation: yup.string().required("Occupation is required"),
  passport: yup.string(), // Added passport field
  signature: yup.string(), // Added signature field
  businessAddressNumber: yup
    .string()
    .required("Business Address Number is required"),
  businessState: yup.string().required("Business Address State is required"),
  businessLocalGovernment: yup
    .string()
    .required("Business Address LGA is required"),
  businessCity: yup.string().required("Business Address City is required"),
  businessAssociationName: yup.string(),
  // Added 4 modified field
  businessRegistrationNumber: yup.string(),
  businessName: yup.string().required("Business name is required"),
  marketName: yup.string().required("Market name is required"),
  marketAddress: yup.string().required("Market address is required"),

  businessCategoryOfService: yup.string(),
  businessSubCategory: yup.string(),
  NextOfKinName: yup.string().required("Name of Next of Kin is required"),
  nextofKinDateOfBirth: yup
    .string()
    .required("Next of Kin Date of Birth is required"),
  nextofKinPhoneNumber: yup
    .string()
    .required("Phone Number is required")
    .matches(/^\d{11}$/, "Phone Number must be exactly 11 digits"),
  nextofKinEmailAddress: yup.string().email("Invalid email address"),
  nextofKinHomeAddress: yup
    .string()
    .required("Next of Kin Address is required"),
});

export const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  middleName: yup.string(),
  title: yup.string(),
  role: yup.string(),
  phoneNumber: yup.string().required("Phone number is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  // selectedFile: Yup.mixed().required("Passport is required"),
});
export const nextofKinSchema = yup.object().shape({});

export const businessSchema = yup.object().shape({});

export const personalDetails = [
  "firstName",
  "nin",
  "email",
  "lastName",
  "middleName",
  "title",
  "phoneNumber",
  "bvn",
  // "tin",
  "dateOfBirth",
  "gender",
  "stateOfOrigin",
];

export const initialBusinessDetails = [
  "occupation",
  "passport",
  "signature",
  "businessAddressNumber",
  "businessState",
  "businessLocalGovernment",
  "businessCity",
  "businessAssociationName",
  "businessCategoryOfService",
  "businessSubCategory",
  "BranchCode",
  "businessName",
  "marketName",
  "marketAddress",
];

export const initialNextOfKinDetails = [
  "NextOfKinName",
  "nextofKinDateOfBirth",
  "nextofKinPhoneNumber",
  // "nextofKinEmailAddress",
  "nextofKinHomeAddress",
];
export const initialAgentDetails = [
  "firstName",
  "lastName",
  "middleName",
  "title",
  "phoneNumber",
  "email",
  "passport",
];

export const isFormNotEmpty = (specifiedFields, formData, errors) => {
  const hasErrors = specifiedFields.some(
    (field) => errors[field] !== undefined && errors[field] !== ""
  );

  const allFieldsFilled = specifiedFields.every(
    (field) => formData[field] && formData[field].trim() !== ""
  );

  return !hasErrors && allFieldsFilled;
};

export const businesSubCategory = [
  "",
  "Small Scale",
  "Medium Scale",
  "Large Scale",
];

export const businessCategory = ["", "Traders", "Haulage", "Others"];

export const initialState = {
  firstName: "",
  nin: "",
  email: "",
  lastName: "",
  middleName: "",
  title: "",
  phoneNumber: "",
  bvn: "",
  tin: "",
  dateOfBirth: "",
  gender: "",
  stateOfOrigin: "",
  occupation: "",
  passport: "",
  signature: "",
  businessAddressNumber: "",
  businessState: "",
  businessLocalGovernment: "",
  businessCity: "",
  businessAssociationName: "",
  businessCategoryOfService: "",
  businessSubCategory: "",
  NextOfKinName: "",
  nextofKinDateOfBirth: "",
  nextofKinPhoneNumber: "",
  nextofKinEmailAddress: "",
  nextofKinHomeAddress: "",
  BranchCode: "",
  businessRegistrationNumber: "",
  businessName: "",
  marketName: "",
  marketAddress: "",
};

export const businessAssociations = [
  "NURTW",
  "NIMASA",
  "NOSDRA",
  "NBA",
  "NARTO",
  "NUPENG",
];
