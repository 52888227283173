import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { encryptData, decryptData } from "../crypto/AdminCrypto";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_ADMIN_BASEURL;

const useAxios = () => {
  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens?.Token}` },
  });

  axiosInstance.interceptors.request.use(
    async (req) => {
      if (req.data) {
        req.data = encryptData(req.data);
      }

      const user = jwt_decode(authTokens.Token);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (!isExpired) return req;

      localStorage.removeItem("authTokens");
      localStorage.removeItem("userDetails");
      setUser(null);
      window.location.href = "/signIn";
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if (
        response.headers["content-type"] ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        return response;
      }
      const data = decryptData(response.data);
      return data;
    },
    (error) => {
      if (error.response && error.response.status === 403) {
        if (error.response.data == "Session is invalid.") {
          toast("You have an active session. Please log in again.", {
            type: "error",
          });
          localStorage.removeItem("authTokens");
          localStorage.removeItem("userDetails");
          setUser(null);

          window.location.href = "/signIn";
        }
      }
      let apiCallError = decryptData(error.response.data);
      toast(apiCallError?.Message, {
        type: "error",
      });
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const usePublicAxios = () => {
  const axiosInstance = axios.create({
    baseURL,
  });

  axiosInstance.interceptors.request.use(
    (req) => {
      if (req.data) {
        req.data = encryptData(req.data);
      }
      return req;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      if (
        response.headers["content-type"] ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        return response;
      }
      const data = decryptData(response.data);
      return data;
    },
    (error) => Promise.reject(error)
  );

  return axiosInstance;
};

export default useAxios;
