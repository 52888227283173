import React from "react";
import { useNavigate } from "react-router-dom";
import matanBackground from "../assets/matanBackground.svg";

import OtpForm from "../reusables/AgentsReuseables/OtpFormComponent";

const VerifyOtp = () => {
  const navigate = useNavigate();

  return (
    <main className="flex h-screen">
      <section className="w-1/2 flex flex-col relative">
        <div
          className="w-full flex-grow"
          style={{
            backgroundImage: `url(${matanBackground})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h4
            className="text-3xl text-center md:text-left text-white font-semibold absolute top-11 mb-4 ml-6 cursor-pointer"
            onClick={() => navigate("/")}
          >
            MATAN
          </h4>
          <section className="pt-8 md:mt-5 flex-grow mb-6 mx-6">
            <h3 className="text-xl text-center md:text-left text-white font-semibold absolute bottom-24 mb-4">
              Tax collection and enumeration have been simplified through the
              collaboration betwen MATAN and Alternative Bank.
            </h3>
          </section>
        </div>
        <div className="text-[12px] text-center md:mr-52 mt-3 text-gray-500">
          Powered by The Alternative Bank
        </div>
      </section>
      <section className="flex items-center w-1/2">
        <OtpForm />
      </section>
    </main>
  );
};

export default VerifyOtp;
