import React from "react";

function Card({ className = "bg-green-200", textTitle = "Filter", onClick }) {
  const handleClick = () => {
    onClick(textTitle);
  };

  return (
    <div className={`relative inline-block text-left mr-3 mt-6`}>
      <div>
        <span className={`rounded-md shadow-sm `}>
          <button
            type="button"
            className={`inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-2 ${className} text-xs leading-5 font-medium text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150`}
            id="options-menu"
            aria-haspopup="true"
            aria-expanded="true"
            onClick={handleClick}
          >
            {textTitle}
            <span className="ml-3 text-l">X</span>
          </button>
        </span>
      </div>
    </div>
  );
}

export default Card;
