import React from "react";
import { useState } from "react";
import OTPInput from "../OTP/OTPInput";

const AuthLogin = ({ verifyOtp, callCesendOtp }) => {
  const [otp, setOtp] = useState("");
  const [resendOTP, setResendOTP] = useState(false);
  const disableButton = otp.length <= 5 ? true : false;

  const submitOtp = (value) => {
    setOtp(value);
    verifyOtp(otp);
  };

  const handleResendOTP = () => {
    setResendOTP(true);
    callCesendOtp(true);
  };

  return (
    <main className="flex items-center justify-center ">
      <section className="text-center">
        <div className="mt-20 mb-7">
          <h1 className="font-bold text-2xl mb-3">MATAN BVN Verification</h1>
          <p className="text-gray-500 text-sm md:text-base">
            Please enter OTP sent to your registered email and phone number
          </p>
        </div>

        <div className="flex justify-center">
          <OTPInput
            length={6}
            className="flex gap-5"
            inputClassName="w-8 h-9 outline-[#15803D] placeholder-gray-300 text-gray-600 font-semibold py-2 text-2xl text-center border border-gray-300 rounded-md focus:border border-gray-200 focus:ring-1"
            isNumberInput
            autoFocus
            onChangeOTP={(otp) => setOtp(otp)}
          />
        </div>

        <div className="mt-5">
          <button
            onClick={submitOtp}
            className={`w-[294px] h-[36px] rounded-lg ${
              disableButton
                ? "bg-gray-400"
                : "bg-[#15803D] hover:bg-emerald-900 transition ease-in-out duration-500"
            } text-[14px] text-white`}
            disabled={disableButton}
          >
            Submit OTP
          </button>
        </div>
        <p
          className={` my-5 text-sm 
          ${!resendOTP ? "text-red-400" : "text-green-700"}
        `}
        >
          Haven't received OTP?{" "}
          <i
            onClick={handleResendOTP}
            className="text-[#15803D] hover:underline font-bold"
          >
            Resend OTP
          </i>
        </p>
      </section>
    </main>
  );
};

export default AuthLogin;
