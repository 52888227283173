import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import CustomDialog from "../layout/Modal";
import TextInput from "../input-fields/TextInput";
import AdminAuth from "../../../context/AdminContext/adminAuth";
import schemas from "../../Validation";

export default function AssociationDetails({
  onClose,
  open,
  item,
  action = "Create",
}) {
  const { newAssociationSchema } = schemas;
  const [showSpinner, setShowSpinner] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    resolver: yupResolver(newAssociationSchema),
    defaultValues: {
      ...item,
      status: item?.status === 1 ? "active" : "inactive",
    },
  });

  const {
    createAssociation,
    deleteAssociation,
    toastMessage,
    updateAssociation,
  } = AdminAuth();
  const queryClient = useQueryClient();

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["associations"] });
      onClose();
    },
    onError: (error) => {
      console.log(error);
      toastMessage("error", error.message);
    },
  };

  const { mutateAsync: createAssociationEntries, isLoading: isCreating } =
    useMutation({
      mutationFn: async (formData) => {
        setShowSpinner(true);
        await newAssociationSchema.validate(formData, { abortEarly: false });
        const response = await createAssociation({
          ...formData,
          status: formData.status === "active" ? 1 : 2,
        });
        if (response?.Message !== "Successful" || !response.Success) {
          throw new Error(response.Message);
          setShowSpinner(false);
        }
        setShowSpinner(false);
      },
      ...mutationOptions,
    });

  const { mutateAsync: updateAssociationEntries, isLoading: isUpdating } =
    useMutation({
      mutationFn: async (formData) => {
        setShowSpinner(true);
        await newAssociationSchema.validate(formData, { abortEarly: false });
        const response = await updateAssociation({
          Id: item.Id,
          Name: formData.Name,
          Description: formData.Description,
          status: formData.status === "active" ? 1 : 2,
        });
        if (!response && response.Success != true) {
          throw new Error(response);
          setShowSpinner(false);
        }
        toastMessage("success", "Association updated successfully");
        setShowSpinner(false);
      },
      ...mutationOptions,
    });

  const { mutateAsync: deleteAssociationEntry, isLoading: isDeleting } =
    useMutation({
      mutationFn: async () => {
        setShowSpinner(true);
        const response = await deleteAssociation({ Id: item.Id });
        if (response.Success !== true) {
          throw new Error("Couldn't delete association");
        }
        toastMessage("success", "Association deleted successfully");
        setShowSpinner(false);
      },
      ...mutationOptions,
    });

  const onSubmit = async (data) => {
    try {
      if (action === "Create") {
        await createAssociationEntries({ ...data });
      } else if (action === "Edit") {
        await updateAssociationEntries({ ...data });
      }
    } catch (validationErrors) {
      console.log("Validation errors:", validationErrors);
    }
  };

  const onDelete = async () => {
    try {
      await deleteAssociationEntry();
    } catch (error) {
      console.log("Delete error:", error);
    }
  };

  const hasError = Object.keys(errors).length > 0;

  useEffect(() => {
    if (item) {
      setValue("status", item.status === 1 ? "active" : "inactive");
    }
  }, [item, setValue]);

  return (
    <CustomDialog
      isOpen={open}
      handleClose={onClose}
      title={`${action} Association`}
      maxWidth="sm"
    >
      {action !== "Delete" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-scroll add-new-location">
            <TextInput
              label="Association Name"
              name="Name"
              type="text"
              placeholder="Enter Association Name"
              fieldName={register("Name")}
              errorMessage={errors.Name?.message}
            />
            <TextInput
              label="Association Description"
              name="Description"
              type="textArea"
              placeholder="Enter Association Description"
              fieldName={register("Description")}
              errorMessage={errors.Description?.message}
            />
            <div className="flex mt-4">
              <label className="mr-2">Status:</label>
              <label className="mr-4">
                <input
                  type="radio"
                  value="active"
                  {...register("status")}
                  defaultChecked={
                    item?.Status === 1 || item?.Status == "active"
                  }
                />
                Active
              </label>
              <label>
                <input
                  type="radio"
                  value="inactive"
                  {...register("status")}
                  defaultChecked={
                    item?.Status === 2 || item?.Status == "inactive"
                  }
                />
                Inactive
              </label>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-end items-center mt-10">
            <button
              className="btn red-outline font-medium w-full md:w-[150px]"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={`border text-white text-xs md:text-sm rounded py-1 px-2 ${
                !isValid || hasError
                  ? "bg-slate-300 opacity-50 cursor-not-allowed"
                  : "bg-gray-100 bg-green-600"
              }`}
              type="submit"
              disabled={
                !isValid || hasError || isCreating || isUpdating || showSpinner
              }
            >
              {isCreating || isUpdating || showSpinner
                ? "Loading..."
                : "Submit"}
            </button>
          </div>
        </form>
      ) : (
        <>
          <p>
            Are you sure you want to delete this Association? This action cannot
            be undone.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-end items-center mt-10">
            <button
              className="btn blue-outline font-medium w-full md:w-[150px] bg-blue-400 text-white rounded rounded-md py-2"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn green-outline font-medium w-full md:w-[150px] bg-red-500 text-white rounded rounded-md py-2"
              type="button"
              onClick={onDelete}
              disabled={isDeleting}
            >
              {isDeleting || showSpinner ? "Deleting..." : "Proceed"}
            </button>
          </div>
        </>
      )}
    </CustomDialog>
  );
}
