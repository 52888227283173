function convertKeysToCamelCase(obj) {
  const camelCasedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const camelKey = key.charAt(0).toLowerCase() + key.slice(1);
      camelCasedObj[camelKey] = obj[key];

      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        camelCasedObj[camelKey] = convertKeysToCamelCase(obj[key]);
      }
    }
  }

  return camelCasedObj;
}

export function convertKeysToPascalCase(obj) {
  const pascalCasedObj = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const pascalKey = key.charAt(0).toUpperCase() + key.slice(1);
      pascalCasedObj[pascalKey] = obj[key];

      if (
        typeof obj[key] === "object" &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        pascalCasedObj[pascalKey] = convertKeysToCamelCase(obj[key]);
      }
    }
  }

  return pascalCasedObj;
}

export default convertKeysToCamelCase;
