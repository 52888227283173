import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CustomDialog = ({
  isOpen,
  handleClose,
  title,
  subtitle,
  maxWidth = "md",
  children,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
      >
        <DialogTitle id="max-width-dialog-title">{title}</DialogTitle>
        <IconButton onClick={handleClose} color="inherit" size="large">
          <CloseIcon color="warning" />
        </IconButton>
      </Box>
      <DialogContent>
        {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;
