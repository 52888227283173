import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import CustomDialog from "../layout/Modal";
import TextInput from "../input-fields/TextInput";
import { useForm } from "react-hook-form";
import AdminAuth from "../../../context/AdminContext/adminAuth";
import schemas from "../../Validation";

export default function AssociationDetails({
  onClose,
  open,
  item,
  action = "Create",
}) {
  const { newAdminSchema } = schemas;
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger, // This is used to manually trigger validation
  } = useForm({
    resolver: yupResolver(newAdminSchema),
    defaultValues: {
      ...item,
      status: item?.status === 1 ? "active" : "inactive",
    },
  });

  const { createAdmin, toastMessage } = AdminAuth();
  const queryClient = useQueryClient();

  const mutationOptions = {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admins"] });
      onClose();
    },
    onError: (error) => {
      console.log(error);
      toastMessage("error", error.message);
    },
  };

  const { mutateAsync: createAdminEntries, isLoading } = useMutation({
    mutationFn: async (formData) => {
      await newAdminSchema.validate(formData, { abortEarly: false });
      const response = await createAdmin({
        ...formData,
        status: formData.status === "active" ? 1 : 2,
      });
      if (!response?.Succeeded) {
        toastMessage("error", response?.Message);
        // throw new Error(response?.Message);
        return;
      }
      toastMessage("success", "MATAN Admin added successfully");
    },
    ...mutationOptions,
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      if (action === "Create") {
        await createAdminEntries({ ...data });
      }
      setLoading(false);
    } catch (validationErrors) {
      console.log("Validation errors:", validationErrors);
      setLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      // Add delete functionality if needed
    } catch (error) {
      console.log("Delete error:", error);
    }
  };

  const hasError = Object.keys(errors).length > 0;

  useEffect(() => {
    if (item) {
      setValue("status", item.status === 1 ? "active" : "inactive");
    }
  }, [item, setValue]);

  return (
    <CustomDialog
      isOpen={open}
      handleClose={onClose}
      title={`${action} Admin`}
      maxWidth="sm"
    >
      {action !== "Delete" ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-scroll add-new-location">
            <TextInput
              label="First Name"
              name="FirstName"
              type="text"
              placeholder="Enter First Name"
              fieldName={register("FirstName", {
                onBlur: () => trigger("FirstName"),
              })}
              errorMessage={errors.FirstName?.message}
            />
            <TextInput
              label="Last Name"
              name="LastName"
              type="text"
              placeholder="Enter Last Name"
              fieldName={register("LastName", {
                onBlur: () => trigger("LastName"),
              })}
              errorMessage={errors.LastName?.message}
            />
            <TextInput
              label="Phone Number"
              name="PhoneNumber"
              type="text"
              placeholder="Enter Phone Number"
              fieldName={register("PhoneNumber", {
                onBlur: () => trigger("PhoneNumber"),
              })}
              errorMessage={errors.PhoneNumber?.message}
            />
            <TextInput
              label="Email"
              name="Email"
              type="email"
              placeholder="Enter Email"
              fieldName={register("Email", { onBlur: () => trigger("Email") })}
              errorMessage={errors.Email?.message}
            />
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-end items-center mt-10">
            <button
              className="btn red-outline font-medium w-full md:w-[150px]"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className={`border text-white text-xs md:text-sm rounded py-1 px-2 ${
                !isValid || hasError
                  ? "bg-slate-300 opacity-50 cursor-not-allowed"
                  : "bg-gray-100 bg-green-600"
              }`}
              type="submit"
              disabled={!isValid || hasError || isLoading || loading}
            >
              {isLoading || loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </form>
      ) : (
        <>
          <p>
            Are you sure you want to delete this Admin? This action cannot be
            undone.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-end items-center mt-10">
            <button
              className="btn blue-outline font-medium w-full md:w-[150px] bg-blue-400 text-white rounded rounded-md py-2"
              type="button"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="btn green-outline font-medium w-full md:w-[150px] bg-red-500 text-white rounded rounded-md py-2"
              type="button"
              onClick={onDelete}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Proceed"}
            </button>
          </div>
        </>
      )}
    </CustomDialog>
  );
}
