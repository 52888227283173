import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaXmark } from "react-icons/fa6";
import AuthContext from "../../context/AuthContext";
import ImageAvatars from "./NavBarAvata";
const HeaderNavbar = () => {
  const { user } = useContext(AuthContext);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const Links = [
    { name: "HOME", link: "/" },
    { name: "SERVICES", link: "/services" },
    { name: "ABOUT US", link: "/about" },
  ];

  return (
    <div className="shadow-md w-screen top-2 left-0 fixed bg-black ">
      <div className="md:flex items-center justify-between py-4 md:px-10 px-7">
        <div className="font-bold text-2xl cursor-pointer flex items-center gap-1">
          <span className="text-white">MATAN</span>
        </div>
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7"
        >
          {open ? (
            <FaXmark className="text-white text-3xl" />
          ) : (
            <GiHamburgerMenu className="text-white text-3xl" />
          )}
        </div>
        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-12" : "top-[-490px]"
          }`}
        >
          {Links.map((link, index) => (
            <li
              key={index}
              className="md:ml-8 md:my-0 my-7 font-semibold border border-transparent transition duration-300 hover:border-white"
              onClick={() => navigate(link.link)}
            >
              <button className="text-white hover:text-blue-400 duration-500 ">
                {link.name}
              </button>
            </li>
          ))}
          {user ? (
            <ImageAvatars />
          ) : (
            <>
              <li className="md:ml-8 md:my-0 my-7 font-semibold">
                <button
                  className="btn text-white md:ml-8 font-semibold px-3 py-1 rounded duration-500 md:static"
                  onClick={() => navigate("/signin")}
                >
                  Sign In
                </button>
              </li>
              <li className="md:ml-8 md:my-0 my-7 font-semibold">
                <button
                  className="btn bg-green-600 text-white md:ml-8 font-semibold px-3 py-1 rounded duration-500 md:static"
                  onClick={() => navigate("/register")}
                >
                  Register
                </button>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default HeaderNavbar;
