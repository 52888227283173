import React, { useState, useMemo, useEffect } from "react";
import { MantineProvider } from "@mantine/core";

import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import { GoDotFill } from "react-icons/go";
import { LuEye } from "react-icons/lu";

import { visuallyHidden } from "@mui/utils";
import AgentSearch from "./AgentSearch";
import FilterDropdown from "./Agentfilter";
import formatDate from "../dateConverter";
import AgentAuth from "../../context/AgentContext/agentAuth";
import LoadingSpinner from "../spinner";
import SelectDateRange from "../dateRange";
import Card from "./Card";

const options = [
  { id: 1, label: "Haulage" },
  { id: 2, label: "Traders" },
  { id: 3, label: "Active" },
  { id: 4, label: "Inactive" },
];

const taxStatuses = ["Successful", "Failed", "Pending"];
const paymentMethode = [
  "Cash",
  "MATAN CARD",
  "MATAN ID",
  "Bank Transfer",
  "QR CODE",
];

const headCells = [
  {
    id: "MatanID",
    numeric: false,
    disablePadding: true,
    label: "Matan ID",
  },
  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "TaxID",
    numeric: true,
    disablePadding: false,
    label: "Tax ID ",
  },
  {
    id: "DateTime",
    numeric: true,
    disablePadding: false,
    label: "Date & Time",
  },
  {
    id: "CreatedAt",
    numeric: true,
    disablePadding: false,
    label: "Created At",
  },
  {
    id: "Amount",
    numeric: true,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "VAT",
    numeric: true,
    disablePadding: false,
    label: "VAT",
  },
  {
    id: "PaymentMethod",
    numeric: true,
    disablePadding: false,
    label: "Payment Method",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    // onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className="bg-gray-100">
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const [filteredData, setFilteredData] = useState([]);
  const { numSelected } = props;
  const handleSelectionChange = (selectedItems) => {
    // console.log("Selected items:", selectedItems);
    setFilteredData(selectedItems);
  };

  const removedClickedFilter = (item) => {
    const filterItem = filteredData.filter((filterItem) => filterItem !== item);
    setFilteredData(filterItem);
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
      className="mb-4"
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          <AgentSearch
            placeholder="Search by Matan ID, Tax ID & Name"
            location="taxCollection"
          />
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <div
          className="flex flex-row mr-1 justify-end"
          style={{ width: "70%", height: "100%" }}
        >
          {filteredData.map((item, index) => {
            return (
              <div key={index}>
                <Card
                  textTitle={item}
                  onClick={() => removedClickedFilter(item)}
                />
              </div>
            );
          })}
          <FilterDropdown
            title="Filter"
            className="bg-green-200"
            options={options}
            onSelectionChange={handleSelectionChange}
          />
        </div>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ tableChange, allTaxCollection }) {
  const {
    agentState,
    setMemberDetailModal,
    setCurrentMember,
    setModalOpen,
    setAgentAction,
    showSpinner,
    allMembers,
    totalMembers,
    setTotalMembers,
  } = AgentAuth();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("DateCreated");
  const [selected, setSelected] = useState([]);
  const [dense, setDense] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, CreatedAt) => {
    const isAsc = orderBy === CreatedAt && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(CreatedAt);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = allMembers.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    tableChange({
      page,
      rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    tableChange({
      page,
      rowsPerPage,
    });
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalMembers) : 0;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer className="min-h-[90vh] max-h-[98vh] overflow-y-auto no-scrollbar">
          <Table
            // sx={{ minWidth: 'auto' }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={totalMembers}
            />
            {allTaxCollection.length < 1 && showSpinner ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <LoadingSpinner />
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              <TableBody>
                {allTaxCollection.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        className="p-2 font-small text-gray-900 whitespace-nowrap text-[12px]"
                      >
                        <p className="text-[12px] mb-1 text-left font-semibold">
                          {row?.MatanId || "MATAN/LAGOS/561236"}
                        </p>
                        <p className="text-gray-500 text-left tracking-wider">
                          {`${row?.LastName || "Musa"} ${
                            row?.FirstName || "Chiroma"
                          }`}
                        </p>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="font-small text-gray-900 whitespace-nowrap transition duration-300 ease-in-out"
                      >
                        <span className="flex flex-row items-center justify-left">
                          <LuEye className="text-[22px] text-gray-500 mr-2" />
                        </span>
                      </TableCell>
                      <TableCell align="left">{row?.TaxId || "N/A"}</TableCell>
                      <TableCell align="left">
                        {formatDate(
                          row?.DateCreated ||
                            new Date().toISOString().slice(0, 10)
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        className="p-2 font-small text-gray-900 whitespace-nowrap"
                      >
                        {row?.Amount || 0}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="p-2 font-small text-gray-900 whitespace-nowrap"
                      >
                        {row?.VAT || 0}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="p-2 font-small text-gray-900 whitespace-nowrap"
                      >
                        {paymentMethode[row?.PaymentMethode] || "Cash"}{" "}
                      </TableCell>
                      <TableCell align="left">
                        <div className="flex flex-row items-center border-1 rounded-lg p-1 justify-start">
                          <span
                            className={`flex items-center rounded-lg p-0 ${
                              taxStatuses[row?.Status] === "Failed"
                                ? "text-red-500 bg-red-100"
                                : taxStatuses[row?.Status] === "Pending"
                                ? "text-yellow-500 bg-yellow-100"
                                : "text-green-600 bg-green-100"
                            }`}
                          >
                            <GoDotFill
                              className={`text-[20px] mr-1 ${
                                taxStatuses[row?.Status] === "Failed"
                                  ? "text-red-500"
                                  : taxStatuses[row?.Status] === "Pending"
                                  ? "text-yellow-500"
                                  : "text-green-600"
                              }`}
                            />
                            {taxStatuses[row?.Status]}
                          </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={totalMembers}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
