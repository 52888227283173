import React, { useState, useContext, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { GoGear } from "react-icons/go";
import { SlPeople } from "react-icons/sl";
import { PiBriefcaseThin } from "react-icons/pi";
import { MdOutlineSpaceDashboard, MdOutlinePersonPin } from "react-icons/md";
import AuthContext from "../context/AuthContext";
import AdminAuth from "../context/AdminContext/adminAuth";
import { useQuery } from "@tanstack/react-query";
import { IoIosPeople } from "react-icons/io";
import { imageObj } from "../icon";
import { LuLogOut } from "react-icons/lu";

const DashSideNav = ({ currentLink = "dashboard", setAllMembersRecords }) => {
  const [activeLink, setActiveLink] = useState(currentLink);
  const [totalOnboardedMembers, setTotalOnboardedMembers] = useState(0);

  const { user, logOut, setUser } = useContext(AuthContext);
  const { listMembers } = AdminAuth();
  const { pathname } = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("authTokens");
    localStorage.removeItem("userDetails");
    logOut();
    setUser(null);
  };

  const fetchMembers = async () => {
    const response = await listMembers(`pageIndex=${1}&pageSize=${1000}`);
    setTotalOnboardedMembers(response.Data.TotalCount);
    return response.TotalCount;
  };

  const userRole = user
    ? user[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ].toLowerCase()
    : "admin";
  const userEmail = user
    ? user["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"]
    : "";

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["members"],
    queryFn: fetchMembers,
    staleTime: 5000,
    cacheTime: 0,
  });

  useEffect(() => {
    if (data !== undefined) {
      setAllMembersRecords(totalOnboardedMembers);
    }
  }, [data, setAllMembersRecords]);

  return (
    <div className="h-full bg-white-800 text-black p-4 border overflow-x-hidden border-r-2">
      <div className="flex flex-col items-start">
        <div className="flex mb-4">
          <img src={imageObj.MATANlogo} className="w-7 h-7" alt="" />
          <h1 className="text-sm text-gray-600 font-semibold mt-[0px] ml-2">
            MATAN
          </h1>
        </div>

        <div className="flex flex-col items-start p-2 w-full">
          <Link
            to={userRole === "admin" ? "/dashboardAdmin" : "/dashboardAgent"}
            className={`flex items-center text-sm mt-4 w-[180px] py-1 ${
              pathname.includes("dashboard")
                ? "text-green-700 font-bold bg-green-100 rounded-sm "
                : ""
            }`}
            onClick={() => setActiveLink("dashboard")}
          >
            <MdOutlineSpaceDashboard
              className={`text-sm mr-2${
                pathname.includes("dashboard")
                  ? "text-green-700 mr-3 hover:font-bold rounded-sm bg-green-100"
                  : ""
              }`}
            />
            Dashboard
          </Link>
          <Link
            to={userRole === "admin" ? "/collection" : "/collectionAgent"}
            className={`flex items-center text-sm mt-4 w-[180px] py-1 ${
              pathname.includes("collection")
                ? "text-green-700 font-bold  bg-green-100 rounded-sm "
                : ""
            }`}
          >
            <PiBriefcaseThin
              className={`text-xl mr-2 ${
                pathname.includes("collection")
                  ? "text-green-700 font-bold bg-green-100 rounded-sm "
                  : ""
              }`}
            />
            Tax Collection
          </Link>
          <Link
            to={userRole === "admin" ? "/membersAdmin" : "/membersAgent"}
            className={`flex items-center text-sm mt-4 w-[180px] py-1 ${
              pathname.includes("members")
                ? "text-green-700 font-bold bg-green-100 rounded-sm "
                : ""
            }`}
          >
            <SlPeople className="text-xl mr-2" />
            Members
          </Link>
          {userRole === "admin" && (
            <>
              <Link
                to="/association"
                className={`flex items-center text-sm mt-4 w-[180px] py-1 ${
                  pathname.includes("association")
                    ? "text-green-700 font-bold bg-green-100 rounded-sm "
                    : ""
                }`}
              >
                <IoIosPeople className="text-xl mr-2" />
                Association
              </Link>
              <Link
                to="/agentAdmin"
                className={`flex items-center text-sm mt-4 w-[180px] py-1 ${
                  pathname.includes("agent")
                    ? "text-green-700 font-bold bg-green-100 rounded-sm "
                    : ""
                }`}
              >
                <MdOutlinePersonPin className="text-xl mr-2" />
                Agent
              </Link>
            </>
          )}
          {userRole === "superadmin" && (
            <Link
              to="/matanAdmin"
              className={`flex items-center text-sm mt-4 w-[180px] py-1 ${
                pathname.includes("matanAdmin")
                  ? "text-green-700 font-bold bg-green-100 rounded-sm "
                  : ""
              }`}
            >
              <MdOutlinePersonPin className="text-xl mr-2" />
              MATAN Admin
            </Link>
          )}
        </div>
        <div className="flex flex-col items-start mt-[200px]">
          <Link
            to="/profileSetting"
            className={`flex items-center font-semibold text-sm w-[180px] py-1 ${
              pathname.includes("profileSetting")
                ? "text-green-700 font-bold bg-green-100 rounded-sm "
                : ""
            }`}
          >
            <GoGear
              className={`text-xl mr-4${
                pathname.includes("profileSetting")
                  ? "text-green-700 text-2xl bg-green-100"
                  : ""
              }`}
            />
            Settings
          </Link>
          <hr className="mt-6 mb-1 border-t border-gray-200 w-[200px]" />
          <div className="flex mt-3 items-center">
            <img src={imageObj.Avatar} alt="" className="w-9 h-9" />
            <div className="text-sm ml-3">
              <p className="text-gray-900 font-semibold">
                {userEmail.split("@")[0] || "MATAN"}
              </p>
              <p className="text-gray-600">{userEmail}</p>
              <button onClick={handleLogout} className="ml-3">
                <LuLogOut className="hover:text-green-700 transition ease-in-out duration-500 text-gray-600 w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashSideNav;
