import React from "react";
import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";
import { FaLinkedinIn } from "react-icons/fa";
import { SlSocialFacebook } from "react-icons/sl";
import { IoLogoGoogle } from "react-icons/io";

const SocialIcons = () => {
  const Icons = [
    { name: "TiSocialTwitter", icon: <TiSocialTwitter /> },
    { name: "TiSocialYoutube", icon: <TiSocialYoutube /> },
    { name: "SlSocialLinkedin", icon: <FaLinkedinIn /> },
    { name: "SlSocialFacebook", icon: <SlSocialFacebook /> },
    { name: "IoLogoGoogle", icon: <IoLogoGoogle /> },
  ];
  return (
    <div className="text-teal-500">
      {Icons.map((icon) => (
        <span
          key={icon.name}
          className="p-2 cursor-pointer inline-flex items-center
        rounded-full bg-green-700 mx-1.5 text-xl hover:text-gray-100 hover:bg-teal-500
        duration-300 text-white "
        >
          {icon.icon}
        </span>
      ))}
    </div>
  );
};

export default SocialIcons;
