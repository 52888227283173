import { createContext, useMemo, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActionTypes from "./actionTypes";
import api from "../../reusables/Api/AgentApiInterceptor";
import { encryptData } from "../../reusables/crypto/AgentCrypto";

export const AuthContext = createContext();

const initialState = {
  loggedIn: false,
  agentAccount: {},
  loading: false,
  member: {},
  searchedInput: "",
  filter: [],
};

function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.SIGNUP:
    case ActionTypes.LOGIN:
    case ActionTypes.VALIDATE_OTP:
    case ActionTypes.LIST_MEMBERS:
    case ActionTypes.GET_ALL_TAX_COLLECTION:
    case ActionTypes.GETMEMBER:
    case ActionTypes.GET_MEMBER_ACCOUNT:
    case ActionTypes.VERIFYUSEROTP:
    case ActionTypes.GET_ALL_BRANCHES:
    case ActionTypes.RESENDUSEROTP:
      return {
        ...state,
        loggedIn: false,
        user: action.payload,
        loading: action?.payload?.loading || true,
      };

    case ActionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        agentAccount: {},
        loading: false,
        member: {},
        searchedInput: "",
        filteredItems: [],
      };

    case ActionTypes.SEARCH:
    case ActionTypes.FILTER:
    case ActionTypes.REGISTERMEMBER:
      return {
        ...state,
        [action.type.toLowerCase()]: action.payload,
        loading: false,
      };
    case ActionTypes.FINDMEMBER:
    case ActionTypes.VALIDATEBVN:
    case ActionTypes.CREATEMEMBER:
      return {
        ...state,
        member: action.payload,
        loading: false,
      };

    default:
      throw new Error();
  }
}

export default reducer;

export const AgentProvider = ({ children }) => {
  const [agentState, dispatch] = useReducer(reducer, initialState);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCollection, setModalCollection] = useState(false);
  const [memberDetailModal, setMemberDetailModal] = useState(false);
  const [agentAction, setAgentAction] = useState("newRegistration");
  const [allMembers, setAllMembers] = useState([]);
  const [allTaxCollection, setAllTaskCOllections] = useState([]);
  const [currentMember, setCurrentMember] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [activeLink, setActiveLink] = useState("dashboard");
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalCollections, settotalCollections] = useState(0);
  const toastMessage = (type, message) => {
    toast[type](message, { autoClose: 3000 });
  };

  const createAsyncAction =
    (type, url, method = "post") =>
    async (payload) => {
      try {
        let response;
        const config = {
          headers: {
            ApiKey: ` ${process.env.REACT_APP_API_KEY}`,
          },
        };

        switch (method.toLowerCase()) {
          case "get":
            const queryValue = encryptData(payload) || "";
            response = await api.get(url, {
              ...config,
              params: { dataValue: queryValue.dataValue },
            });
            return response;
          case "post":
            response = await api.post(url, payload, config);
            return response;
          case "put":
            response = await api.put(url, payload, config);
            return response;
          case "patch":
            response = await api.patch(url, payload, config);
            return response;
          case "delete":
            response = await api.delete(url, config);
            return response;
          default:
            // throw new Error(`Unsupported HTTP method: ${method}`);
            return;
        }
      } catch (error) {
        dispatch({ type, payload: null, loading: false });
        console.error(`Error in action ${type}:`, error.message);
        // toastMessage("error", `Error in ${type}: ${error.message}`);
      }
    };

  const actions = {
    signup: createAsyncAction(ActionTypes.SIGNUP, "/api/signup"),
    verifyUserOtp: createAsyncAction(
      ActionTypes.VERIFYUSEROTP,
      "/api/Onboarding/verifyotp"
    ),
    resendUserOtp: createAsyncAction(
      ActionTypes.RESENDUSEROTP,
      "/api/Onboarding/resendotp"
    ),
    createMember: createAsyncAction(
      ActionTypes.CREATEMEMBER,
      "/api/Onboarding/member"
    ),
    validateOtp: createAsyncAction(ActionTypes.VALIDATE_OTP, "/api/validate"),
    getMember: createAsyncAction(ActionTypes.GETMEMBER, "/api/get"),
    listMembers: createAsyncAction(
      ActionTypes.LIST_MEMBERS,
      `/api/Onboarding/members`,
      "get"
    ),
    getMemberAccount: createAsyncAction(
      ActionTypes.GET_MEMBER_ACCOUNT,
      "/api/id"
    ),
    searchMember: createAsyncAction(
      ActionTypes.SEARCH,
      "/api/Onboarding/searchmember",
      "get"
    ),
    searchTaxCollection: createAsyncAction(
      ActionTypes.SEARCH,
      "/api/Onboarding/searchcollection",
      "get"
    ),
    filter: createAsyncAction(ActionTypes.FILTER, "/api/filter", "try"),
    registerMember: createAsyncAction(
      ActionTypes.REGISTERMEMBER,
      "/api/members"
    ),
    findMember: createAsyncAction(ActionTypes.FINDMEMBER, "/api/members"),
    validateBVN: createAsyncAction(
      ActionTypes.VALIDATEBVN,
      "/api/Onboarding/verifybvn"
    ),
    getAllTaxCollection: createAsyncAction(
      ActionTypes.GET_ALL_TAX_COLLECTION,
      "/api/Onboarding/taxcollections",
      "get"
    ),
    getAllBranches: createAsyncAction(
      ActionTypes.GET_ALL_BRANCHES,
      "/api/Onboarding/branches",
      "get"
    ),
  };

  const value = useMemo(
    () => ({ ...actions, toastMessage }),
    [actions, toastMessage]
  );

  return (
    <AuthContext.Provider
      value={{
        agentState,
        modalOpen,
        setModalOpen,
        modalCollection,
        setModalCollection,
        activeLink,
        setActiveLink,
        showSpinner,
        setShowSpinner,
        memberDetailModal,
        setMemberDetailModal,
        currentMember,
        setCurrentMember,
        agentAction,
        setAgentAction,
        allMembers,
        setAllMembers,
        allTaxCollection,
        setAllTaskCOllections,
        totalMembers,
        setTotalMembers,
        totalCollections,
        settotalCollections,
        ...value,
      }}
    >
      <ToastContainer position="top-right" autoClose={2000} />
      {children}
    </AuthContext.Provider>
  );
};
