import React, { useState, useRef, useEffect } from "react";
import { CiFilter } from "react-icons/ci";
import AgentAuth from "../../context/AgentContext/agentAuth";

const CheckboxOption = ({ option, isSelected, onOptionChange }) => (
  <div className="flex items-center space-x-2">
    <input
      type="checkbox"
      id={`option-${option.label}`}
      checked={isSelected}
      onChange={() => onOptionChange(option.label)}
      className="form-checkbox h-4 w-4 text-blue-500"
    />
    <label
      htmlFor={`option-${option.label}`}
      className="font-medium text-gray-700 m-3"
    >
      {option.label}
    </label>
  </div>
);

const FilterDropdown = ({
  className,
  options,
  title = "Filter",
  location = "general",
  onSelectionChange,
}) => {
  const { agentState, filter } = AgentAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef();

  const handleOptionChange = (optionLabel) => {
    setSelectedOptions((prevOptions) => {
      if (prevOptions.includes(optionLabel)) {
        return prevOptions.filter((label) => label !== optionLabel);
      } else {
        return [...prevOptions, optionLabel];
      }
    });
  };

  const handleToggle = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectedOptions);
    }
  }, [selectedOptions, onSelectionChange]);

  const handleClearClick = () => {
    filter([]);
    setSelectedOptions([]);
    setIsOpen(false);
  };

  const handleFilterClick = () => {
    const filteredLabels = options
      .filter((option) => selectedOptions.includes(option.label))
      .map((option) => option.label.toLowerCase());

    filter(filteredLabels);
    setIsOpen(false);
  };

  return (
    <div className={`relative inline-block text-left mt-6`} ref={dropdownRef}>
      <div>
        <span className={`rounded-md shadow-sm `}>
          <button
            type="button"
            className={`inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 ${className} text-sm leading-5 font-medium text-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition ease-in-out duration-150`}
            id="options-menu"
            aria-haspopup="true"
            aria-expanded={isOpen}
            onClick={handleToggle}
          >
            <CiFilter className="text-2xl" />
            {title}
          </button>
        </span>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg z-50">
          <div className="rounded-md bg-white shadow-xs p-2">
            <div className="py-1">
              {options.map((option) => (
                <CheckboxOption
                  key={option.label}
                  option={option}
                  isSelected={selectedOptions.includes(option.label)}
                  onOptionChange={handleOptionChange}
                />
              ))}
            </div>
            <div className="flex justify-between px-4 py-2 mt-4 border-t">
              <button
                type="button"
                className="text-green-600 hover:text-red-700 mt-2"
                onClick={handleClearClick}
              >
                Clear
              </button>
              <button
                type="button"
                className="text-white hover:text-white-700 bg-green-700 mt-2
                  hover:bg-green-800 rounded-lg px-4 py-1"
                onClick={handleFilterClick}
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterDropdown;
