import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { RiFolderDownloadLine } from "react-icons/ri";
import { IoIosAdd } from "react-icons/io";
import { CiImport } from "react-icons/ci";
import AdminAuth from "../context/AdminContext/adminAuth";

function DashboardNav({
  totalAgent = 0,
  openBulkModal,
  openModal,
  location = "agentTable",
  clickExportClasses,
  handleExportClick,
  totalOnboarded = 0,
  title,
  subTitle,
  showButton = true,
}) {
  const queryClient = useQueryClient();
  const { downloadMemberRecords } = AdminAuth();

  const handleExportDownload = async () => {
    handleExportClick("Export");
    if (title == "Members") await downloadMemberRecords();
  };

  return (
    <div
      className="hidden sm:flex flex flex-row w-full bg-white m-3 p-1"
      style={{
        height: "12vh",
      }}
    >
      {location === "agentTable" ? (
        <>
          <div>
            <div className="flex">
              <h2 className="text-2xl font-semibold">User Profiling</h2>
              <p className="bg-gray-100 text-[12px] ml-3 tracking-wider items-center h-[3vh] font-semibold px-2 rounded-lg mt-2">
                {totalAgent}
              </p>
            </div>
            <p className="text-xs text-gray-500">View/Create Agent</p>
          </div>
          <div className="ml-auto">
            <div className="flex mr-0">
              <button
                onClick={openBulkModal}
                className="flex text-sm border-2 mr-2 shadow-sm px-3 py-2 rounded"
              >
                <RiFolderDownloadLine className="mt-1 mr-2" />
                <p className="">Bulk Add</p>
              </button>
              <button
                onClick={openModal}
                className="flex text-sm border-2 text-white bg-green-700 shadow-sm px-3 py-2 rounded"
              >
                <IoIosAdd className="mt-1 mr-1" />
                <p className=" ">New Agent</p>
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="flex pt-4 w-full">
          <div>
            <div className="flex">
              <h2 className="text-2xl font-semibold">{title || "Members"} </h2>
              {showButton && (
                <p className="bg-gray-100 text-[12px] ml-3 tracking-wider items-center h-[3vh] font-semibold px-2 rounded-lg mt-3">
                  {totalOnboarded || 0}
                </p>
              )}
            </div>
            <p className="text-xs text-gray-500">
              {subTitle || "View all members in the platform"}
            </p>
          </div>
          {showButton && (
            <div className="ml-auto bg-white">
              <button
                onClick={handleExportDownload}
                className={clickExportClasses("Export")}
              >
                <div className="flex mr-3 border-2 shadow-sm px-3 py-1 rounded-lg">
                  <CiImport className="mt-1 mr-2" />
                  <p>Export</p>
                </div>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DashboardNav;
