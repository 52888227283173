const ActionTypes = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  VALIDATE_OTP: "VALIDATE_OTP",
  GETMEMBER: "GETMEMBER",
  REGISTERMEMBER: "REGISTERMEMBER",
  LIST_MEMBERS: "LIST_MEMBERS",
  LIST_AGENTS: "LIST_AGENTS",
  LOGOUT: "LOGOUT",
  GET_MEMBER_ACCOUNT: "GET_MEMBER_ACCOUNT",
  SEARCH: "SEARCH",
  FILTER: "FILTER",
  FINDMEMBER: "FINDMEMBER",
  VALIDATEBVN: "VALIDATEBVN",
  CREATEMEMBER: "CREATEMEMBER",
  GET_ALL_TAX_COLLECTION: "GET_ALL_TAX_COLLECTION",
  EDIT_AGENT: "EDIT_AGENT",
  CREATE_AGENT: "CREATE_AGENT",
  CHANGEPASSWORD: "CHANGEPASSWORD",
};

export default ActionTypes;
