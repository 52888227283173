import { useEffect, useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import PrivateRoute from "./Agent/private/PrivateRoutes";
import DashboardAdmin from "./Dashboard/DashboardAdmin";
import TaxAdmin from "./AdminTable/TaxAdmin";
import Members from "./AdminTable/Members";
import AgentPage from "./AdminTable/Agent";
import AgentDashboard from "./Agent/pages/dashboard";
import AgentMembers from "./Agent/pages/members";
import AgentCollection from "./Agent/pages/taxCollection";
import AgentSetting from "./Agent/pages/setting";
import NotFound from "./reusables/NotFound";
import Settings from "./AdminTable/Settings/Settings";
import User from "./User/UserRegForm";
import PasswordChange from "./Auth/changePassword";
import Home from "./Home";
import AdminLogin from "./Auth";
import VerifyLogin from "./Auth/verifyOtp";
import AuthLogin from "./Auth/LoginModule/AuthLogin";
import ReactIdleTimer from "./reusables/ReactIdleTimer";
import AuthContext from "./context/AuthContext";
import DuplicateTab from "./reusables/DuplicateTabs";
import Associations from "./pages/Association";
import MatanAdmin from "./pages/MatanAdmin";

function App() {
  const [noTab, setNoTab] = useState(false);
  const [tabLoad, setTabLoad] = useState(true);
  const { user } = useContext(AuthContext);
  var setCookie = (cname, cvalue, seconds) => {
    var d = new Date();
    d.setTime(d.getTime() + seconds * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  // helper function to get a cookie
  var getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  useEffect(() => {
    // Do not allow multiple call center tabs
    // setTabLoad(false)
    document.cookie =
      "ic_window_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    const validateCallCenterTab = () => {
      var win_id_cookie_duration = 10; // in seconds

      if (!window.name) {
        window.name = Math.random().toString();
      }

      if (
        !getCookie("ic_window_id") ||
        window.name === getCookie("ic_window_id")
      ) {
        // This means they are using just one tab. Set/clobber the cookie to prolong the tab's validity.
        // console.log(window.name);
        // console.log(getCookie("ic_window_id"));
        setCookie(
          "ic_window_id",
          Number.parseFloat(window.name),
          win_id_cookie_duration
        );
      } else if (getCookie("ic_window_id") !== window.name) {
        // this means another browser tab is open, alert them to close the tabs until there is only one remaining
        setNoTab(true);
        clearInterval(callCenterInterval);
        // var error = "Multiple call center tabs error. Program terminating.";
        // throw error;
      }
    };
    setTimeout(() => {
      setTabLoad(false);
    }, 2500);
    // setTabLoad(false);
    var callCenterInterval = setInterval(validateCallCenterTab, 2000);
  }, []);

  if (noTab) return <DuplicateTab />;

  return (
    <div className="App">
      {user && <ReactIdleTimer />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/register" element={<User />} />
        <Route path="/signIn" element={<AdminLogin />} />
        <Route path="/:email/verify-otp/" element={<VerifyLogin />} />
        <Route path="/auth" element={<AuthLogin />} />
        <Route path="/:email/changepassword" element={<PasswordChange />} />
        <Route element={<PrivateRoute adminRoute />}>
          <Route path="/dashboardAdmin" element={<DashboardAdmin />} />
          <Route path="/association" element={<Associations />} />
          <Route path="/collection" element={<TaxAdmin />} adminRoute />
          <Route path="/membersAdmin" element={<Members />} adminRoute />
          <Route path="/agentAdmin" element={<AgentPage />} adminRoute />
          <Route path="/matanAdmin" element={<MatanAdmin />} adminRoute />
          <Route path="/profileSetting" element={<Settings />} adminRoute />
        </Route>
        <Route element={<PrivateRoute agentRoute />}>
          <Route
            path="/dashboardAgent"
            element={<AgentDashboard />}
            agentRoute
          />
          <Route path="/membersAgent" element={<AgentMembers />} agentRoute />
          <Route
            path="/collectionAgent"
            element={<AgentCollection />}
            agentRoute
          />
          <Route path="/profileSetting" element={<AgentSetting />} agentRoute />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
