import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, IconButton } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";

const SearchBar = ({ placeholder, onSearch, debounceTime }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, debounceTime);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery, debounceTime]);

  useEffect(() => {
    if (debouncedQuery) {
      onSearch(debouncedQuery);
    }
  }, [debouncedQuery, onSearch]);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchClick = () => {
    onSearch(searchQuery);
    // setSearchQuery("");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <div className="flex items-center border-2 border-slate rounded-lg mt-6 w-6/12 h-11">
      <TextField
        variant="outlined"
        placeholder={placeholder}
        value={searchQuery}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        fullWidth
        size="small"
        InputProps={{
          style: {
            height: "2.5rem",
            padding: "0.25rem",
          },
        }}
        className="flex-1"
      />
      <IconButton onClick={handleSearchClick}>
        <SearchIcon />
      </IconButton>
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  debounceTime: PropTypes.number,
};

SearchBar.defaultProps = {
  placeholder: "Search...",
  debounceTime: 5000, // 5 seconds default debounce time
};

export default SearchBar;
