import { createContext, useMemo, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ActionTypes from "./actionTypes";
import useAxios, { usePublicAxios } from "../../reusables/Api/useAxios";

import { encryptData, decryptData } from "../../reusables/crypto/AdminCrypto";

export const AuthContext = createContext();

const initialState = {
  loggedIn: false,
  agentAccount: {},
  loading: false,
  member: {},
  searchedInput: "",
  filter: [],
};

function reducer(state, action) {
  switch (action.type) {
    case ActionTypes.SIGNUP:
    case ActionTypes.LOGIN:
    case ActionTypes.CHANGEPASSWORD:
    case ActionTypes.VALIDATE_OTP:
    case ActionTypes.CREATE_AGENT:
    case ActionTypes.CREATE_ASSOCIATION:
    case ActionTypes.UPDATE_ASSOCIATION:
    case ActionTypes.DELETE_ASSOCIATION:
    case ActionTypes.EDIT_AGENT:
    case ActionTypes.LIST_MEMBERS:
    case ActionTypes.LIST_ASSOCIATION:
    case ActionTypes.LIST_MATAN_ADMIN:
    case ActionTypes.LIST_AGENTS:
    case ActionTypes.GET_ALL_TAX_COLLECTION:
    case ActionTypes.GETMEMBER:
    case ActionTypes.GET_MEMBER_ACCOUNT:
    case ActionTypes.DOWNLOAD_MEMBERS_RECORD:
      return {
        ...state,
        loggedIn: false,
        user: action.payload,
        loading: action?.payload?.loading || true,
      };

    case ActionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        agentAccount: {},
        loading: false,
        member: {},
        searchedInput: "",
        filteredItems: [],
      };

    case ActionTypes.SEARCH:
    case ActionTypes.FILTER:
    case ActionTypes.REGISTERMEMBER:
    case ActionTypes.REGISTERADMIN:
      return {
        ...state,
        [action.type.toLowerCase()]: action.payload,
        loading: false,
      };
    case ActionTypes.FINDMEMBER:
    case ActionTypes.VALIDATEBVN:
    case ActionTypes.CREATEMEMBER:
      return {
        ...state,
        member: action.payload,
        loading: false,
      };

    default:
      throw new Error();
  }
}

export default reducer;

export const AdminProvider = ({ children }) => {
  const privateApi = useAxios();
  const publicApi = usePublicAxios();

  const [adminState, dispatch] = useReducer(reducer, initialState);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalCollection, setModalCollection] = useState(false);
  const [memberDetailModal, setMemberDetailModal] = useState(false);
  const [agentAction, setAgentAction] = useState("newRegistration");
  const [allMembers, setAllMembers] = useState([]);
  const [allTaxCollection, setAllTaskCOllections] = useState([]);
  const [allAgents, setAllAgents] = useState([]);
  const [currentMember, setCurrentMember] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalAgents, setTotalAgents] = useState(0);

  const toastMessage = (type, message) => {
    toast[type](message, { autoClose: 3000 });
  };
  const createAsyncAction =
    (type, url, method = "post", usePublic = false) =>
    async (payload) => {
      try {
        dispatch({ type, payload, loading: true });
        let response;
        const apiInstance = usePublic ? publicApi : privateApi;

        switch (method.toLowerCase()) {
          case "get":
            if (url.includes("/ExportMember")) {
              const queryValue = payload ? encryptData(payload) : null;
              const queryString = queryValue ? queryValue.data : "";
              const res = await apiInstance.get(`${url}?${queryString}`, {
                responseType: "blob",
              });

              const blob = new Blob([res.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "members.xlsx";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);

              return;
            } else {
              const queryValue = payload ? encryptData(payload) : null;
              const queryString = queryValue ? queryValue.data : "";
              response = await apiInstance.get(`${url}?${queryString}`);
            }
            break;

          case "post":
            response = await apiInstance.post(url, payload);
            break;

          case "put":
            const { Id, ...rest } = payload;
            response = await apiInstance.put(`${url}/${Id}`, rest);
            break;

          case "patch":
            response = await apiInstance.patch(url, payload);
            break;

          case "delete":
            response = await apiInstance.delete(url);
            break;

          default:
            throw new Error(`Unsupported method: ${method}`);
        }

        return response;
      } catch (error) {
        console.error(`Error in action ${type}:`, error.message);
      }
    };

  const actions = {
    signup: createAsyncAction(ActionTypes.SIGNUP, "/api/signup"),
    createAgent: createAsyncAction(ActionTypes.CREATE_AGENT, "/api/agent"),
    createAssociation: createAsyncAction(
      ActionTypes.CREATE_ASSOCIATION,
      "/api/Association"
    ),
    createAdmin: createAsyncAction(
      ActionTypes.REGISTERADMIN,
      "/api/Authentication/registerAdmin"
    ),
    updateAssociation: createAsyncAction(
      ActionTypes.UPDATE_ASSOCIATION,
      "/api/Association/UpdateAssociation"
    ),
    validateOtp: createAsyncAction(ActionTypes.VALIDATE_OTP, "/api/validate"),
    getMember: createAsyncAction(ActionTypes.GETMEMBER, "/api/get"),
    listMembers: createAsyncAction(
      ActionTypes.LIST_MEMBERS,
      `/api/Member`,
      "get"
    ),
    listMembersSwitchTabs: createAsyncAction(
      ActionTypes.LIST_MEMBERS,
      `/api/Member/switchTabs`,
      "get"
    ),
    listAssociations: createAsyncAction(
      ActionTypes.LIST_ASSOCIATION,
      `/api/Association`,
      "get",
      true
    ),
    listAdmin: createAsyncAction(
      ActionTypes.LIST_MATAN_ADMIN,
      `/api/Admin`,
      "get"
    ),
    deleteAssociation: createAsyncAction(
      ActionTypes.DELETE_ASSOCIATION,
      `/api/Association/DeleteAssociation`,
      "post"
    ),
    listAgents: createAsyncAction(ActionTypes.LIST_AGENTS, `/api/Agent`, "get"),
    editAgent: createAsyncAction(ActionTypes.EDIT_AGENT, `/api/Agent`, "put"),
    editAgent: createAsyncAction(ActionTypes.EDIT_AGENT, `/api/Agent`, "put"),
    getMemberAccount: createAsyncAction(
      ActionTypes.GET_MEMBER_ACCOUNT,
      "/api/id"
    ),
    searchMember: createAsyncAction(
      ActionTypes.SEARCH,
      "/api/Member/search",
      "get"
    ),
    searchAgent: createAsyncAction(
      ActionTypes.SEARCH,
      "/api/Agent/search",
      "get"
    ),
    searchTaxCollection: createAsyncAction(
      ActionTypes.SEARCH,
      "/api/Onboarding/searchcollection",
      "get"
    ),
    filter: createAsyncAction(ActionTypes.FILTER, "/api/filter", "try"),
    registerMember: createAsyncAction(
      ActionTypes.REGISTERMEMBER,
      "/api/members"
    ),
    downloadMemberRecords: createAsyncAction(
      ActionTypes.DOWNLOAD_MEMBERS_RECORD,
      "/api/Member/ExportMember",
      "get"
    ),
    findMember: createAsyncAction(ActionTypes.FINDMEMBER, "/api/members"),
    getAllTaxCollection: createAsyncAction(
      ActionTypes.GET_ALL_TAX_COLLECTION,
      "/api/Onboarding/taxcollections",
      "get"
    ),
  };

  const value = useMemo(
    () => ({ ...actions, toastMessage }),
    [actions, toastMessage]
  );

  return (
    <AuthContext.Provider
      value={{
        adminState,
        modalOpen,
        setModalOpen,
        modalCollection,
        setModalCollection,
        showSpinner,
        setShowSpinner,
        memberDetailModal,
        setMemberDetailModal,
        currentMember,
        setCurrentMember,
        agentAction,
        setAgentAction,
        allMembers,
        setAllMembers,
        allTaxCollection,
        setAllTaskCOllections,
        totalMembers,
        setTotalMembers,
        allAgents,
        setAllAgents,
        totalAgents,
        setTotalAgents,
        ...value,
      }}
    >
      <ToastContainer position="top-right" autoClose={2000} />
      {children}
    </AuthContext.Provider>
  );
};
