import React, { useState, useEffect } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import SideNav from "../../reusables/AgentsReuseables/sideNav";
import TopCard from "../../reusables/AgentsReuseables/topCard";
import AgentAuth from "../../context/AgentContext/agentAuth";
import CollectionModal from "../modal/collectionForm";
import EnhancedTable from "../../reusables/AgentsReuseables/collectionsCompleteTable";
import PageLayout from "../../reusables/shared/layout/PageLayout";

function TaxCollection() {
  const {
    agentState,
    filter,
    modalCollection,
    getAllTaxCollection,
    allTaxCollection,
    setAllTaskCOllections,
    totalCollections,
    settotalCollections,
    setShowSpinner,
  } = AgentAuth();

  const [displayNav, setDisplayNav] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const filteredData = agentState?.filter;

  const removedClickedFilter = (item) => {
    const filterItem = filteredData.filter((filterItem) => filterItem !== item);
    filter(filterItem);
  };

  const showSideNav = () => {
    setDisplayNav(!displayNav);
  };

  const getTaxCollection = async () => {
    setShowSpinner(true);
    const response = await getAllTaxCollection({
      page: page + 1,
      perPage: rowsPerPage,
    });

    if (response?.Data) {
      const apiResponse = JSON.parse(response?.Data);
      setAllTaskCOllections(apiResponse?.Data);
      settotalCollections(apiResponse?.MetaData?.Total);
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
      setAllTaskCOllections([]);
    }
  };

  useEffect(() => {
    getTaxCollection();
  }, [page, rowsPerPage]);

  const handleTableChange = (payload) => {
    setPage(payload.page);
    setRowsPerPage(payload.rowsPerPage);
  };

  return (
    <PageLayout
      title="Tax Collection"
      subTitle="View/Create Tax."
      buttonShown="all"
    >
      <section className="pl-3 pt-1 w-full overflow-x-hidden">
        <div className="flex justify-between pt-4">
          <TopCard
            title="Tax Collections"
            subTitle="View/Create Tax."
            button1="Export"
            button2="New Member"
            button3="Verify Collection"
            totalValue={totalCollections || 0}
          />
          <RxHamburgerMenu
            onClick={showSideNav}
            className="md:hidden mr-5 ml-auto text-2xl"
          />
        </div>
        <EnhancedTable
          allTaxCollection={allTaxCollection}
          tableChange={handleTableChange}
        />
      </section>
      {modalCollection && <CollectionModal />}
    </PageLayout>
  );
}

export default TaxCollection;
