import { useState, useContext } from "react";
import { ToastContainer } from "react-toastify";
import { RxHamburgerMenu } from "react-icons/rx";
import "react-toastify/dist/ReactToastify.css";

import DashSideNav from "../../DashSideNav";
import DashboardNav from "../../../Dashboard/DashboardNav";
import AuthContext from "../../../context/AuthContext";
import TopCard from "../../AgentsReuseables/topCard";

export default function PageLayout({
  children,
  title,
  subTitle,
  totalValue,
  showDetails,
  setShowDetails,
  buttonShown = "download",
  noButton = true,
  button1Text,
  button2Text,
}) {
  const [displayNav, setDisplayNav] = useState(false);
  const { user } = useContext(AuthContext);
  const userEmail =
    user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] || "@";

  const showSideNav = () => {
    setDisplayNav(!displayNav);
  };

  const handleNewAssociationClick = () => {
    setShowDetails(true);
  };

  return (
    <>
      <ToastContainer theme="colored" />
      <div className="flex h-screen w-full">
        <section
          className={`h-screen ${
            displayNav ? "block md:hidden absolute bg-white" : "md:block hidden"
          }`}
        >
          <DashSideNav />
        </section>

        <div className="w-10/12 flex flex-col">
          <div className="flex justify-between pt-4 w-full">
            <RxHamburgerMenu
              onClick={showSideNav}
              className="md:hidden mr-5 ml-auto text-2xl"
            />
            {buttonShown === "download" && (
              <DashboardNav
                location="memberTable"
                clickExportClasses={() => {}}
                handleExportClick={() => {}}
                title={
                  title || `Welcome back, ${userEmail.split("@")[0] || " "}`
                }
                subTitle={
                  subTitle ||
                  "Monitor activities going on in the MATAN platform"
                }
                showButton={noButton}
              />
            )}
            {buttonShown === "create" && !noButton && (
              <TopCard
                title={title || "Association"}
                subTitle={subTitle || "View/Create Association."}
                button1={button1Text || "Export"}
                button2={button2Text || "New Association"}
                totalValue={totalValue || 0}
                onButton1Click={handleNewAssociationClick}
              />
            )}
            {buttonShown !== "download" &&
              buttonShown !== "create" &&
              !noButton && (
                <TopCard
                  title={title}
                  subTitle={subTitle}
                  button1={button1Text || "Export"}
                  button2={button2Text || "New Agent"}
                  totalValue={totalValue || 0}
                  onButton1Click={handleNewAssociationClick}
                />
              )}
          </div>

          <main className="flex-grow px-4 py-8 bg-[#FCFCFC] w-full overflow-y-auto">
            {children}
          </main>
        </div>
      </div>
    </>
  );
}
