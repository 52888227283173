import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { IoCloseSharp } from "react-icons/io5";
import AdminAuth from "../../context/AdminContext/adminAuth";
import { GoPerson } from "react-icons/go";
import LoadingSpinner from "../spinner";

const EditAgentModal = ({ currentAgent, closeAgentEditModal }) => {
  const queryClient = useQueryClient();
  const { setModalOpen, editAgent } = AdminAuth();

  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    FirstName: currentAgent?.FirstName || "",
    LastName: currentAgent?.LastName || "",
    MiddleName: currentAgent?.MiddleName || "",
    Title: currentAgent?.Title || "",
    PhoneNumber: currentAgent?.PhoneNumber || "",
    Email: currentAgent?.Email || "",
    Status: currentAgent?.Status || "",
    fileData: currentAgent?.fileData || "",
  });

  const [showSpinner, setShowSpinner] = useState(false);

  const closeEditModal = () => {
    closeAgentEditModal();
    setModalOpen(false);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileSize = file.size;
      const MAX_FILE_SIZE = 1024 * 1024;

      if (fileSize <= MAX_FILE_SIZE) {
        try {
          const base64String = await convertFileToBase64(file);
          setSelectedFile(file);
          setFormData((prevFormData) => ({
            ...prevFormData,
            fileData: base64String,
          }));
        } catch (error) {
          console.error("Error converting file to base64:", error);
        }
      } else {
        setSelectedFile(null);
        setFormData((prevFormData) => ({
          ...prevFormData,
          fileData: "",
        }));
        console.error(
          `File size exceeds the maximum allowed (${MAX_FILE_SIZE} bytes).`
        );
      }
    }
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const { mutateAsync: handleAgentUpdate, isPending } = useMutation({
    mutationFn: async () => {
      setShowSpinner(true);
      const fileReader = new FileReader();
      if (selectedFile) {
        fileReader.readAsDataURL(selectedFile);
        fileReader.onload = () => {
          const base64 = fileReader.result?.split(",")[1];
          setFormData({ ...formData, fileData: base64 || "" });
        };
      }
      fileReader.onerror = (error) => {};
      const payload = { ...formData, Id: currentAgent.Id };
      const response = await editAgent(payload);
      if (response?.Success === true) {
        closeEditModal();
        setShowSpinner(false);
      }
      setShowSpinner(false);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["agents"] });
    },
  });

  return (
    <>
      <div className="fixed top-0 right-0 bottom-0 left-0 z-50 flex items-center justify-center backdrop-filter backdrop-blur-sm">
        <div className="bg-white bg-opacity-90 overflow-y-hidden mt-3 w-3/4 h-[530px] max-w-5xl p-4 rounded-lg shadow-lg dark:bg-gray-700">
          <div className="relative">
            <div className="bg-white rounded-lg px-2 py-1">
              <div className="flex justify-between border-b shadow-sm pb-2 ">
                <p className="text-xl font-semibold">Edit Agent details</p>
                <button onClick={closeEditModal}>
                  <IoCloseSharp />
                </button>
              </div>

              <div>
                <p className="absolute mt-5 text-[8px] ml-4 md:ml-4 font-semibold md:text-xs">
                  Upload Passport
                </p>
              </div>

              <div className="shadow mt-4 py-5 md:py-6 mt-3">
                <div className="flex">
                  <div className="ml-2 px-5 py-4 md:py-7 md:px-8 bg-gray-100 rounded-full">
                    {selectedFile ? (
                      <img
                        src={"data:image/jpg;base64," + formData.fileData}
                        alt="User"
                        className="w-20 h-12 md:w-24 rounded-full md:h-20"
                      />
                    ) : (
                      <GoPerson className="text-7xl text-slate-600" />
                    )}
                  </div>

                  <div className="ml-10 items-center w-full">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-40 h-20 md:w-7/12 md:h-28 border-2 border-gray-100 rounded-lg cursor-pointer bg-white dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-2 pb-3">
                        <svg
                          className="w-3 h-3 md:w-4 md:h-4 mb-1 text-gray-500 dark:text-gray-400"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 20 16"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                          />
                        </svg>
                        <p className="mb-0.5 text-[8px] md:text-[10px] text-gray-500 dark:text-gray-400">
                          <span className="font-semibold">Click to upload</span>{" "}
                          or drag and drop
                        </p>
                        <p className="md:text-[10px] text-[8px] text-gray-500 dark:text-gray-400">
                          SVG, PNG, JPG, or GIF (MAX. 800x400px)
                        </p>
                      </div>
                      <input
                        onChange={handleFileChange}
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept="image/*"
                      />
                    </label>
                  </div>
                </div>

                {/* Form fields */}
                <div className="block md:flex mt-2 md:mt-5">
                  <div className="flex flex-col md:px-6 mx-2 md:mx-0">
                    <label className="text-[9px] md:text-sm">First Name</label>
                    <input
                      placeholder="Enter First Name"
                      type="text"
                      className="border-2 rounded md:py-1 md:px-2 w-[270px] md:w-[400px]"
                      value={formData.FirstName}
                      name="FirstName"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col mt-1 md:mt-0 mx-2 md:mx-0">
                    <label className="text-[9px] md:text-sm">Last Name</label>
                    <input
                      placeholder="Enter Last Name"
                      type="text"
                      className="border-2 md:py-1 md:px-2 w-[270px] rounded md:w-[400px]"
                      value={formData.LastName}
                      name="LastName"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="block md:flex mt-2 md:mt-5">
                  <div className="flex flex-col md:px-6 mx-2 md:mx-0">
                    <label className="text-[9px] md:text-sm">Middle Name</label>
                    <input
                      placeholder="Enter Middle Name"
                      type="text"
                      className="border-2 rounded md:py-1 md:px-2 w-[270px] md:w-[400px]"
                      value={formData.MiddleName}
                      name="MiddleName"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col mt-1 md:mt-0 mx-2 md:mx-0">
                    <label className="text-[9px] md:text-sm">Title</label>
                    <input
                      placeholder="Enter Title"
                      type="text"
                      className="border-2 md:py-1 md:px-2 w-[270px] rounded md:w-[400px]"
                      value={formData.Title}
                      name="Title"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="block md:flex mt-2 md:mt-5">
                  <div className="flex flex-col md:px-6 mx-2 md:mx-0">
                    <label className="text-[9px] md:text-sm">
                      Phone Number
                    </label>
                    <input
                      placeholder="Enter Phone Number"
                      type="text"
                      className="border-2 rounded md:py-1 md:px-2 w-[270px] md:w-[400px]"
                      value={formData.PhoneNumber}
                      name="PhoneNumber"
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="flex flex-col mt-1 md:mt-0 mx-2 md:mx-0">
                    <label className="text-[9px] md:text-sm">Email</label>
                    <input
                      placeholder="Enter Email"
                      type="text"
                      className="border-2 md:py-1 md:px-2 w-[270px] rounded md:w-[400px]"
                      value={formData.Email}
                      name="Email"
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="mt-2 md:mt-5 mx-2 md:px-8 flex justify-between md:justify-start">
                  <label className="text-[9px] md:text-sm mr-2">
                    Agent Status
                  </label>
                  <div className="space-x-2">
                    <label className="text-[9px] md:text-sm">
                      <input
                        type="radio"
                        name="Status"
                        value="Active"
                        checked={formData.Status.toLowerCase() == "active"}
                        onChange={handleInputChange}
                      />
                      Active
                    </label>
                    <label className="text-[9px] md:text-sm">
                      <input
                        type="radio"
                        name="Status"
                        value="Inactive"
                        checked={formData.Status != "Active"}
                        onChange={handleInputChange}
                      />
                      Inactive
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex justify-center md:justify-end mt-2 md:mt-5 md:px-8 space-x-2 md:space-x-4">
                <button
                  className="w-28 md:w-32 py-1 md:py-1.5 bg-gray-500 text-white rounded"
                  onClick={closeEditModal}
                >
                  Cancel
                </button>
                <button
                  className="w-28 md:w-32 py-1 md:py-1.5 bg-green-500 text-white rounded"
                  onClick={handleAgentUpdate}
                  disabled={showSpinner}
                >
                  {showSpinner ? <LoadingSpinner /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAgentModal;
