import React, { useRef, useEffect, useState, useContext } from "react";
import { useIdleTimer } from "react-idle-timer";
import Modal from "react-modal";
import AuthContext from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const ReactIdleTimer = () => {
  const { setUser, logOut } = useContext(AuthContext);
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  const handleLogout = () => {
    localStorage.removeItem("authTokens");
    localStorage.removeItem("userDetails");
    logOut();
    setUser(null);
    navigate("/signIn");
  };

  const onIdle = () => {
    setModalIsOpen(true);

    // If user doesn't click "Keep me signed in" within 30 seconds, log out
    sessionTimeoutRef.current = setTimeout(() => {
      handleLogout();
    }, 10000); // 10 seconds
  };

  const stayActive = () => {
    setModalIsOpen(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    onIdle,
    timeout: process.env.REACT_APP_SESSION_TIMEOUT || 5 * 60 * 1000, // Timeout before modal shows up
    debounce: 5000,
  });

  useEffect(() => {
    // Cleanup timer on component unmount
    return () => {
      clearTimeout(sessionTimeoutRef.current);
    };
  }, []);

  return (
    <>
      <Modal style={customStyles} isOpen={modalIsOpen}>
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-4">
            You've been idle for a while
          </h2>
          <p className="text-lg mb-6">You will be logged out soon</p>
          <div>
            <button
              onClick={handleLogout}
              className="bg-red-500 text-white px-4 py-2 rounded-md mr-4 focus:outline-none focus:ring-2 focus:ring-red-600 hover:bg-red-600"
            >
              Log me out
            </button>
            <button
              onClick={stayActive}
              className="bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 hover:bg-blue-600"
            >
              Keep me signed in
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ReactIdleTimer;
