export const PRODUCTS = [
  {
    name: "MATAN, short for Market Traders Association of Nigeria, is the pioneering umbrella association dedicated to championing the rights and interests of traders nationwide. ",
    link: "#",
  },
];
export const RESOURCES = [
  { name: "Fetures", link: "#" },
  { name: "About Us", link: "#" },
  { name: "FAQ", link: "#" },
  { name: "Fetures", link: "#" },
];
export const COMPANY = [
  { name: "Membership", link: "#" },
  { name: "Events", link: "#" },
  { name: "Blog", link: "#" },
  { name: "About Us", link: "#" },
];
export const SUPPORT = [
  { name: "location", link: "#" },
  { name: "1711 northeast  ", link: "#" },
  { name: "FCT Abuja", link: "#" },
  { name: "location", link: "#" },
];
