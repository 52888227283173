const ActionTypes = {
  SIGNUP: "SIGNUP",
  LOGIN: "LOGIN",
  VALIDATE_OTP: "VALIDATE_OTP",
  GETMEMBER: "GETMEMBER",
  REGISTERMEMBER: "REGISTERMEMBER",
  LIST_MEMBERS: "LIST_MEMBERS",
  LOGOUT: "LOGOUT",
  GET_MEMBER_ACCOUNT: "GET_MEMBER_ACCOUNT",
  SEARCH: "SEARCH",
  FILTER: "FILTER",
  FINDMEMBER: "FINDMEMBER",
  VALIDATEBVN: "VALIDATEBVN",
  CREATEMEMBER: "CREATEMEMBER",
  GET_ALL_TAX_COLLECTION: "GET_ALL_TAX_COLLECTION",
  VERIFYUSEROTP: "VERIFYUSEROTP",
  RESENDUSEROTP: "RESENDUSEROTP",
  GET_ALL_BRANCHES: "GET_ALL_BRANCHES",
};

export default ActionTypes;
