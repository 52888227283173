import React from 'react'; 
 
const Progressbar = ({ bgcolor, progress, state = 'Lagos' }) => {
    const fillerStyles = {
      width: `${progress}%`,
      backgroundColor: bgcolor,
      transition: 'width 0.5s ease-in-out',
      borderRadius: '0 4px 4px 0', 
    };
  
    return (
      <div className="flex flex-col items-start w-full ">
        <span className="text-[13px] text-gray-500 text-left tracking-wider mr-2">
          {state}
        </span>
  
        <div className='w-full flex items-center'>
  <div className="w-full h-2 bg-gray-300 rounded-md overflow-hidden">
    <div
      className="h-full text-right text-white font-bold"
      style={fillerStyles}
    ></div>
  </div>

  <span className="text-[14px] text-gray-500 text-right tracking-wider ml-2">
    {`${progress}%`}
  </span>
</div>

      </div>
    );
  };
  
  
 
export default Progressbar;
