import React, { useEffect, useState } from "react";
import SideNav from "../reusables/DashSideNav";
import { CiImport } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import TaxCollectionTable from "../reusables/AgentsReuseables/collectionsCompleteTable";
import AdminAuth from "../context/AdminContext/adminAuth";
import DashboardNav from "../Dashboard/DashboardNav";
import PageLayout from "../reusables/shared/layout/PageLayout";

const TaxAdmin = () => {
  const {
    agentState,
    filter,
    modalCollection,
    getAllTaxCollection,
    allTaxCollection,
    setAllTaskCOllections,
    totalCollections,
    settotalCollections,
    setShowSpinner,
  } = AdminAuth();
  const [selectedOption, setSelectedOption] = useState("category");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [displayNav, setDisplayNav] = useState(false);
  const [selectedExButton, setSelectedExButton] = useState(null);

  const clickExportClasses = (elem) => {
    return `mx-2 ${
      selectedExButton === elem
        ? "text-green-600 rounded-lg font-semibold bg-green-200"
        : ""
    }`;
  };

  const getTaxCollection = async () => {
    setShowSpinner(true);
    const response = await getAllTaxCollection({
      page: page + 1,
      perPage: rowsPerPage,
    });

    if (response?.Data) {
      const apiResponse = JSON.parse(response?.Data);
      setAllTaskCOllections(apiResponse?.Data);
      settotalCollections(apiResponse?.MetaData?.Total);
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
      setAllTaskCOllections([]);
    }
  };

  useEffect(() => {
    getTaxCollection();
  }, [page, rowsPerPage]);

  const handleExportClick = (elem) => {
    setSelectedExButton(elem);
  };
  const removeHandleExportClick = (elem) => {
    setSelectedExButton(!elem);
  };

  const getButtonClasses = (option) => {
    return `mx-2 ${
      selectedOption === option
        ? "text-green-700 font-bold border-b-2 border-green-700 rounded-sm"
        : ""
    }`;
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const showSideNav = () => {
    setDisplayNav(!displayNav);
  };
  const handleTableChange = (payload) => {
    setPage(payload.page);
    setRowsPerPage(payload.rowsPerPage);
  };
  return (
    <PageLayout
      title="Tax Collection"
      subTitle="View/Create Tax."
      buttonShown="download"
    >
      <section
        className="pl-3 pt-1 w-full overflow-x-hidden"
        onMouseDown={removeHandleExportClick}
      >
        <div className="flex text-[12px] mt-6">
          <button
            onClick={() => handleOptionClick("category")}
            className={getButtonClasses("category")}
          >
            All Categories
          </button>
          <button
            onClick={() => handleOptionClick("traders")}
            className={getButtonClasses("traders")}
          >
            Traders
          </button>
          <button
            onClick={() => handleOptionClick("haulage")}
            className={getButtonClasses("haulage")}
          >
            Haulage
          </button>
        </div>
        <hr className="my-0 border-t border-gray-200 w-full" />
        <TaxCollectionTable
          allTaxCollection={allTaxCollection}
          tableChange={handleTableChange}
        />
      </section>
    </PageLayout>
  );
};

export default TaxAdmin;
